import { EAgreementsURL } from '../../utils/EAgreements'
import useWindowDimensions from '../../utils/useWindowDimension';
import { Modal } from './Modal';

interface Props {
    visible: boolean;
    src: EAgreementsURL;
    close: () => void;
    children?: React.ReactNode;
};

const Agreement = (props: Props) => {

    const { height, width } = useWindowDimensions();

    const getAgreementTile = (): string => {
        let title = "";
        switch (props.src) {
            case EAgreementsURL.CGU:
                title = "Conditions générales d'utilisation"
                break;
            case EAgreementsURL.CGV:
                title = "Conditions générales de vente"
                break;
            case EAgreementsURL.CHARTER_OF_GOOD_CONDUCT:
                title = "Charte de bonne conduite"
                break;
            case EAgreementsURL.CONFIDENTIALITY_POLICY:
                title = "Politique de confidentialité"
                break;
            case EAgreementsURL.IDENFY_CONFID_POLICY:
                title = "Politique de confidentialité du service iDenfy"
                break;
            default:
                break;
        }
        return title;
    };
    
    return (
        <Modal 
            title={getAgreementTile()}
            visible={props.visible}
            close={props.close}
            width={'90vw'}
            children={
                <div>
                    <iframe 
                        src={props.src} 
                        width={width * 0.8} 
                        height={height * 0.8} 
                    ></iframe>
                    {props.children}
                </div>
            }
        />
    );
};

export default Agreement;