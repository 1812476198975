import * as React from 'react';
import { IMessage } from '../../store/message';
import styled from 'styled-components';
import { Text } from '../shared/styled';
import { Theme } from '../../assets/Theme';
import { observer } from 'mobx-react';
import { DataStoreContext } from '../../store/rootStore';
import { transformDateToString } from '../utils/dateManager';
import { Separator } from './../Transactions/TransactionCard';
import { IUser } from '../../store/user';
import defaultUser from './../../assets/icons/avatar/DefaultUser.png';
import Pictures from '../shared/Pictures/Pictures';
import Avatar from '../shared/Avatar';

export const MESSAGE_BORDER_RADIUS = 0.8;
const MESSAGE_MARGIN = '2rem';
const AVATAR_MARGIN = '1rem';

interface IMessageContainer {
    isUserMessage: boolean;
}

export const MessageContainer = styled.div<IMessageContainer>`
display: flex;
width: 90%;
margin-bottom: ${MESSAGE_MARGIN};
${({ isUserMessage }) => isUserMessage && `
    flex-direction: row-reverse;
`}
`;

export const MessageAvatarContainer = styled.div<IMessageContainer>`
display: flex;
align-items: flex-end;
margin-right: ${AVATAR_MARGIN};
${({ isUserMessage }) => isUserMessage && `
    margin-right: 0;
    margin-left: ${AVATAR_MARGIN};
    color: ${Theme.colors.white};
`}
`;

export const MessageBody = styled.div<IMessageContainer>`
width: 70%;
padding: 1rem;
border-radius: ${MESSAGE_BORDER_RADIUS}rem;
color: ${Theme.colors.violet};
background-color: ${props => props.theme.colors.white};
${({ isUserMessage }) => isUserMessage && `
    background-color: ${Theme.colors.pinkStrong};
    color: ${Theme.colors.white};
`}
`;

export const MessagePhotos = styled.div``;

export const MessageHead = styled.div`
display: flex;
align-items: baseline;
`;
interface Props {
    message: IMessage | null;
    isUserMessage: boolean;
}

const Message: React.FC<Props> = observer((props : Props) => {

    const { userLogged, message, userLogged : { user } } = React.useContext(DataStoreContext);

    const friend: IUser | undefined = message.getUserInTouch(message.selectedConversation, user?.id);
    const friendAvatar: string = (friend != null && friend.avatar != null) ? friend.avatar : defaultUser;

    const avatar: string = props.isUserMessage ? userLogged.getUserAvatar() : friendAvatar;

    const getPhotos = () => {

        if (props.message?.images != null && props.message.images.length > 0)
        {
            return <Pictures list={props.message.images} />
        }
        else
        {
            return null;
        };          
    };

    return (
        <MessageContainer isUserMessage={props.isUserMessage}>
            <MessageAvatarContainer isUserMessage={props.isUserMessage}>
                <Avatar 
                    image={avatar}
                    width={4}
                    height={4}
                    onClick={() => null}
                    isWepoppins
                />
            </MessageAvatarContainer>
            <MessageBody isUserMessage={props.isUserMessage}>
                <MessageHead>
                    <Text 
                        size={Theme.fontSizes.small} 
                        color='black'
                    >
                        { props.message?.senderId == user?.id ? 
                            `${user?.firstname} ${user?.lastname}` 
                            : `${friend?.firstname} ${friend?.lastname}` 
                        } 
                    </Text>
                    <Separator />
                    <Text 
                        size={Theme.fontSizes.xsmall} color={'black'}>{transformDateToString(props.message?.createdAt)}</Text>
                </MessageHead>
                <Text 
                    align='left' 
                    color={props.isUserMessage ? Theme.colors.white : Theme.colors.violet}
                >
                    {props.message?.text}
                </Text>
                <MessagePhotos>
                    { getPhotos() }
                </MessagePhotos>
            </MessageBody>
        </MessageContainer>
    )
});

export default Message;
