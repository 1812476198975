import React from 'react';
import styled from 'styled-components';
import Agreement from '../../../shared/Information/Agreement';
import MiniButton from '../../../shared/Buttons/MiniButton';
import { EAgreementsURL } from '../../../utils/EAgreements';

export const Buttons = styled.div`
display: flex;
justify-content: space-around;
margin-top: 1rem;
`;

interface Props {
    accept: () => void;
    decline: () => void;
};

const CharterOfGoodConduct: React.FC<Props> = (props: Props) => {

  const source = EAgreementsURL.CHARTER_OF_GOOD_CONDUCT;

  return (
    <Agreement 
      src={source}
      visible={true}
      close={props.decline}
      children={
        <Buttons>
          <MiniButton
            label='Refuser'
            action={props.decline}
            whiteBackground={true} 
            width={13.5}  
          />
          <MiniButton 
            label='Accepter'
            action={props.accept}
            whiteBackground={false}   
            width={13.5}                                  
          />        
        </Buttons>          
      }
    />
  );
};

export default CharterOfGoodConduct;