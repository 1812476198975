import * as React from 'react';
import styled from 'styled-components';
import illustration from './../../../assets/illustrations/approvedParent.png';
import { Modal } from './Modal';
import { Button, Text } from '../styled';

const IMG_HEIGHT = 30;

const Container = styled.div`
display: flex;
flex-direction: column;
align-items: center;
`;

const Image = styled.img`
height: ${IMG_HEIGHT}rem;
`;

interface Props {
    hasUnderstood: () => void;
    isVisible: boolean;
    close: () => void;
    warnMessage: string;
}

const NetworkWarnModal: React.FC<Props> = (props: Props) => {

    return (
        <Modal 
            title='La sécurité est notre priorité'
            visible={props.isVisible}
            close={props.close}
            width='90%'
            children={
            <Container>
                <Image src={illustration} />
                <Text>
                    {props.warnMessage} 
                </Text>
                <Button onClick={props.hasUnderstood}>
                    J'ai compris
                </Button>
            </Container>
        }               
    />
  );
};

export default NetworkWarnModal;