import styled from 'styled-components';
import background from './../../assets/illustrations/pattern-inscription-connexion.svg';
import { PADDING } from './Navigation/Header';
import selectPhoto from './../../assets/icons/profile/photo-profil.svg';
import { Theme } from '../../assets/Theme';
import { createGlobalStyle } from 'styled-components';

export const AVATAR_WIDTH = 10.4;
export const AVATAR_HEIGHT = 10.4;
export const AVATAR_WIDTH_LARGE_SCREEN = 8;
export const AVATAR_HEIGHT_LARGE_SCREEN = 8;
export const BUTTON_HEIGHT = 5;
export const BUTTON_WIDTH = 30;
export const FOOTER_HEIGHT = '10vh';
export const INPUT_CONTAINER_HEIGHT = '5.8rem';
export const INPUT_BORDER_SIZE = '0.1rem';
export const INPUT_PADDING = '1rem';

interface IContainer {
    register?: boolean,
    showBackground?: boolean
}

export const Container = styled.div<IContainer>`
display: flex;
flex-direction: column;
align-items: center;
background-color: ${props => props.theme.colors.powderWhite};
padding: 0 ${PADDING} ${PADDING} ${PADDING};
overflow-y: auto;
overflow-x: hidden;
min-height: 100vh;
${({ register }) => register && `
    position: relative;
`}
${({ showBackground }) => showBackground && `
    background: url(${background}) bottom center no-repeat ${Theme.colors.powderWhite};
`}
`;

export const Body = styled.div`
display: flex;
flex-direction: column;
align-items: center;
`;

export const Title = styled.h1<TextProps>`
color: ${props => props.theme.colors.violet};
font-size: 3.2rem;
font-weight: 700;
text-align: center;
margin-top: ${props => props.marginTop ? props.marginTop : 0};
margin-right: ${props => props.marginRight ? props.marginRight : 0};
margin-bottom: ${props => props.marginBottom ? props.marginBottom : 0};
margin-left: ${props => props.marginLeft ? props.marginLeft : 0};
`;

export const H4 = styled.h4<TextProps>`
color: ${props => props.theme.colors.violet};
font-size: 1.8rem;
font-weight: 700;
text-align: ${props => props.align || 'center'};
margin-top: ${props => props.marginTop ? props.marginTop : 0};
margin-right: ${props => props.marginRight ? props.marginRight : 0};
margin-bottom: ${props => props.marginBottom ? props.marginBottom : 0};
margin-left: ${props => props.marginLeft ? props.marginLeft : 0};
z-index: 999;
`;

export const H3 = styled.h3<TextProps>`
color: ${props => props.theme.colors.violet};
font-size: 2rem;
font-weight: 700;
text-align: ${props => props.align || 'center'};
margin-top: ${props => props.marginTop ? props.marginTop : 0};
margin-right: ${props => props.marginRight ? props.marginRight : 0};
margin-bottom: ${props => props.marginBottom ? props.marginBottom : 0};
margin-left: ${props => props.marginLeft ? props.marginLeft : 0};
z-index: 999;
line-height: 2.8rem;
`;

export const P1 = styled.p<TextProps>`
color: ${props => props.color ? props.color : props.theme.colors.violet};
font-size: 1.6rem;
font-weight: 700;
z-index: 999;
line-height: 140%;
margin-top: ${props => props.marginTop ? props.marginTop : 0};
margin-right: ${props => props.marginRight ? props.marginRight : 0};
margin-bottom: ${props => props.marginBottom ? props.marginBottom : 0};
margin-left: ${props => props.marginLeft ? props.marginLeft : 0};
&:first-letter {
    text-transform: capitalize;
}
`;

export const P2 = styled.p<TextProps>`
color: ${props => props.theme.colors.white};
font-size: 1.3rem;
font-weight: 700;
z-index: 999;
line-height: 1.8rem;
text-align: center;
margin-top: ${props => props.marginTop ? props.marginTop : 0};
margin-right: ${props => props.marginRight ? props.marginRight : 0};
margin-bottom: ${props => props.marginBottom ? props.marginBottom : 0};
margin-left: ${props => props.marginLeft ? props.marginLeft : 0};
&:first-letter {
    text-transform: capitalize;
}
`;

export const P3 = styled.p<TextProps>`
color: ${props => props.theme.colors.greyBorder};
font-weight: 400;
font-size: 1.2rem;
line-height: 100%;
margin-top: ${props => props.marginTop ? props.marginTop : 0};
margin-right: ${props => props.marginRight ? props.marginRight : 0};
margin-bottom: ${props => props.marginBottom ? props.marginBottom : 0};
margin-left: ${props => props.marginLeft ? props.marginLeft : 0};
&:first-letter {
    text-transform: capitalize;
}
`;

interface TextProps {
    success?: boolean,
    align?: string,
    marginTop?: string,
    marginRight?: string,
    marginLeft?: string,
    marginBottom?: string,
    bold?: boolean,
    size?:string
    width?: string,
    padding? : string,
    limitTextHorizontal?: boolean,
    noLineHeight?: boolean
}

export const Text = styled.p<TextProps>`
color: ${props => props.color ? props.color : props.theme.colors.violet};
text-align: ${props => props.align || 'center'};
margin: ${props => props.marginTop || 0} ${props => props.marginRight || 0} ${props => props.marginBottom || 0} ${props => props.marginLeft || 0};
font-weight: ${props => props.bold ? 'bold' : '400'};
font-size: ${props => props.size ? props.size : Theme.fontSizes.normal};
width: ${props => props.width ? props.width : 'auto'};
padding: ${props => props.padding? props.padding : 0};
word-break: break-word;
line-height: ${props => props.noLineHeight ? 'unset' : '2.2rem'};

&:first-letter {
    text-transform: capitalize;
}
${({ limitTextHorizontal }) => limitTextHorizontal && `
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
`}
`;

interface IconProps {
    image: string,
    width: number,
    height: number,
    borderRadius?: string,
    inputPlaceholder?: boolean,
    avatar?: boolean,
    avatarProfile?: boolean,
    relationCard?:boolean,
    tribu?: boolean,
    inputTrash?: boolean,
    backgroundCover?: boolean,
    backgroundAuto?: boolean,
    backgroundPosition?: string,
    absolute?: boolean,
    marginTop?: string,
    marginRight?: string,
    marginLeft?: string,
    marginBottom?: string,
    isFromWebMobile?: boolean,
    position?: string,
    bottom?: string
    top?: string
};

export const Icon = styled.div<IconProps>`
background-image: url(${props => props.image});
background-repeat: no-repeat;
background-position: ${props => props.backgroundPosition ? props.backgroundPosition : 'center'};
background-size: ${props => props.backgroundCover ? 'cover' : props.backgroundAuto ? 'auto' : 'contain'};
min-width: ${props => props.width + 'rem'};
height: ${props => props.height + 'rem'};
border-radius: ${props => props.borderRadius ? props.borderRadius : 0};
margin-top: ${props => props.marginTop ? props.marginTop : 0};
margin-right: ${props => props.marginRight ? props.marginRight : 0};
margin-bottom: ${props => props.marginBottom ? props.marginBottom : 0};
margin-left: ${props => props.marginLeft ? props.marginLeft : 0};
position: ${props => props.position ? props.position : 'unset'};
bottom: ${props => props.bottom ? props.bottom : 'unset'};
top: ${props => props.top? props.top : 'unset'};
z-index: 999;
${({ avatar, width }) => avatar && `
    position: absolute;
    left: calc(-${width}rem/2);
    background-size: cover;
`}
${({ tribu }) => tribu && `
    background-size: contain;
`}
${({ inputPlaceholder }) => inputPlaceholder && `
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-50%, -50%);
`}
${({ avatarProfile }) => avatarProfile && `
    position: absolute;
    top: 50%;
    background-size: cover;
`}
${({ absolute }) => absolute && `
    position: absolute;
`}
${({ relationCard, width }) => relationCard && `
    position: absolute;
    left: calc(-${width}rem/2);
    background-size: cover;
`}
`;

interface AlertProps {
    success: boolean,
}

export const UpFooterButton = styled.button<IconProps>`
background-color: ${props => props.theme.colors.pinkStrong};
background-image: url(${props => props.image});
background-position: center;
background-repeat: no-repeat;
border: none;
width: ${props => props.width + 'rem'};
height: ${props => props.height + 'rem'};
border-radius: 50%;
position: fixed;
bottom: ${props => props.isFromWebMobile ? '2%' : `calc(${FOOTER_HEIGHT} + 2%)`};
right: 1rem;
z-index: 9999;
`;

export const Alert = styled.div<AlertProps>`
padding: 1rem;
display: flex;
color: ${props => props.success ? 'green' : 'red'};
text-align: center;
`;

interface FormProps {
    marginTop?: string,
    marginRight?: string,
    marginLeft?: string,
    marginBottom?: string,
}

export const Form = styled.div<FormProps>`
display: flex;
flex-direction: column;
width: 100%;
margin-top: ${props => props.marginTop ? props.marginTop : 0};
margin-right: ${props => props.marginRight ? props.marginRight : 0};
margin-bottom: ${props => props.marginBottom ? props.marginBottom : 0};
margin-left: ${props => props.marginLeft ? props.marginLeft : 0};
`;

interface FormItemProps {
    padding? : number,
    marginBottom?: number
    marginTop?: number
}
export const FormItem = styled.div<FormItemProps>`
display: flex;
flex-direction: column;
margin-bottom: ${props => props.marginBottom ? props.marginBottom + 'rem' : 0};
margin-top: ${props => props.marginTop ? props.marginTop + 'rem' : 0};
padding: 0 ${props => props.padding && props.padding + 'rem'} 0 ${props => props.padding && props.padding + 'rem'};
`;

interface LabelProps {
    color?: string,
    marginTop?: string,
    marginRight?: string,
    marginLeft?: string,
    marginBottom?: string,
    align?: string
}

export const Label = styled.label<LabelProps>`
font-size: 1.6rem;
color: ${props => props.color ? props.color : props.theme.colors.violet};
margin: ${props => props.marginTop || '0'} ${props => props.marginRight || '0'} ${props => props.marginBottom || '0.5rem'} ${props => props.marginLeft || '0'};
text-align: ${props => props.align || 'left'};
width: 100%;
`;

interface IInput {
    width?: string,
    noBorder?: boolean
}

export const Input = styled.input<IInput>`
width: ${props => props.width ? props.width : `calc(100% - ${INPUT_PADDING})`};
height: 4.8rem;
background-color: ${Theme.colors.white};
border: none;
padding-left: ${INPUT_PADDING};
color: ${props => props.theme.colors.violet};
border: 0.1rem solid ${Theme.colors.greyBorder};
border-radius: 1rem;
font-size: 1.6rem;
::placeholder { 
    color: ${Theme.colors.greyBorder}; 
    font-size: 1.6rem;
    font-weight: 400;
}
&:-webkit-autofill {
    transition: "color 9999s ease-out, background-color 9999s ease-out";
    transition-delay: 9999s;
}
${({ noBorder }) => noBorder && `
    border: none;
`}
`;

interface TextAreaProps {
    width?: string;
    borderRadius?: number;
    backgroundColor?: string;
    noBorder?: boolean
}

export const TextArea = styled.textarea<TextAreaProps>`
width: ${props => props.width ? props.width : 'auto'};
background: ${props => props.backgroundColor ? props.backgroundColor : 'transparent'};
padding: ${PADDING};
color: ${props => props.theme.colors.violet};
border: ${props => props.noBorder ? 'none' : `0.1rem solid ${Theme.colors.greyBorder}`};
border-radius: ${props => props.noBorder ? 0 : props.borderRadius ? props.borderRadius + 'rem' : '1rem'};
font-family: 'Manrope';
font-size: 1.6rem;
resize: none;
::placeholder { 
    color: ${Theme.colors.greyBorder}; 
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 2.24rem; 
}
`;

interface ButtonProps {
    width?: number,
    height?: number,
    upFooter?: boolean,
    marginTop?: number,
    marginBottom?: number,
    backgroundColor?: string,
    border?: boolean,
    borderColor?: string,
    color?: string
};

export const Button = styled.button<ButtonProps>`
height: ${props => props.height ? props.height + 'rem' : BUTTON_HEIGHT + 'rem'};
width: ${props => props.width ? props.width + 'rem' : BUTTON_WIDTH + 'rem'};
border: ${props => props.border ? `0.1rem solid ${props.borderColor}` : 'none'};
background: ${props => props.backgroundColor ? props.backgroundColor : props.theme.colors.violet};
color: ${props => props.color ? props.color : props.theme.colors.white};
border-radius: 2.5rem;
margin-top: ${props => props.marginTop ? props.marginTop + 'rem' : '2rem'};
margin-bottom: ${props => props.marginBottom ? props.marginBottom + 'rem' : 0};
transition: all 0.5s ease;
cursor: pointer;
font-weight: 500;
font-size: 1.6rem;
line-height: 100%;
&:disabled {
    cursor: not-allowed;
    background: ${Theme.colors.greyBorder};
    color: ${props => props.theme.colors.violetLight};
}
${({ upFooter }) => upFooter && `
    position: fixed;
    bottom: calc(${FOOTER_HEIGHT} + 2%);
    left: calc((100% - ${BUTTON_WIDTH}rem) / 2);
`}

`;

interface AvatarContainerProps {
    align?: string;
};

export const AvatarContainer = styled.div<AvatarContainerProps>`
display: flex;  
justify-content: center;
align-items: center;
position: absolute;
top: 0;
bottom: 0;
`;

interface ProfileContainerProps {
    upFooter?: boolean,
    marginTop?: number,
    marginBottom?: number,
};

export const ProfileContainer = styled.div<ProfileContainerProps>`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
width: 100%;
margin-top: ${props => props.marginTop && props.marginTop + 'rem'};
`;

export const Profile = styled.div`
display: flex;
flex-direction: column;
align-items: center;
width: 100%;
`;

interface ProfileInfoProps {
    height?: number,
    marginTop?: string,
};

export const ProfileInfo = styled.div<ProfileInfoProps>`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
background-color: ${props => props.theme.colors.white};
border-radius: 1rem;
width: 100%;
margin-top: ${props => props.marginTop ? props.marginTop : 0};
`;

export const Password = styled.div`
position: relative;
`;

export const SelectPhoto = styled.button`
background-color: ${props => props.theme.colors.pinkStrong};
background-image: url(${selectPhoto});
background-position: center;
background-repeat: no-repeat;
width: 4rem;
height: 4rem;
position: absolute;
border-radius: 50%;
left: calc(50% + ${AVATAR_WIDTH}rem/2 - 3rem);
bottom: calc(-${AVATAR_HEIGHT}rem + 4rem/2);
border: none;
padding: 0;
z-index: 999;
`;


export const EmptyData = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
margin-top: 2rem;
overflow: hidden;
`;

export const CircleShape = styled.div`
width: 34rem;
height: 43rem;
background-color: ${Theme.colors.white};
transform: rotateZ(-20deg);
border-radius: 50%;
display: flex;
align-items: center;
justify-content: center;
`;

interface IIllustration {
    src: string;
};

export const Illustration = styled.div<IIllustration>`
width: 30rem;
height: 33rem;
background-image: url(${props => props.src});
background-position: top center;
background-repeat: no-repeat;
background-size: contain;
transform: rotateZ(20deg);
`;

interface INotificationBadge {
    pinkColor?: boolean,
    width?: string,
    height?: string,
    top?: string,
    right?: string,
}

export const NotificationBadge = styled.div<INotificationBadge>`
width: ${props => props.width ? props.width : '0.9rem'};
height: ${props => props.height ? props.height : '0.9rem'};
background-color: ${props => props.pinkColor ? props.theme.colors.pinkStrong : props.theme.colors.violet};
color: ${props => props.theme.colors.white};
border-radius: 50%;
position: absolute;
top: ${props => props.top ? props.top : 0};
right: ${props => props.right ? props.right : 0};
display: flex;
align-items: center;
justify-content: center;
font-weight: 600;
transition: all 500ms ease;
`;

export const DatePickerWrapperStyles = createGlobalStyle`
    .react-datepicker-popper {
        z-index: 99999;
        width: calc(100% - 1rem);
    }
    .date_picker.full-width {
        width: calc(100% - 1rem);
        height: calc(4.8rem - 1rem);
        background: ${props => props.theme.colors.white};
        padding: 0.5rem;
        color: ${props => props.theme.colors.violet};
        border: 0.1rem solid ${props => props.theme.colors.greyLight};
        border-radius: 1rem;
        display: flex;
        align-items: center;
    }
    .date_picker.full-width input {
        width: calc(100% - 1rem);
        border: none;
        height: calc(4.8rem - 1rem);
        background: transparent;
        color: ${props => props.theme.colors.violet};
        font-size: 1.6rem;
    }

    //
    .react-datepicker {
        border: none;
        margin-top: 1rem;
        margin-bottom: 1rem;
        width: calc(100% - 1rem);
    }

    .react-datepicker__navigation {
        top: 1.2rem;
    }
   
    .react-datepicker__header {
        background-color: ${Theme.colors.white};
        border: none;
        padding: 0;
        margin-top: 2rem;
        margin-bottom: 1rem;
        font-weight: 600;
        text-transform: capitalize;
    }

    .react-datepicker__month-container {
        width: calc(100% - 1rem);
    }

    .react-datepicker__current-month {
        text-transform: capitalize;
        color: ${Theme.colors.violet};
        margin-bottom: 1rem;
        font-size: 2rem;
    }

    .react-datepicker__day {
        color: ${Theme.colors.violet};
        line-height: 4.3rem;
        font-size: 1.6rem;
    }
    
    .react-datepicker__day--selected {
        background-color: ${Theme.colors.pinkStrong};
        color: ${Theme.colors.white};
        border-radius: 50%;
    }

    .react-datepicker__day-name {
        color: ${Theme.colors.violet};
        font-size: 1.6rem;
        line-height: 3.7rem;
    }

    .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
        width: calc(100% / 8);
        height: 4.3rem;
    }

    .react-datepicker__day--selected:hover {
        background-color: ${Theme.colors.pinkStrong};
        border-radius: 50%;
    }

    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
        height: 3rem;
        padding: 0.5rem 1rem;
        white-space: nowrap;
    }
`;