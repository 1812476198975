import * as React from 'react';
import { Container, Text, Form, Button } from '../shared/styled';
import Header from '../shared/Navigation/Header';
import { ERoutes } from '../shared/Navigation/ERoutes';
import { useNavigate, useParams } from 'react-router-dom';
import Axios, { AxiosError } from 'axios';
import { observer } from 'mobx-react';
import { AlertStoreContext } from '../../store/alertStore';
import { EToastStatus } from '../shared/Information/Toast';
import eye from './../../assets/icons/eye-on.svg';
import { AppStoreContext } from '../../store/appStore';
import TextInput from '../shared/Input/TextInput';
import { passwordRegex } from '../utils/regex';

const { REACT_APP_API_URL } = process.env;

const NewPassword : React.FC = observer(() => {

    let navigate = useNavigate();

    let { accessToken } = useParams();

    const { app } = React.useContext(AppStoreContext);
    const { alert } = React.useContext(AlertStoreContext);

    const [seePassword, setSeePassword] = React.useState(false);

    const [password, setPassword] = React.useState<string>('');
    
    const passwordIsValid: boolean = password != null ? passwordRegex.test(password) : false;

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
    };

    const toggleSeePassword = () => setSeePassword(!seePassword);

    const checkPasswordValidity = (): void => {
        const passwordRegex = "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"; // At least 8 characters, one uperCase letter, one lowerCase letter, one digit or special character
        if (!password.match(passwordRegex))
        {
            alert.setAlert(EToastStatus.FAIL, "Le mot de passe doit être composé de 8 caractères, une majuscule, une minuscule, un nombre ou un caractère spécial", null);
            return;
        };
        changePassword();
    };

    const changePassword = async () => {
        app.setLoading(true);
        try {
            const response = await Axios.post(`${REACT_APP_API_URL}/api/auth/confirm/newPassword`, { accessToken, password });
            if (response.data != null) 
            {
                alert.setAlert(EToastStatus.SUCCESS, response.data.message, null);
                navigate(ERoutes.Login);
            };
        } catch (error) {
            const err = error as AxiosError;
            if (err.response) 
            {
                alert.setAlert(EToastStatus.FAIL, err.response.data.message, null);
            };
        };
        app.setLoading(false);
    };

    return (
        <Container>
            <Header 
                title="Nouveau mot de passe" 
                url={ERoutes.Login} 
                noIcon
            />
            <Text align='left' marginBottom="2rem" >
                Saisissez votre nouveau mot de passe. 
            </Text>
            <Form>
                <TextInput
                    id="password"
                    type={seePassword ? "text" : "password"}
                    label='Nouveau mot de passe*'
                    placeholder=" Tapez votre nouveau mot de passe"
                    value={password}
                    onChange={e => handleChange(e)}    
                    marginBottom={0.5}
                    iconSrc={eye}
                    onClickIcon={toggleSeePassword}
                    validityIndicator
                    isValid={passwordIsValid}
                    helpText={"Au moins 8 caractères dont une majuscule, une minuscule, un nombre et un caractère spécial"}
                />
            </Form>
            <Button onClick={checkPasswordValidity}>
                Valider   
            </Button>           
        </Container>
    )
});

export default NewPassword;
