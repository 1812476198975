import * as React from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import {  NotificationBadge } from '../styled';


export const SELECT_OPTION_HEIGHT = '3.4rem';
export const BORDER_SELECT_OPTION = '0.1rem';
export const PADDING_SELECT_CONTAINER = '0.1rem';

export const SelectContainer = styled.div`
display: flex;
justify-content: center;
border: ${BORDER_SELECT_OPTION} solid ${props => props.theme.colors.greyMedium};
border-radius: 2.6rem;
padding: ${PADDING_SELECT_CONTAINER};
width: 100%;
margin-bottom: 2rem;
`;

interface SelectedOption {
    selected: boolean;
}

export const SelectOption = styled.div<SelectedOption>`
display: flex;
align-items: center;
justify-content: center;
width: 50%;
height: ${SELECT_OPTION_HEIGHT};
background-color: ${props => props.selected ? props.theme.colors.pinkStrong : props.theme.colors.powderWhite};
border-radius: 2.6rem;
color: ${props => props.selected ? props.theme.colors.white : props.theme.colors.greyMedium};
transition: all 250ms ease;
cursor: pointer;
position: relative;
font-size: 1.6rem;
`;

interface Props {
    option1: string,
    option2: string,
    url1: string,
    url2: string,
    notifications?: number | null
}

const SelectOptions : React.FC<Props> = (props: Props) => {

    let navigate = useNavigate();
    let location = useLocation();

    const handleOption1 = () => navigate(props.url1);

    const handleOption2 = () => navigate(props.url2);

    return (
        <SelectContainer>
            <SelectOption 
                selected={location.pathname === props.url1}
                onClick={handleOption1}
            >
                {props.option1}
            </SelectOption>
            <SelectOption 
                selected={location.pathname === props.url2}
                onClick={handleOption2}
            >
                {props.option2}
                {props.notifications != null && props.notifications > 0 &&
                    <NotificationBadge 
                        pinkColor={location.pathname === props.url1} 
                        width='2rem'
                        height='2rem'
                        top='-0.5rem'
                        right='-0.5rem'
                    >
                        {props.notifications}
                    </NotificationBadge>
                }
            </SelectOption>
    </SelectContainer>
)
}

export default SelectOptions;


