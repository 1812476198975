import * as React from 'react';
import { DataStoreContext } from '../../store/rootStore';
import { IUser } from '../../store/user';
import RelationCard from './RelationCard';
import { observer } from 'mobx-react';
import SearchBar from '../shared/Input/SearchBar';
import styled from 'styled-components';
import NetworkWarnModal from '../shared/Information/NetworkWarnModal';
import { AppStoreContext } from '../../store/appStore';

const Container = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`;

export const Parents = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
width: 100%;

/* Large devices (portrait tablets and large phones, 768px and up) */
@media only screen and (min-width: 768px) {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
}
`;

const ParentsList: React.FC = observer(() => {

    const [offset, setOffset] = React.useState<number>(0);
    const { network, userLogged } = React.useContext(DataStoreContext);
    const [showWarnModal, setShowWarnModal] = React.useState<boolean>(false);
    const { app } = React.useContext(AppStoreContext)

    const warnMessage: string = "Avant d'inviter un nouveau parent dans votre réseau, nous vous recommendons de l'avoir déjà rencontré et de vous être assuré que vous pouvez lui faire confiance.";

    const parents = network.parents != null ? network.parents : [];

    // FOR SEARCH BAR:
    const [parentName, setParentName] = React.useState<string>('');

    const handleSearchParent = async (e : React.ChangeEvent<HTMLInputElement>) => {
        network.setParents([]);
        setOffset(0);
        const textContent: string = e.target.value;
        setParentName(textContent);
    };
    
    const observer = React.useRef<IntersectionObserver | null>(null);

    const lastParentRef = React.useCallback((node: any) => {
        if (app.loading) 
        {
            return;
        };
        if (observer.current != null) 
        {
            observer.current.disconnect();
        };
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && network.fetchHasMoreParents) 
            {
                setOffset(offset + 10);
            };
        });
        if (node) 
        {
            observer.current.observe(node);
        }   
    }, [app.loading, network.fetchHasMoreParents]);

    const acceptWarning = () => {
        setShowWarnModal(false);
        network.setHasUnderstoodWarningBeforeInviting(true);
    };

    const addParent = async (parent: IUser) => {
        // For every first add parent, show the warn modal before allowing to add a parent
        if (network.hasUnderstoodWarningBeforeInviting && !showWarnModal)
        {
            app.setLoading(true);
            await network.addParent(parent);
            app.setLoading(false);    
        }
        else
        {
            setShowWarnModal(true);
        };
    };

    const getParents = async () => {
        app.setLoading(true);
        await network.getParents(offset);
        app.setLoading(false);
    }
    const findParents = async () => {
        app.setLoading(true);
        await network.findParents(parentName, offset);
        app.setLoading(false);
    }

    React.useEffect(() => {
        network.setParents([]);
    }, []);

    React.useEffect(() => {
        if (parentName.length > 0)
        {
            findParents();    
        }
    }, [offset, parentName]);

    React.useEffect(() => {
        if (parentName.length === 0)
        {
            getParents();
        }
    }, [offset, parentName]);

  return (
    <Container>
        {showWarnModal &&
            <NetworkWarnModal 
                hasUnderstood={acceptWarning} 
                isVisible={showWarnModal} 
                close={() => setShowWarnModal(false)}
                warnMessage={warnMessage}
            />
        }
        <SearchBar
            label="Chercher un parent à l'aide de son nom..."
            value={parentName}
            action={handleSearchParent}
            marginBottom={3}         
        />
        <Parents>
            {parents.map((parent: IUser, index) => {
                if (parents.length === index + 1)
                {
                    return (
                        <RelationCard 
                                ref={lastParentRef}
                                key={parent.id}
                                parent={parent}
                                isFriend={false}
                                isInvitedByUser={network.isInvitedByUser(parent)}
                                userIsInvited={network.userIsInvited(parent, userLogged.user?.id)}
                                addParent={() => addParent(parent)}
                        />
                    )
                } 
                else
                {
                    return (
                        <RelationCard 
                            key={parent.id}
                            parent={parent}
                            isFriend={false}
                            isInvitedByUser={network.isInvitedByUser(parent)}
                            userIsInvited={network.userIsInvited(parent, userLogged.user?.id)}
                            addParent={() => addParent(parent)}
                        />                                    
                        )
                }
            })}
        </Parents>
    </Container>
  )
});

export default ParentsList;