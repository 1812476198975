import * as React from 'react';
import { useNavigate } from "react-router-dom";
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { Text, AvatarContainer, Icon, AVATAR_HEIGHT, AVATAR_WIDTH, NotificationBadge, AVATAR_WIDTH_LARGE_SCREEN, AVATAR_HEIGHT_LARGE_SCREEN } from '../shared/styled';
import envoye from './../../assets/icons/parentCard/envoye.svg';
import time from './../../assets/icons/parentCard/time.svg';
import trash from './../../assets/icons/parentCard/trash.svg';
import calendar from './../../assets/icons/parentCard/calendar-check-fill.svg';
import contact from './../../assets/icons/parentCard/contact.svg';
import { dateToString, transformDateToString } from '../utils/dateManager';
import check from './../../assets/icons/parentCard/check-on.svg';
import { Modal } from '../shared/Information/Modal';
import ManageTransaction, { EManageStatus } from './ManageTransaction';
import { ERoutes } from '../shared/Navigation/ERoutes';
import { DataStoreContext } from '../../store/rootStore';
import { ETransactionNeed, ETransactionType, ITransaction, ITransactionStatus } from '../../store/transaction';
import { IUser } from '../../store/user';
import defaultUser from './../../assets/icons/avatar/DefaultUser.png';
import networkIcon from './../../assets/icons/parentCard/network.svg';
import { Theme } from '../../assets/Theme';
import { AppStoreContext } from '../../store/appStore';

export const CONTAINER_HEIGHT = 17;

const PARENT_INFO_WIDTH = '30rem';
const PARENT_INFO_WIDTH_LARGE_SCREEN = '25rem'

export const Container = styled.div`
display: flex;
min-height: ${CONTAINER_HEIGHT}rem;
background-color: ${props => props.theme.colors.powderWhite};
margin-bottom: 1rem;
width: calc(${AVATAR_WIDTH/2}rem + ${PARENT_INFO_WIDTH});
position: relative;
/* Large devices (portrait tablets and large phones, 768px and up) */
@media only screen and (min-width: 768px) {
    flex-wrap: wrap;
    /* margin: 1rem; */
    width: calc(${AVATAR_WIDTH_LARGE_SCREEN/2}rem + ${PARENT_INFO_WIDTH_LARGE_SCREEN});
}
`;

export const Info = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
margin-left: calc(${AVATAR_WIDTH}rem / 2);
background-color: ${props => props.theme.colors.white};
width: ${PARENT_INFO_WIDTH};
border-radius: 1rem;
position: relative;
/* Large devices (portrait tablets and large phones, 768px and up) */
@media only screen and (min-width: 768px) {
    width: ${PARENT_INFO_WIDTH_LARGE_SCREEN};
    margin-left: calc(${AVATAR_WIDTH_LARGE_SCREEN}rem / 2);
}
`;

interface Item {
    justifyContent?: string;
    marginTop?: number;
    noMarginLeft?: boolean;
    marginBottom?: number;
    padding?: number;
    align?: string;
}

export const Item = styled.div<Item>`
display: flex;
align-items: ${props => props.align ? props.align : 'center'};
margin-left: ${props => props.noMarginLeft ? 0 : `calc(${AVATAR_WIDTH}rem/2 + 1rem)`};
margin-bottom: ${props => props.marginBottom ? props.marginBottom + 'rem' : '0.5rem'};
justify-content: ${props => props.justifyContent ? props.justifyContent : 'normal'};
margin-top: ${props => props.marginTop ? props.marginTop + 'rem' : 0};
padding: ${props => props.padding ? props.padding + 'rem' : 0};
/* Large devices (portrait tablets and large phones, 768px and up) */
@media only screen and (min-width: 768px) {
    margin-left: ${props => props.noMarginLeft ? 0 : `calc(${AVATAR_WIDTH_LARGE_SCREEN}rem/2 + 1rem)`};
}
`;

interface Status {
    color: string;
}

export const Status = styled.div<Status>`
font-size: ${Theme.fontSizes.normal};
background-color: ${props => props.color};
color: ${props => props.color === props.theme.colors.greyLightest ? props.theme.colors.greyMedium : props.theme.colors.white};
width: 8.5rem;
height: 2.5rem;
display: flex;
align-items: center;
justify-content: center;
border-radius: 1rem;
`;

export const Separator = styled.div`
width: 0.5rem;
height: 0.5rem;
border-radius: 50%;
background-color: ${props => props.theme.colors.greyLight};
margin-left: 1rem;
margin-right: 1rem;
`;
interface Props {
    transac: ITransaction,
    contactedParent: IUser | null,
    onClick: () => void,
    action: () => void,
};

const TransactionCard : React.FC<Props> = observer((props : Props) => {

    const { userLogged, transactions, message } = React.useContext(DataStoreContext);
    const { app } = React.useContext(AppStoreContext);

    let navigate = useNavigate();

    const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false);
    const [showAcceptModal, setShowAcceptModal] = React.useState<boolean>(false);

    const responsesReceived: number | null = props.transac?.responses != null ? props.transac?.responses.length : null;

    const openDeleteModal = () => setShowDeleteModal(true);

    const openAcceptModal = () => setShowAcceptModal(true);

    const goToconversation = (friendInTouch: IUser | null) => {
        message.setSelectFriend(friendInTouch);

        if (friendInTouch != null)
        {
            navigate(`${ERoutes.Conversation}/${friendInTouch.id}`);
        };
    };

    const friendAvatar: string = (props.contactedParent?.avatar != null  && props.contactedParent?.avatar.length > 0) ? props.contactedParent.avatar : defaultUser;

     // Accept a gard is only possible if the connected user is the contacted parent and if it is a SINGLE demand (PUBLIC DEMAND ARE ACCEPTED/REFUSED from Messagerie)
    const isGardToDo: boolean = userLogged.user?.id !== props.transac.sender?.id && props.transac.type === ETransactionType.SINGLE;
    const gardIsOpened: boolean = props.transac?.status === ITransactionStatus.OPENED;

    const isMyDemand: boolean = props.transac?.sender?.id === userLogged.user?.id;
    const transacIsFinished: boolean = new Date(props.transac?.start) < new Date();
    const infoStatus: string = transactions.getTransactionStatus(props.transac);

    const showNotificationBadge: boolean = props.transac.type === ETransactionType.PUBLIC && responsesReceived != null && props.transac.status === ITransactionStatus.OPENED;
    const showSendMessage: boolean = props.transac.type === ETransactionType.SINGLE;
    const showTrashBin: boolean = props.transac.status !== ITransactionStatus.CANCELED && !transacIsFinished;

    const titleDemandHours: string = props.transac.need === ETransactionNeed.GARD ? `De ${transformDateToString(props.transac.start)} À ${transformDateToString(props.transac.end)}` : `À ${transformDateToString(props.transac.start)}`;

    const isSentBy = () => {
        if (isGardToDo)
        {
            return `Envoyée par ${props.transac.sender.firstname}`;
        }
        else
        {
            if (props.transac.type === ETransactionType.SINGLE)
            {
                return `Envoyée à ${props.contactedParent?.firstname}`;
            };
            if (props.transac.type === ETransactionType.PUBLIC)
            {
                return 'Envoyée à tout mon réseau'
            };
        };
    };

    return (
        <>
            {showDeleteModal &&
                <Modal 
                    title={isMyDemand ? 'Annuler ma demande' 
                                      : props.transac.status === ITransactionStatus.CONFIRMED ? 'Je ne suis plus disponible'
                                      : 'Je ne suis pas disponible'
                    }
                    visible={showDeleteModal}
                    close={() => setShowDeleteModal(false)}
                    children={
                        <ManageTransaction
                            manageStatus={isMyDemand ? EManageStatus.CANCEL_MY_DEMAND 
                                                     : props.transac.status === ITransactionStatus.CONFIRMED ? EManageStatus.CANCEL 
                                                     : EManageStatus.REFUSE
                            } 
                            contactedParentId={props.contactedParent?.id}
                            closeModal={() => setShowDeleteModal(false)}
                            sendTo={props.contactedParent?.firstname}
                            action={props.action}
                            transac={props.transac}
                        />
                    }
                />
            }
            {showAcceptModal &&
                <Modal 
                    title='Accepter la demande'
                    visible={showAcceptModal}
                    close={() => setShowAcceptModal(false)}
                    children={
                        <ManageTransaction
                            manageStatus={EManageStatus.ACCEPT} 
                            contactedParentId={props.contactedParent?.id}
                            closeModal={() => setShowAcceptModal(false)}
                            sendTo={props.contactedParent?.firstname}
                            action={props.action}
                            transac={props.transac}
                        />
                    }
                />
            }
            <Container>
                <AvatarContainer onClick={props.onClick}>
                    <Icon 
                        image={props.transac.type === ETransactionType.SINGLE ? friendAvatar : networkIcon}
                        width={app.deviceIsTablet() ? AVATAR_WIDTH_LARGE_SCREEN : AVATAR_WIDTH} 
                        height={app.deviceIsTablet() ? AVATAR_HEIGHT_LARGE_SCREEN : AVATAR_HEIGHT}
                        borderRadius={'50%'}
                        backgroundCover
                    />
                </AvatarContainer>
                <Info>
                    <Item onClick={props.onClick}>
                        <Icon 
                            image={calendar} 
                            width={2.5} 
                            height={2.5}
                        />
                        {props.transac.start != null &&
                            <Text 
                                align='left'
                                marginLeft={'1rem'}
                                size={app.deviceIsTablet() ? Theme.fontSizes.intermediate : Theme.fontSizes.normal }
                                >
                                Le {dateToString(props.transac.start)}
                            </Text>
                        }
                    </Item>
                    <Item onClick={props.onClick}>
                        <Icon 
                            image={time} 
                            width={2.5} 
                            height={2.5}
                        />
                        <Text 
                            align='left'
                            marginLeft={'1rem'}
                            size={app.deviceIsTablet() ? Theme.fontSizes.intermediate : Theme.fontSizes.normal }
                        >
                            { titleDemandHours }
                        </Text>
                    </Item>
                    <Item onClick={props.onClick}>
                        <Icon 
                            image={contact} 
                            width={2.5} 
                            height={2.5}
                        />
                        <Text 
                            marginLeft={'1rem'}
                            align='left'
                            size={app.deviceIsTablet() ? Theme.fontSizes.intermediate : Theme.fontSizes.normal }
                        >
                            { isSentBy() }
                        </Text>
                    </Item>
                        <Item>
                            <Status color={transactions.getTransactionStatusColor(props.transac)}>
                                {infoStatus}
                            </Status>
                        {showSendMessage &&
                            <>
                                <Separator>&nbsp;</Separator>
                                <Icon 
                                    image={envoye} 
                                    width={2.5} 
                                    height={2.5}
                                    onClick={() => goToconversation(props.contactedParent)}
                                />
                            </>
                        }
                        {showTrashBin &&
                            <>
                                <Separator>&nbsp;</Separator>
                                <Icon 
                                    image={trash} 
                                    width={2.5} 
                                    height={2.5}
                                    onClick={openDeleteModal}
                                />
                            </>
                        }
                        {isGardToDo && gardIsOpened && !transacIsFinished &&
                            <>
                                <Separator>&nbsp;</Separator>
                                    <Icon 
                                        image={check} 
                                        width={2.5} 
                                        height={2.5}
                                        onClick={openAcceptModal}
                                    />
                            </>
                        }
                    </Item>
                </Info>
                { showNotificationBadge && 
                    <NotificationBadge
                        pinkColor
                        width='2rem'
                        height='2rem'
                        top='-0.5rem'
                        right='-0.5rem'
                    >
                        {responsesReceived}
                    </NotificationBadge>    
                }
            </Container>
        </>
    )
});

export default TransactionCard;
