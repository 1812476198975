import React from 'react';
import styled from 'styled-components';
import { APP_VERSION } from '../../version';
import Agreement from '../shared/Information/Agreement';
import Header from '../shared/Navigation/Header';
import { Container, Text } from '../shared/styled';
import { EAgreementsURL } from '../utils/EAgreements';

const Wrapper = styled.div`
display: flex;
flex-direction: column;
align-items: center;
`;

const TextContainer = styled.div``;

const OtherInfoContainer = styled.div`
position: absolute;
bottom: 0;
`;

const About: React.FC = () => {

    const [currentAgreementToRead, setCurrentAgreementToRead] = React.useState<EAgreementsURL | null>(null);
  
    const handleOpenAgreement = (agreement: EAgreementsURL) => setCurrentAgreementToRead(agreement);
    const handleCloseCurrentAgreement = () => setCurrentAgreementToRead(null);

    return (
        <Container>
            <Header title={'A propos'} />
            { currentAgreementToRead != null ?
                <Agreement 
                    src={currentAgreementToRead}
                    visible={true}
                    close={handleCloseCurrentAgreement}
                />
            :
                <Wrapper>
                    <TextContainer>
                        <Text marginTop='1rem' marginBottom='2rem' align='justify'>
                            Parce que la vie de parents n'est pas toujours simple et parce que s'entraider ça fait du bien, Wepoppins est là pour vous aider à trouver des parents de confiance quand vous en avez besoin : accompagner votre enfant à son activité, le garder en cas de problèmatique d'emploi du temps, gérer les imprévus...
                        </Text>
                        <Text marginTop='1rem' marginBottom='2rem' align='justify'>
                            Si vous avez des suggestions à nous communiquer pour améliorer l'application, n'hésitez pas à utiliser le formulaire de contact.
                        </Text>
                    </TextContainer>
                    <OtherInfoContainer>
                        <Text marginTop='1rem' bold >
                            Version de l'application : {APP_VERSION}      
                        </Text>
                        <Text marginTop='1rem' onClick={() => handleOpenAgreement(EAgreementsURL.CGU)}>
                            Conditions générales d'utilisation
                        </Text>
                        <Text marginTop='1rem' onClick={() => handleOpenAgreement(EAgreementsURL.CONFIDENTIALITY_POLICY)}>
                            Politique de confidentialité
                        </Text>
                        <Text marginTop='1rem' onClick={() => handleOpenAgreement(EAgreementsURL.CHARTER_OF_GOOD_CONDUCT)}>
                            Charte de bonne conduite
                        </Text>
                    </OtherInfoContainer>
                </Wrapper>
            }
        </Container>
)
}

export default About