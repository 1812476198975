import styled from 'styled-components';
import background from './../../assets/illustrations/pattern-home.svg';
import logo from './../../assets/logo/logo-couleur.svg';
import { Icon, Text } from '../shared/styled';
import { Theme } from '../../assets/Theme';
import appStore from './../../assets/logo/store/app-store.svg';
import playStore from './../../assets/logo/store/play-store.svg';


const Container = styled.div`
background: url(${background}) top center no-repeat ${props => props.theme.colors.powderWhite};
height: 100vh;
width: 100vw;
background-size: cover;
`; 

const Content = styled.div`
display: flex;
flex-direction: column;
align-items: center;
position: fixed;
top: 10%
`;

const LogoContainer = styled.div`
width: 30rem;
height: 30rem;
background: ${Theme.colors.white};
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
border-radius: 50%;
`;

const Logo = styled.div`
background: url(${logo}) top center no-repeat;
width: 100%;
height: 4rem;
`;

const Stores = styled.div`
display: flex;
width: 100%;
justify-content: space-around;
`;

const STORE_ICON_WIDTH = 15;
const STORE_ICON_HEIGTH = 15;

export const moveToAppleStore = () => window.location.href = 'https://apps.apple.com/fr/app/wepoppins/id6446250471';
export const moveToPlayStore = () => window.location.href = 'https://play.google.com/store/apps/details?id=com.wepoppins';

const Login = () => {

    return (
        <Container>
            <Content> 
                <LogoContainer>
                    <Logo />
                </LogoContainer>
                <Text marginTop='2rem' padding='1rem'>
                    Pour profiter du service, veuillez ouvrir l'application depuis votre mobile ou téléchargez la gratuitement.
                </Text>
                <Stores>
                    <Icon
                        image={appStore} 
                        width={STORE_ICON_WIDTH} 
                        height={STORE_ICON_HEIGTH}
                        onClick={moveToAppleStore}
                    />
                    <Icon
                        image={playStore} 
                        width={STORE_ICON_WIDTH} 
                        height={STORE_ICON_HEIGTH}
                        onClick={moveToPlayStore}
                    />
                </Stores>
            </Content>  
        </Container>
)
};

export default Login;
