import * as React from 'react';
import styled from 'styled-components';
import { FormItem, Button, Text, TextArea, Icon } from '../shared/styled';
import { DataStoreContext } from '../../store/rootStore';
import pen from './../../assets/icons/search/pen.svg';
import { Theme } from '../../assets/Theme';
import { observer } from 'mobx-react';
import { CurrentStep as Container } from '../DirectPages/Search';
import { ESearchSteps } from './ESearchSteps';
import { ETransactionType } from '../../store/transaction';

const Body = styled.div`
width: 100%;
margin-top: 1rem;
display: flex;
flex-direction: column;
background-color: ${props => props.theme.colors.white};
border-radius: 1rem;
`;

const Item = styled.div`
display: flex;
align-items: center;
padding-left: 1rem;
padding-top: 1rem;
margin-bottom: 2rem;
`;

interface Props {
    demandType: ETransactionType;
    message: string;
    setMessage: (e : React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const PrepareMessage: React.FC<Props> = observer((props: Props) => {

    const { parent, search } = React.useContext(DataStoreContext);

    const generateLabel = (): string => {
        switch(props.demandType)
        {
            case ETransactionType.PUBLIC:
                return 'Demander une garde à votre réseau'
            case ETransactionType.SINGLE:
                return `Demander une garde à ${parent.selectedParent?.firstname}`
            default:
                return '';
        };
    };

    const generateTextAreaPlaceHolder = (): string => {
        switch(props.demandType)
        {
            case ETransactionType.PUBLIC:
                return 'Vous pouvez si besoin ajouter un message pour apporter des précisions sur votre demande...'
            case ETransactionType.SINGLE:
                return 'Expliquez lui les raisons de votre prise de contact.'
            default:
                return '';
        };
    };

    const sendDemand = () => {
        search.setCurrentStep(ESearchSteps.SEND_DEMAND);
    };

    return (
        <Container>
            <Body>
                <Item>
                    <Icon 
                        image={pen} 
                        width={3} 
                        height={3}
                        />
                    <Text 
                        marginLeft={'1rem'} 
                        color={Theme.colors.violet}
                        bold
                        align='left'
                    >
                        { generateLabel() }
                    </Text>
                </Item>
                <FormItem padding={1} marginBottom={1}>
                    <TextArea 
                        id="message"
                        rows={10}
                        cols={5}
                        placeholder={ generateTextAreaPlaceHolder() }
                        value={props.message}
                        onChange={e => props.setMessage(e)}
                    />
                </FormItem>
            </Body>
            <Button onClick={sendDemand} >
                    Envoyer la demande
            </Button>
        </Container>
    )
});

export default PrepareMessage;
