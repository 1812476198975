import * as React from 'react';
import styled from 'styled-components';
import Header, { PADDING } from '../shared/Navigation/Header';
import Timeline from '../Search/Timeline';
import { ESearchSteps } from '../Search/ESearchSteps';
import { DataStoreContext } from '../../store/rootStore';
import SelectDate from '../Search/SelectDate';
import SelectParent from '../Search/SelectParent';
import { observer } from 'mobx-react';
import ContactParent from '../Search/ContactParent';
import SendDemand from '../Search/SendDemand';
import PrepareMessage from '../Search/PrepareMessage';
import { ETransactionType } from '../../store/transaction';

const Container = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
padding: 0 ${PADDING} ${PADDING} ${PADDING};
`;

export const CurrentStep = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
width: 100%;
`;


const Search: React.FC = observer(() => {

    const { search } = React.useContext(DataStoreContext);

    const [demandType, setDemandType] = React.useState<ETransactionType>(ETransactionType.SINGLE);
    const [message, setMessage] = React.useState<string>('');

    const writeMessage = (e : React.ChangeEvent<HTMLTextAreaElement>) => {
        setMessage(e.target.value);
    };

    const moveBack = (): void => {
        const previousStep: ESearchSteps = search.currentStep - 1;
        search.setCurrentStep(previousStep);
    };

    const handleChangeDemandType = () => {
        switch(demandType) 
        {
            case ETransactionType.PUBLIC:
                setDemandType(ETransactionType.SINGLE);
                break;
            case ETransactionType.SINGLE:
                setDemandType(ETransactionType.PUBLIC);
                break;
            default:
                break;
        };
    };

    const displayCurrentStep = () => {
        switch(search.currentStep) 
        {
            case ESearchSteps.SELECT_DATE :
                return <SelectDate />;
            case ESearchSteps.SELECT_PARENT :
                return <SelectParent 
                            demandType={demandType}
                            setDemandType={handleChangeDemandType}
                            moveBack={moveBack}
                        />;
            case ESearchSteps.CONTACT_PARENT :
                return <ContactParent />
            case ESearchSteps.PREPARE_MESSAGE :
                return <PrepareMessage 
                            demandType={demandType}
                            message={message} 
                            setMessage={writeMessage} 
                        />;
            case ESearchSteps.SEND_DEMAND:
                return <SendDemand 
                            demandType={demandType} 
                            message={message}
                        />
            default: 
                return null;
        };
    };

    return (
        <Container>
            <Header 
                title={search.currentStep === ESearchSteps.SEND_DEMAND ? '' : "Demander un service"}
                action={search.currentStep > ESearchSteps.SELECT_DATE && search.currentStep !== ESearchSteps.SEND_DEMAND ? moveBack : null}
                noIcon={search.currentStep > ESearchSteps.SELECT_DATE}
            />
            {search.currentStep !== ESearchSteps.SEND_DEMAND &&
                <Timeline />
            }
            <CurrentStep>
                {displayCurrentStep()}
            </CurrentStep>
        </Container>

    )
});

export default Search;