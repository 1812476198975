import * as React from 'react';
import styled, { keyframes, css } from 'styled-components';
import { Text } from '../styled';
import check from './../../../assets/icons/parentCard/check-blanc.svg';
import warning from './../../../assets/icons/warning.svg';
import { Theme } from '../../../assets/Theme';
import { observer } from 'mobx-react';
import closeIcon from './../../../assets/icons/close.svg';
import { AlertStoreContext } from '../../../store/alertStore';

const HEADER_HEIGHT = '5rem';

const TOAST_WIDTH = '90%';
const TOAST_HEIGHT = '5.5rem';

const DELAY = 1000; // ms

export const SUCCESS_COLOR = Theme.colors.greenToast;
export const FAIL_COLOR = Theme.colors.warning;

const appear = keyframes`
0% {
	transform:translateX(${TOAST_WIDTH});
}
100% {
	transform: translate(-50%, 0);
}
`;

const disappear = keyframes`
0% {
	transform: translate(-50%, 0);
}
100% {
	transform:translateX(${TOAST_WIDTH});
}
`;

export enum EToastStatus {
    NONE,
    SUCCESS,
    FAIL
}

interface ContainerProps {
    color: string,
    close: boolean,
};

const Container = styled.div<ContainerProps>`
z-index: 10000;
position: fixed;
left: 50%;
top: calc(${HEADER_HEIGHT} + 2rem);
background-color: ${props => props.color};
color: white;
width: ${TOAST_WIDTH};
min-height: ${TOAST_HEIGHT};
border-radius: 2.5rem;
display: flex;
align-items: flex-start;
animation: ${props => props.close ? css`${disappear} ${DELAY}ms forwards` : css`${appear} ${DELAY}ms forwards`};
padding: 1rem;
`;


const CloseIcon = styled.div`
background: url(${closeIcon}) center no-repeat;
width: 2rem;
height: 2rem;
border-radius: 50%;
position: absolute;
right: -0.8rem;
top: -1rem;
background-color: ${Theme.colors.greyLightest};
`;

interface ToastIconProps {
    icon: string;
    color: string;
}

const ToastIcon = styled.div<ToastIconProps>`
background-image: url(${props => props.icon});
background-position: center;
background-repeat: no-repeat;
background-color: ${props => props.color};
min-width: 3rem;
height: 3rem;
`;

const Errors = styled.ul`
padding: 0;
margin: 0;
`;

const Error = styled.li`
`;

const Toast: React.FC = observer(() => {

    const { alert } = React.useContext(AlertStoreContext);

    const hasAlert: boolean = alert.status != null && alert.status !== EToastStatus.NONE;

    const [open, setOpen] = React.useState<boolean>(false);

    const cleanAlert = (): void => {
        setOpen(false); // FIRST CHANGE STATE OPEN TO FALSE
        setTimeout(() => alert.setAlert(EToastStatus.NONE, null, null), DELAY); // WAIT ANIMATION IS FINISHED BEFORE CHANGING ALERT STATUS (avoid color issue on Toast if status becomes NONE)
    };

    const toastColor: string = alert.status === EToastStatus.FAIL ? FAIL_COLOR : SUCCESS_COLOR;
    const toastIcon: string  = alert.status === EToastStatus.FAIL ? warning : check;

    const displayErrors = () => {
        if (alert.errors)
        {
            return (
                <Errors>
                    {alert.errors.map(error => 
                        <Error key={error}>
                            <Text
                                align='justify'
                                color={Theme.colors.white}
                            >
                                {error}
                            </Text>
                        </Error>
                    )}
                </Errors>
            );
        };
    };

    // MANAGE ALERT AUTO OPENING/CLOSING
    React.useEffect(() => {
        if (hasAlert)
        {
            setOpen(true);
            setTimeout(cleanAlert, 7000); // Alert is shown for 5 seconds before starting to close
        };
    }, [alert.status]);

    return (
        <>
        {alert.status != null && alert.status !== EToastStatus.NONE &&
            <Container 
                close={!open} 
                color={toastColor}
            >
                <ToastIcon
                    icon={toastIcon}
                    color={toastColor}
                
                />
                <div>
                    <Text 
                        align='justify'
                        color={Theme.colors.white}
                        padding={'0 0.7em'}
                        marginBottom={'1rem'}
                    >
                        { alert.message }
                    </Text>
                    {displayErrors()}
                </div>
                <CloseIcon
                    onClick={cleanAlert}
                />
            </Container>
        }
        </>
    )
});

export default Toast;