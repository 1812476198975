import * as React from 'react';
import styled from 'styled-components';
import { Theme } from '../../../assets/Theme';
import { IComment } from '../../../store/social';
import { AvatarContainer, P1, P3, Text } from '../../shared/styled';
import { dateToString, transformDateToString } from '../../utils/dateManager';
import defaultUser from './../../../assets/icons/avatar/DefaultUser.png';
import { Options } from '../Post/Post';
import { DataStoreContext } from '../../../store/rootStore';
import CommentMenu from './CommentMenu';
import { useNavigate } from 'react-router-dom';
import Avatar from '../../shared/Avatar';
import Pictures from '../../shared/Pictures/Pictures';

const Container = styled.div`
display: flex;
width: 100%;
margin-top: 2rem;
`;

const Message = styled.div`
width: 100%;
background-color: ${Theme.colors.white};
border-radius: 1rem;
padding: 1rem;
margin-left: 0.5rem;
`;

const Header = styled.div`
display: flex;
position: relative;
min-height: 5.5rem;
`;


interface PropsComment {
    comment: IComment;
    postId: number | undefined;
};

const Comment: React.FC<PropsComment> = (props: PropsComment) => {

    const [openCommentMenu, setOpenCommentMenu] = React.useState<boolean>(false);

    const authorAvatar = props.comment.author.avatar != null ? props.comment.author.avatar : defaultUser;

    const { userLogged, parent } = React.useContext(DataStoreContext);
    
    let navigate = useNavigate();

    const userIsAuthor: boolean = props.comment.author.id === userLogged.user?.id;

    const moveToParentProfile = async () => {
        await parent.getParentData(props.comment.author.id);
        navigate(`parent/${props.comment.author.id}`);
    };

    const getPhotos = () => {
        if (props.comment?.images != null && props.comment.images.length > 0)
        {
            return <Pictures list={props.comment.images} />
        };       
    };

    return (
        <Container>
            <Avatar 
                image={authorAvatar}
                onClick={moveToParentProfile}
                marginRight='1rem'
            />
            <Message>
                <Header>
                    {!openCommentMenu &&
                        <div>
                            <P1 marginBottom='0.2rem'>{props.comment.author.firstname} {props.comment.author.lastname}</P1>
                            <P3>{dateToString(props.comment.updatedAt)} à {transformDateToString(props.comment.updatedAt)}</P3>
                        </div>
                    }
                    <Options opened={openCommentMenu}>
                        {openCommentMenu && props.postId != undefined ? 
                            <CommentMenu 
                                currentComment={props.comment}
                                closeMenu={() => setOpenCommentMenu(false)}
                                postId={props.postId} 
                            />
                        : userIsAuthor ?
                            <Text color='black' onClick={() => setOpenCommentMenu(true)}>
                                ...
                            </Text>
                        :
                            null
                        }
                    </Options>
                </Header>
                <Text
                    color={Theme.colors.violet}
                    align='left'               
                >
                    {props.comment.text}
                </Text>
                { getPhotos() }
            </Message>
        </Container>
    )
};

export default Comment;