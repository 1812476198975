import * as React from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { Form, Button } from '../../shared/styled';
import { DataStoreContext } from '../../../store/rootStore';
import { AlertStoreContext } from '../../../store/alertStore';
import eye from './../../../assets/icons/eye-on.svg';
import { EToastStatus } from '../../shared/Information/Toast';
import { ERoutes } from '../../shared/Navigation/ERoutes';
import Header from '../../shared/Navigation/Header';
import { AppStoreContext } from '../../../store/appStore';
import { emailRegex, passwordRegex } from '../../utils/regex';
import { ValidAgreementToggleButton } from '../../shared/Buttons/ToggleButton';
import { Modal } from '../../shared/Information/Modal';
import CharterOfGoodConduct from './Agreements/CharterOfGoodConduct';
import TextInput from '../../shared/Input/TextInput';

export const ValidAgreementsContainer = styled.div`
margin-top: 2rem;
width: 100%;
`;

interface Props {
    goNextStep: () => void;
    goCGU: () => void;
    goConfidPolicy: () => void;
}

const RegisterStep1 : React.FC<Props> = observer((props: Props) => {

    const { app } = React.useContext(AppStoreContext);
    const { alert } = React.useContext(AlertStoreContext);

    const { register, register : user } = React.useContext(DataStoreContext);

    const [seePassword, setSeePassword] = React.useState(false);
    const toggleSeePassword = () => setSeePassword(!seePassword);
    
    const [showCharterOfGoodConduct, setShowCharterOfGoodConduct] = React.useState<boolean>(false);

    const [hasAcceptedCGU, setHasAcceptedCGU] = React.useState<boolean>(false);
    const handleAcceptCGU = () => setHasAcceptedCGU(!hasAcceptedCGU);

    const [hasAcceptedConfidPolicy, setHasAcceptedConfidPolicy] = React.useState<boolean>(false);
    const handleAcceptConfidPolicy = () => setHasAcceptedConfidPolicy(!hasAcceptedConfidPolicy);

    const emailIsValid: boolean = user.email != null ? emailRegex.test(user.email) : false;
    const passwordIsValid: boolean = user.password != null ? passwordRegex.test(user.password) : false;
    
    const checkFieldsValidity = () => {
        if (!emailIsValid)
        {
            alert.setAlert(EToastStatus.FAIL, "Vous devez saisir une adresse email valide", null);
            return;
        };
        if (!passwordIsValid)
        {
            alert.setAlert(EToastStatus.FAIL, "Le mot de passe doit être composé d'au moins 8 caractères dont une majuscule, une minuscule, un nombre et un caractère spécial", null);
            return;
        };
        setShowCharterOfGoodConduct(true);
    };

    const createAccount = async () => {
        app.setLoading(true);
        const accountCreated: boolean = await register.createAccount();
        if (accountCreated)
        {
            props.goNextStep();
        };
        app.setLoading(false);
    };

    return (
        <>
            <Header 
                title='Créer un compte' 
                url={ERoutes.Login} 
                noIcon
            />
            {showCharterOfGoodConduct && 
                <Modal
                    title='Charte de bonne conduite'
                    close={() => setShowCharterOfGoodConduct(false)}
                    visible={showCharterOfGoodConduct}
                    children={
                        <CharterOfGoodConduct
                            accept={createAccount} 
                            decline={() => setShowCharterOfGoodConduct(false)}
                    />}            
                />
            }
            <Form>
                <TextInput 
                    id="email"
                    type="email"
                    label='Email*'
                    placeholder=" Tapez votre adresse email"
                    value={user.email || ''}
                    onChange={e => user.setEmail(e.target.value)}  
                    marginBottom={2}  
                    validityIndicator
                    isValid={emailIsValid}        
                />
                <TextInput 
                    id="password"
                    type={seePassword ? "text" : "password"}
                    label='Mot de passe*'
                    placeholder=" Tapez votre mot de passe"
                    value={user.password || ''}
                    onChange={e => user.setPassword(e.target.value)}  
                    marginBottom={0.5}
                    iconSrc={eye}
                    onClickIcon={toggleSeePassword}
                    validityIndicator
                    isValid={passwordIsValid}
                    helpText={"Au moins 8 caractères dont une majuscule, une minuscule, un nombre et un caractère spécial"}
                />
            </Form>
            <ValidAgreementsContainer>
                <ValidAgreementToggleButton
                    label="J'ai lu et j'accepte les CGU*"
                    isSelected={hasAcceptedCGU}
                    onSelect={handleAcceptCGU}
                    marginBottom={2}
                    openAgreement={props.goCGU}          
                />
                <ValidAgreementToggleButton
                    label="J'ai lu et j'accepte la politique de confidentialité*"
                    isSelected={hasAcceptedConfidPolicy}
                    onSelect={handleAcceptConfidPolicy}
                    marginBottom={2} 
                    openAgreement={props.goConfidPolicy}                    
                />
            </ValidAgreementsContainer>
            <Button 
                disabled={!hasAcceptedCGU || !hasAcceptedConfidPolicy}
                onClick={checkFieldsValidity}
            >
                S'inscrire
            </Button>
        </>
    )
});

export default RegisterStep1;
