import * as React from 'react';
import styled from 'styled-components';
import heartFull from './../../assets/icons/social/heartFull.svg';
import heartEmpty from './../../assets/icons/social/heartEmpty.svg';
import { Icon, Text } from './styled';
import { IPost } from '../../store/social';
import { DataStoreContext } from '../../store/rootStore';
import { Theme } from '../../assets/Theme';

const Container = styled.div`
display: flex;
align-items: center;
`; 

interface PropsHeart {
    post: IPost
};

const Heart: React.FC<PropsHeart> = (props: PropsHeart) => {

    const { social, userLogged } = React.useContext(DataStoreContext);

    const userLiked: boolean = social.domain.userHasLiked(props.post, userLogged.user?.id);

    const likeUnlike = async () => {
        await social.domain.likeUnlikePost(props.post.id);
    };

    const dispayLikesNumber = (): string => {
        if (props.post.likes != null)
        {
            if (props.post.likes?.length === 1)
            {
                if (userLiked)
                {
                    return `Vous aimez ce post`
                }
                else
                {
                    return `1 personne aime`
                };
            }
            else if (props.post.likes?.length === 2)
            {
                if (userLiked)
                {
                    return `Vous et 1 autre personne aiment`
                }
                else
                {
                    return `${props.post.likes.length} personnes aiment` 
                };
            }
            else if (props.post.likes?.length > 2)
            {
                if (userLiked)
                {
                    return `Vous et ${props.post.likes.length - 1} personnes aiment`
                }
                else
                {
                    return `${props.post.likes.length} personnes aiment` 
                };
            }
            else 
            {
                return '';
            }
        }
        else 
        {
            return '';
        }
    };

    return (
        <Container>
            <Icon 
                image={userLiked ? heartFull : heartEmpty} 
                width={2.5} 
                height={2.5}  
                onClick={likeUnlike}          
            />
            <Text 
                color='black'
                marginLeft='0.5rem'
                align='left'
                size={Theme.fontSizes.small}
            >
                {dispayLikesNumber()}
            </Text>
        </Container>
    )
};

export default Heart;