import * as React from 'react';
import { observer } from 'mobx-react';
import { DataStoreContext } from '../../store/rootStore';
import { AlertStoreContext } from '../../store/alertStore';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { IMessage } from '../../store/message';
import { dateToString } from '../utils/dateManager';
import { ERoutes } from '../shared/Navigation/ERoutes';
import { Button, Container, H3, Icon } from '../shared/styled';
import defaultUser from './../../assets/icons/avatar/DefaultUser.png';
import phoneCallIcon from './../../assets/icons/social/phone-call.svg';
import trash from './../../assets/icons/parentCard/trash.svg';
import backIcon from '../../assets/icons/arrow-back.svg';
import { HEADER_HEIGHT } from '../shared/Navigation/Header';
import ShareSomething from '../Social/ShareSomething';
import { EContentType } from '../shared/CreateOrUpdateContent';
import { IUser } from '../../store/user';
import Messages from './Messages';
import wepoppinsFavicon from './../../assets/logo/favicon-couleur.svg';
import Avatar from '../shared/Avatar';
import { Modal } from '../shared/Information/Modal';
import { EToastStatus } from '../shared/Information/Toast';
import { AxiosError } from 'axios';

const ICON_WIDTH = 3.2;

const ConversationTopBar = styled.div`
background: ${props => props.theme.colors.powderWhite};
min-height: ${HEADER_HEIGHT};
display: flex;
justify-content: space-between;
width: 100%;
`;

const ConversationSide = styled.div`
display: flex;
align-items: center;
`;

interface Props {
    fromWepoppins?: boolean; // Communication provided by Wepoppins service when user has received public demands
}

const Conversation: React.FC<Props> = observer((props: Props) => {

    let navigate = useNavigate();

    const { message } = React.useContext(DataStoreContext);
    const { alert } = React.useContext(AlertStoreContext);
    const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false);

    const friend: IUser | null = message.selectedFriend;
    const friendAvatar: string = (friend != null && friend.avatar != null) ? friend.avatar : defaultUser;

    const messages: IMessage[] = message.selectedConversation?.messages != null ? message.selectedConversation.messages : [];
    const datesOfMessages: string[] = messages.map(message => dateToString(message.createdAt));
    const nonDuplicatesDatesOfMessages = Array.from(new Set(datesOfMessages));

    const backToConversations = () => navigate(ERoutes.Messages);

    const openDeleteModal = () => setShowDeleteModal(true);

    const deleteConversation = () => {
        if (message.selectedConversation != null)
        {
            message.deleteConversation(message.selectedConversation.id);
        }
        setShowDeleteModal(false);
        backToConversations();
    };

    React.useEffect(() => {
        if (!props.fromWepoppins)
        {
           
            const asyncFunction = async () => {
                try {
                    message.getMessages();
                    message.makeMessagesAsRead(friend?.id);
                }
                catch (error) 
                {
                    const err = error as AxiosError;
                    if (err.response) 
                    {
                        alert.setAlert(EToastStatus.FAIL, err.response.data.message, null);
                    };
                }
            };
            asyncFunction();
        };
    }, []);

    return (
        <Container>
            { showDeleteModal ?
                <Modal 
                    title='Supprimer la conversation'
                    visible={showDeleteModal}
                    close={() => setShowDeleteModal(false)}
                    children={
                        <Button onClick={deleteConversation}>
                            Supprimer
                        </Button>
                    }
                />
            :
                <>
                    <ConversationTopBar>
                        <ConversationSide>
                            <Icon
                                image={backIcon} 
                                width={0.8} 
                                height={1.2}
                                onClick={backToConversations}
                                borderRadius='50%'
                                backgroundCover
                                marginRight='2rem'
                            />
                            <Avatar
                                image={props.fromWepoppins ? wepoppinsFavicon : friendAvatar} 
                                onClick={() => null}
                                width={ICON_WIDTH} 
                                height={ICON_WIDTH}
                                isWepoppins={props.fromWepoppins}
                                marginRight='1rem'
                            />
                            <H3>Messagerie</H3>
                        </ConversationSide>
                        { !props.fromWepoppins &&
                            <ConversationSide>
                                {/* <Icon
                                    image={phoneCallIcon} 
                                    width={ICON_WIDTH} 
                                    height={ICON_WIDTH}
                                    borderRadius='50%'
                                    backgroundAuto
                                /> */}
                                <Icon
                                    image={trash} 
                                    width={ICON_WIDTH} 
                                    height={ICON_WIDTH}
                                    borderRadius='50%'
                                    marginLeft='1rem'
                                    onClick={openDeleteModal}
                                    backgroundAuto
                                />
                            </ConversationSide>
                        }
                    </ConversationTopBar>
                    <Messages 
                        dates={nonDuplicatesDatesOfMessages} 
                        messages={messages}
                        fromWepoppins={props.fromWepoppins}
                    />
                    <ShareSomething 
                        title='Votre message...'
                        contentType={EContentType.CREATE_MESSAGE} 
                    />
                </>
            }
        </Container>
    )
});

export default Conversation;
