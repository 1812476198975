import * as React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { ProfileContainer, SelectPhoto, Icon, Form, FormItem, Label,  AVATAR_HEIGHT, AVATAR_WIDTH, DatePickerWrapperStyles } from '../shared/styled';
import { AvatarContainerProfile } from '../Parent/ParentProfile';
import { DataStoreContext } from '../../store/rootStore';
import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import fr from 'date-fns/locale/fr';
import SelectSex, { ESex } from './SelectSex';
import RadioButton from '../shared/Buttons/RadioButton';
import { Modal } from '../shared/Information/Modal';
import SelectAvatar from './SelectAvatar';
import MiniButton from '../shared/Buttons/MiniButton';
import TextInput, { LabelContainer } from '../shared/Input/TextInput';
import ValidityIndicator from '../shared/Input/ValidityIndicator';
import TextAreaComponent from '../shared/Input/TextAreaComponent';

registerLocale("fr", fr);

const ProfileInfo = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
background-color: ${props => props.theme.colors.white};
border-radius: 1rem;
padding-bottom: 2rem;
width: 100%;
`;

const SectionContainer = styled.div`
margin-top: 0.5rem;
`;

export const ButtonsContainer = styled.div`
display: flex;
justify-content: space-around;
margin-top: 2rem;
width: 100%;
`;

type InputEvent = React.ChangeEvent<HTMLInputElement>;

export enum ESection {
    MATERNELLE = 'Maternelle',
    PRIMAIRE = 'Primaire',
    COLLEGE = 'College'
};

interface Props {
    isEditing: boolean;
    cancel: () => void;
    save: () => void;
}

const CreateOrEditChild : React.FC<Props> = observer((props: Props) => {

    const { childProfile } = React.useContext(DataStoreContext);

    const [ showSelectAvatar, setShowSelectAvatar ] = React.useState(false);

    const currentAvatar = childProfile.getChildAvatar(childProfile);

    const someEmptyFields: boolean = childProfile.sex.length < 1 || childProfile.firstname.length < 1 || childProfile.birthdate == null || childProfile.section.length < 1;

    const selectSection = (e: React.ChangeEvent<HTMLInputElement>) => {
        childProfile.setSection(e.target.value);
    };

    // Allow to force select avatar modal in order to be sure than an avatar will be selected for the current child 
    const forceOpenAvatarModal = () => {
        if (childProfile.avatar.length < 1)
        {
            setShowSelectAvatar(true);
        };
    };

    React.useEffect(() => {
        if (!props.isEditing)
        {
            childProfile.cleanProfile(); // Allow to reset child information if a previous child has been recorded
        };
    }, []);

    return (
        <ProfileContainer>
            { showSelectAvatar &&
                <Modal 
                    title='Choisissez un avatar'
                    close={() => setShowSelectAvatar(false)}
                    visible={showSelectAvatar}
                    children={
                        <SelectAvatar 
                            action={() => setShowSelectAvatar(false)} 
                        />
                    }
                />
            }
            <AvatarContainerProfile>
                <Icon 
                    image={currentAvatar} 
                    width={AVATAR_WIDTH} 
                    height={AVATAR_HEIGHT}
                    borderRadius={'50%'}
                    avatarProfile={true}
                />
                <SelectPhoto onClick={() => setShowSelectAvatar(true)}/>
            </AvatarContainerProfile>
            <ProfileInfo>
                <Form>
                    <FormItem padding={1} marginTop={AVATAR_HEIGHT/2}>
                        <LabelContainer>
                            <Label marginTop='1rem'>
                                Sex*
                            </Label>
                            <ValidityIndicator isValid={childProfile.sex.length > 0} />
                        </LabelContainer>
                        <SelectSex forceSelectAvatar={forceOpenAvatarModal} />
                    </FormItem>
                    <TextInput
                        id='firstname'
                        type='text'
                        label='Prénom*'
                        placeholder="Tapez le prénom de votre enfant"
                        value={childProfile.firstname}
                        onChange={(e: InputEvent) => childProfile.setFirstname(e.target.value)}
                        validityIndicator
                        isValid={childProfile.firstname.length > 0}
                        padding={1}
                    />
                    <FormItem padding={1}>
                        <LabelContainer>
                            <Label marginTop='1rem'>
                                Date de naissance*
                            </Label>
                            <ValidityIndicator isValid={childProfile.birthdate != null} />
                        </LabelContainer>
                        <DatePicker
                            selected={childProfile.birthdate}
                            onChange={(date: Date) => childProfile.setBirthdate(date)}
                            wrapperClassName='date_picker full-width'
                            locale={"fr"}
                            dateFormat="P"
                            openToDate={new Date(2015, 0, 1)}
                            showYearDropdown
                            dropdownMode="select"
                            dateFormatCalendar="MMMM"
                        />
                        <DatePickerWrapperStyles />
                    </FormItem>
                    <FormItem padding={1}>
                        <LabelContainer>
                        <Label marginTop='1rem'>
                            {childProfile.sex == ESex.GIRL ?
                                 "En quelle section est-elle ?*" : "En quelle section est-il ?*"
                            }
                        </Label>
                        <ValidityIndicator isValid={childProfile.section.length > 0}/>
                        </LabelContainer>
                        <SectionContainer>
                            <RadioButton 
                                value={ESection.MATERNELLE}
                                checked={childProfile.section === ESection.MATERNELLE}
                                onChange={selectSection}
                                name="section"
                            />
                            <RadioButton 
                                value={ESection.PRIMAIRE}
                                checked={childProfile.section === ESection.PRIMAIRE}
                                onChange={selectSection}
                                name="section"
                            />
                            <RadioButton 
                                value={ESection.COLLEGE}
                                checked={childProfile.section === ESection.COLLEGE}
                                onChange={selectSection}
                                name="section"
                            />
                        </SectionContainer>
                    </FormItem>
                    <TextAreaComponent 
                        id="biography"
                        label='Quelques mots sur votre enfant'
                        placeholder="Quelques mots sur votre enfant"
                        value={childProfile.biography}
                        onChange={e => childProfile.setBiography(e.target.value)}
                        helpText="Par exemple, est-ce qu'il pratique des activités ? A-t-il des allergies ? ..."
                        marginTop={1}
                        marginBottom={0.3}
                        padding={1}
                    />
                </Form>
            </ProfileInfo>
            <ButtonsContainer>
                <MiniButton
                    label='Annuler'
                    action={props.cancel}
                    whiteBackground={true}                            
                />
                <MiniButton 
                    label='Enregistrer'
                    action={props.save}
                    whiteBackground={false}
                    disabled={someEmptyFields}
                />
            </ButtonsContainer>
        </ProfileContainer>
    )
});

export default CreateOrEditChild;
