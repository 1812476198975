import * as React from 'react';
import styled from 'styled-components';
import { Route, BrowserRouter, Routes, Outlet, Navigate } from "react-router-dom";
import { observer, Provider } from "mobx-react";
import Landing from "../Connexion/Landing";
import Login from '../Connexion/Login';
import { DataStoreContext } from '../../store/rootStore';
import { AlertStoreContext } from '../../store/alertStore';
import Home from './Home';
import ForgotPassword from '../Connexion/ForgotPassword';
import MyProfile from './MyProfile';
import MyChildren from './MyChildren';
import Search from './Search';
import MyCalendar from './Calendar';
import Parent from './Parent';
import Messagerie from './Messagerie';
import Conversation from '../Messagerie/Conversation';
import { ERoutes } from '../shared/Navigation/ERoutes';
import ConfirmationCode from '../Connexion/ConfirmationCode';
import Toast from '../shared/Information/Toast';
import Loader from '../shared/Loader';
import NewPassword from '../Connexion/NewPassword';
import MyNetwork from './MyNetwork';
import UserWall from './UserWall';
import CreateOrUpdateContent from '../shared/CreateOrUpdateContent';
import { FOOTER_HEIGHT } from '../shared/styled';
import CommentList from '../Social/Comment/CommentList';
import { EContentType } from '../shared/CreateOrUpdateContent';
import Contacts from '../Messagerie/Contacts';
import SelectCandidate from '../Transactions/SelectCandidate';
import Menu from '../shared/Navigation/Menu';
import TransactionDetail from '../Transactions/TransactionDetail';
import Register from '../Connexion/Register';
import ContactForm from '../shared/ContactForm';
import { isBrowser } from 'react-device-detect';
import DesktopWaitingRoom from '../Connexion/DesktopWaitingRoom';
import About from './About';
import Sponsorize from './Sponsorize';
import ChildProfile from '../Child/ChildProfile';
import { AppStoreContext } from '../../store/appStore';
import RecheckIdentity from '../Connexion/Register/RecheckIdentity';
import ConfirmTransacIsDone from './ConfirmTransacIsDone';
import DownloadAppOnStore from '../utils/DownloadAppOnStore';

export const MAIN_COMPONENT_HEIGHT = '90vh';

interface ContainerProps {
    logged: boolean;
}

const Container = styled.div<ContainerProps>`
background-color: ${props => props.theme.colors.powderWhite};
height: 100vh;
${({ logged }) => logged && `
    display: grid;
    grid-template-rows: ${MAIN_COMPONENT_HEIGHT} ${FOOTER_HEIGHT};
    grid-template-columns: auto;
    grid-template-areas: 
            "main-element"
            "footer";
`}
`;

const MainComponent = styled.div`
display: block;
overflow-y: auto;
background-color: ${props => props.theme.colors.powderWhite};
`;

export const PrivateRoutes: React.FC = observer(() => {

    // const { userLogged : { isAuth } } = React.useContext(DataStoreContext);

    // ALLOW TO LOCK PRIVATE ROUTES FROM WEB APP :
    const isAuth = false;

    return (
        isAuth ? <Outlet />
        : <Navigate to={ERoutes.Login}/>
    );
});


const Router : React.FC = observer(() => {

    const { userLogged : { isAuth } } = React.useContext(DataStoreContext);
    const { parent : { selectedParent, selectedChild } } = React.useContext(DataStoreContext);
    const { transactions : { selectedTransaction } } = React.useContext(DataStoreContext);
    const { app } = React.useContext(AppStoreContext);

    const location = window.location;

    // Only if confirmation code is in progress, user can do it from mobile or desktop
    const isConfirmingCode: boolean = location.pathname.includes('/confirmation/');
  

    // GET VIEWPORT DIMENSIONS FOR RESPONSIVE 
    const getViewPortSize = () => {
        const { innerWidth: width, innerHeight: height } = window;
        app.setViewPortSize(width, height);
    };
    
    React.useEffect(() => {
        getViewPortSize();
    }, []);

    return (
        <BrowserRouter>
            <Provider value={AlertStoreContext}>
                <Container logged={isAuth}>
                    {app.loading &&
                        <Loader />
                    }
                    {app.menuIsOpen && isAuth &&
                        <Menu />
                    }
                    <MainComponent>
                        <Provider value={DataStoreContext}>
                            <Toast />
                            { isBrowser && isConfirmingCode ?
                                <Routes>
                                    <Route path={ERoutes.Confirmation} element={<ConfirmationCode />} />
                                </Routes>
                            :
                                isBrowser ?
                                    <DesktopWaitingRoom />
                            :
                                <Routes>

                                    {/* PUBLIC ROUTES */}
                                    {/* <Route path={ERoutes.Landing} element={<Landing />} /> */}
                                    <Route path={ERoutes.Login} element={<Login />} />
                                    <Route path={ERoutes.ForgotPassword} element={<ForgotPassword />} />
                                    <Route path={ERoutes.NewPassword} element={<NewPassword />} />
                                    {/* <Route path={ERoutes.Register} element={<Register />} /> */}
                                    {/* <Route path={ERoutes.Confirmation} element={<ConfirmationCode />} />
                                    <Route path={ERoutes.Contact} element={<ContactForm />} /> */}
                                    <Route path={ERoutes.RecheckIdentity} element={<RecheckIdentity />} />
                                    <Route path={ERoutes.ConfirmTransactionDone} element={<ConfirmTransacIsDone />} />
                                    <Route path={ERoutes.DownloadApp} element={<DownloadAppOnStore />} />
                                    {/* PRIVATE ROUTES */}
                                    <Route element={<PrivateRoutes />}>  
                                        <Route path={`${ERoutes.About}`} element={<About />} /> 
                                        <Route path={`${ERoutes.Home}/*`} element={<Home />} />
                                        <Route path={`${ERoutes.Home}/candidate`} element={<SelectCandidate />} />
                                        <Route path={ERoutes.TransactionDetail} element={<TransactionDetail user={selectedParent} transac={selectedTransaction}/>} />
                                        <Route path={ERoutes.Calendar} element={<MyCalendar />} />
                                        <Route path={`${ERoutes.Network}/*`} element={<MyNetwork />} />
                                        <Route path={`${ERoutes.Network}/parent/:id`} element={<Parent user={selectedParent} previousRoute={ERoutes.Network}/>} />
                                        <Route path={ERoutes.Search} element={<Search />} />
                                        <Route path={ERoutes.MyProfile} element={<MyProfile />} />
                                        <Route path={`${ERoutes.Sponsorize}`} element={<Sponsorize />} />
                                        <Route path={ERoutes.MyChildren} element={<MyChildren />} />
                                        <Route path={ERoutes.Messages} element={<Messagerie />} />
                                        <Route path={ERoutes.Friends} element={<Contacts />} />
                                        <Route path={`${ERoutes.Conversation}/:id`} element={<Conversation />} />
                                        <Route path={`${ERoutes.Conversation}/wepoppins`} element={<Conversation fromWepoppins/>} />
                                        <Route path={ERoutes.Social} element={<UserWall />} />
                                        <Route path={`${ERoutes.Social}/comments`} element={<CommentList />} />
                                        <Route path={`${ERoutes.Social}/parent/:id`} element={<Parent user={selectedParent} previousRoute={ERoutes.Social}/>} />
                                        <Route path={ERoutes.ChildDetail} element={<ChildProfile child={selectedChild}/>} />
                                        <Route 
                                            path={ERoutes.CreatePost} 
                                            element={<CreateOrUpdateContent 
                                                        contentType={EContentType.CREATE_POST}
                                                        title='Créer une publication' />
                                                    } 
                                        />
                                        <Route 
                                            path={ERoutes.UpdatePost} 
                                            element={<CreateOrUpdateContent
                                                        contentType={EContentType.UPDATE_POST} 
                                                        title='Modifier votre publication'/>
                                                    } 
                                        />
                                        <Route 
                                            path={ERoutes.CreateMessage} 
                                            element={<CreateOrUpdateContent
                                                        contentType={EContentType.CREATE_MESSAGE}  
                                                        title='Écrire un message' />
                                                    } 
                                        />
                                        <Route 
                                            path={ERoutes.CreateComment} 
                                            element={<CreateOrUpdateContent
                                                        contentType={EContentType.CREATE_COMMENT}  
                                                        title='Écrire un commentaire' 
                                                        />
                                                    } 
                                        />
                                    </Route>
                                    <Route path="*" element={<Navigate to={ERoutes.Login} />}  />
                                </Routes>
                            }
                        </Provider>
                    </MainComponent>
                </Container>
            </Provider>
        </BrowserRouter>

    )
});

export default Router;