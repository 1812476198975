import { types, cast, flow } from "mobx-state-tree";
import { api } from "../api/api";
import { EToastStatus } from "../components/shared/Information/Toast";
import { StoreAlert } from './alertStore';

const { REACT_APP_API_URL } = process.env;

export interface ILike {
    id: number,
    postId: number,
    userId: number
}
export interface IAuthor {
	id: number,
	firstname: string,
	lastname: string,
	avatar: string,
}

export interface IImage {
    id: number,
    src: string
}
export interface IComment {
	id: number,
	text: string,
	createdAt: Date,
	updatedAt: Date,
	author: IAuthor,
    images: IImage[] | null,
}

export interface IPost {
	id: number,
	text: string,
	createdAt: Date,
	updatedAt: Date,
	author: IAuthor,
	comments: IComment[] | null,
    likes: ILike[] | null
    images: IImage[] | null,
}

const DomainSocialStore = types
    .model({
        posts: types.maybeNull(types.array(types.frozen<IPost>())),
        fetchPostHasMore: types.boolean,
        selectedPost: types.maybeNull(types.frozen<IPost>()),
        selectedComment: types.maybeNull(types.frozen<IComment>()),    
    })
    .actions(self => ({
        setPosts(posts : IPost[] | null) {
            self.posts = cast(posts);
        },
        setSelectedPost(post : IPost | null) 
        {
            self.selectedPost = post;
        },
        setSelectedComment(comment : IComment | null) 
        {
            self.selectedComment = comment;
        },    
        sendComment : flow(function* (postId: number | null, text: string) {
            let updatedPost: IPost | null = null;

            if (postId != null)
            {
                yield api
                    .post(`${REACT_APP_API_URL}/api/social/post/${postId}/comment`, { text })
                    .then(resp => updatedPost = resp.data[0])
                    .catch(err => console.log(err));
            };
            if (updatedPost != null)
            {
                self.selectedPost = cast(updatedPost);
            };
        }),

        updateComment : flow(function* (postId: number | null, commentId: number | null, text: string) {
            let updatedPost: IPost | null = null;

            if (commentId != null && postId != null)
            {
                yield api
                    .put(`${REACT_APP_API_URL}/api/social/post/${postId}/comment/${commentId}`, { text })
                    .then(resp => updatedPost = resp.data[0])
                    .catch(err => console.log(err));
            };
            if (updatedPost != null)
            {
                self.selectedPost = cast(updatedPost);
            };
        }),

        deleteComment : flow(function* (postId: number, commentId: number | null) {
            let updatedPost: IPost | null = null;

            if (postId != null && commentId != null)
            {
                yield api
                    .delete(`${REACT_APP_API_URL}/api/social/post/${postId}/comment/${commentId}`)
                    .then(resp => updatedPost = resp.data[0])
                    .catch(err => console.log(err));
            };
            if (updatedPost != null)
            {
                self.selectedPost = cast(updatedPost);
            };
        }),

        getPosts : flow(function* (offset: number) {
            const listToUpdate = self.posts != null ? [...self.posts] : [];
            let agreggateList: IPost[] = [];
            let hasMore = false;
    
            yield api
                .get(`${REACT_APP_API_URL}/api/social/post?offset=${offset}`)
                .then(resp => {
                    const updatedList: IPost[] = resp.data;
                    agreggateList  = [...listToUpdate, ...updatedList];
                    hasMore = updatedList.length > 0;                
                })
                .catch(err => console.log(err));
            self.posts = cast(agreggateList);
            self.fetchPostHasMore = hasMore;
        }),

        createPost : flow(function* (post: FormData | null) {
            const updatedPostList = self.posts != null ? [...self.posts] : [];

            if (post != null)
            {
                yield api
                    .post(`${REACT_APP_API_URL}/api/social/post`, post)
                    .then(resp => {
                        const newPost: IPost = resp.data[0];
                        updatedPostList.unshift(newPost);
                    })
                    .catch(err => StoreAlert.alert.setAlert(EToastStatus.FAIL, `${err.response.data.message}`, null));
            };
        }),

        updatePost : flow(function* (postId: number | null, data: FormData) {
            const updatedPostList = self.posts != null ? [...self.posts] : [];

            if (postId != null)
            {
                yield api
                    .put(`${REACT_APP_API_URL}/api/social/post/${postId}`, data)
                    .then(resp => {
                        const updatedPost: IPost = resp.data[0];
                        const indexOfUpdatedPost =  updatedPostList.findIndex(post => post.id === postId);
                        updatedPostList[indexOfUpdatedPost] = updatedPost;
                    })
                    .catch(err => StoreAlert.alert.setAlert(EToastStatus.FAIL, `${err.response.data.message}`, null));
            };
            self.posts = cast(updatedPostList);
        }),

        deletePost : flow(function* (postId: number | null) {
            const updatedPostList = self.posts != null ? [...self.posts] : [];
            const indexOfPostToDelete =  updatedPostList.findIndex(post => post.id === postId);

            if (postId != null)
            {
                yield api
                    .delete(`${REACT_APP_API_URL}/api/social/post/${postId}`)
                    .then(resp => {
                        if (indexOfPostToDelete > -1) 
                        { 
                            updatedPostList.splice(indexOfPostToDelete, 1);
                        }
                    })
                    .catch(err => console.log(err));
            };
            self.posts = cast(updatedPostList);
        }),

        likeUnlikePost : flow(function* (postId: number | null) {
            const updatedPostList = self.posts != null ? [...self.posts] : [];
            if (postId != null)
            {
                yield api
                    .post(`${REACT_APP_API_URL}/api/social/post/like/${postId}`)
                    .then(resp => {
                        const updatedPost: IPost = resp.data[0];
                        const indexOfUpdatedPost = updatedPostList.findIndex(post => post.id === updatedPost.id);
                        updatedPostList[indexOfUpdatedPost] = updatedPost;
                    })
                    .catch(err => console.log(err));
            };
            self.posts = cast(updatedPostList);            
        })
    }))
    .views(self => ({
        getLikesNumber(post: IPost)
        {
            return post.likes?.length;
        },
        userHasLiked(post: IPost, userId: number | null | undefined)
        {
            let hasLiked = false;
            if (userId != null && post.likes != null)
            {
                hasLiked = post.likes.some(like => like.userId === userId);
            }
            return hasLiked;
        }
    }))
;

const ViewSocialStore = types
.model({})
;

export const SocialStore = types.model({
    domain: DomainSocialStore,
    ui: ViewSocialStore
});
