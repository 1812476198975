import * as React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { DataStoreContext } from '../../store/rootStore';
import { Container, Button, Text } from '../shared/styled';
import { PADDING } from '../shared/Navigation/Header';
import Header from '../shared/Navigation/Header';
import SelectOptions from '../shared/Select/SelectOptions';
import ChildCard from '../Child/ChildCard';
import CreateOrEditChild from '../Child/CreateOrEditChild';
import { IChild } from '../../store/child';
import { ERoutes } from '../shared/Navigation/ERoutes';
import { AppStoreContext } from '../../store/appStore';

const Children = styled.div`
display: flex;
flex-direction: column;
align-items: center;
width: calc(100vw - 2*${PADDING});
margin-top: 3rem;
`;

const MyChildren : React.FC = observer(() => {
    
    const { userLogged, childProfile } = React.useContext(DataStoreContext);
    const { userLogged : { user } } = React.useContext(DataStoreContext);
    const { app } = React.useContext(AppStoreContext);

    const children = user?.children != null ? user.children : [];

    const hasNoChildren: boolean = children.length === 0;

    const [isAdding, setIsAdding] = React.useState<boolean>(false);
    const [isEditing, setIsEditing] = React.useState<boolean>(false);

    const toggleAdding = () => setIsAdding(!isAdding);

    const addChild = async () => {
        setIsAdding(true);
        toggleAdding();
        app.setLoading(true);
        let created: boolean = await childProfile.addChild(childProfile);
        if (created)
        {
            setIsAdding(false);
            userLogged.getUserData();
        };
        app.setLoading(false);
    };

    const editChild = (childToEdit: IChild) => {
        setIsEditing(true);
        childProfile.copyChildInfo(childToEdit);
    };

    const saveUpdateChild = async () => {
        app.setLoading(true);
        let updated: boolean = await childProfile.updateProfile(childProfile);
        if (updated)
        {
            userLogged.getUserData();
        };
        setIsEditing(false);
        app.setLoading(false);
    };

    React.useEffect(() => {
        userLogged.getUserData();
    }, []);

    return (
        <>
            {!isAdding && !isEditing ?
                <Container>
                    <Header 
                        title={'Mes enfants'}
                    />
                    <SelectOptions 
                        option1={'Moi'}
                        option2={'Mes enfants'}
                        url1={ERoutes.MyProfile}
                        url2={ERoutes.MyChildren}
                    />
                    <Children>
                        { hasNoChildren ?
                            <Text>Vous n'avez pas encore renseigné votre tribu</Text>
                        :
                            children.map((child : IChild, index) => 
                                <ChildCard 
                                    key={index}
                                    child={child}
                                    editChild={() => editChild(child)}
                                />)
                            }
                    </Children>
                    <Button onClick={toggleAdding}>
                        Ajouter un enfant
                    </Button>
                </Container>
            : isEditing ?
                <Container>
                    <Header title={'Modifier le profil'} />
                    <CreateOrEditChild 
                        isEditing={isEditing} 
                        cancel={() => setIsEditing(false)} 
                        save={saveUpdateChild}
                    />
                </Container>
            :
                <Container>
                    <Header 
                        title={'Ajouter un enfant'}
                    />
                    <CreateOrEditChild 
                        isEditing={isEditing} 
                        cancel={() => setIsAdding(false)} 
                        save={addChild}
                    />
                </Container>
            }
        </>
    )
});

export default MyChildren;
