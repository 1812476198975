import { observer } from 'mobx-react';
import * as React from 'react';
import { IUser } from '../../store/user';
import RelationCard from './RelationCard';
import { DataStoreContext } from '../../store/rootStore';
import styled from 'styled-components';
import { Button, CircleShape, EmptyData, H4, Illustration } from '../shared/styled';
import paint from './../../assets/illustrations/paint.svg';
import { useNavigate } from 'react-router-dom';
import { ERoutes } from '../shared/Navigation/ERoutes';
import { Parents } from './ParentsList';
import { AppStoreContext } from '../../store/appStore';

const Container = styled.div`
width: 100%;
`;
interface FriendsListProps {
    friends: IUser[] | null,
    fromGard?: boolean; // prop only used in search gard when selecting a parent
}

const FriendsList: React.FC<FriendsListProps> = observer((props: FriendsListProps) => {

    let navigate = useNavigate();

    const [offset, setOffset] = React.useState<number>(0);
    const { network } = React.useContext(DataStoreContext);
    const { app } = React.useContext(AppStoreContext)

    const observer = React.useRef<IntersectionObserver | null>(null);

    const lastFriendRef = React.useCallback((node: any) => {
        if (app.loading) 
        {
            return;
        };
        if (observer.current != null) 
        {
            observer.current.disconnect();
        };
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && network.fetchHasMoreFriends) 
            {
                setOffset(offset + 10);
            };
        });
        if (node) 
        {
            observer.current.observe(node);
        }   
    }, [app.loading, network.fetchHasMoreFriends]);

    const getFriends = async () => {
        app.setLoading(true);
        await network.getFriends(offset);
        app.setLoading(false);
    };

    const moveToNetwork = () => navigate(ERoutes.Network);

    React.useEffect(() => {
        network.setFriends([]);
        setOffset(0);
    }, []);

    React.useEffect(() => {
        getFriends();
    }, [offset]);

    return (
        <Container>
            {(props.friends == null || props.friends.length == 0) ?
                <EmptyData>
                    <CircleShape>
                        <Illustration src={paint} />
                    </CircleShape>
                    <H4>Vous n'avez pas encore ajouté de parents dans votre réseau.</H4>
                    <Button onClick={moveToNetwork}>
                        Créer mon réseau
                    </Button>
                </EmptyData>
            :
                <Parents>
                    {props.friends?.map((parent: IUser, index) => {
                        if (props.friends?.length === index + 1)
                        {
                            return (
                                <RelationCard 
                                        ref={lastFriendRef}
                                        key={parent.id}
                                        parent={parent}
                                        isFriend={true}
                                        addParent={() => null}
                                        fromGard={props.fromGard}
                                />
                            )
                        } 
                        else
                        {
                            return (
                                <RelationCard 
                                    key={parent.id}
                                    parent={parent}
                                    isFriend={true}
                                    addParent={() => null}
                                    fromGard={props.fromGard}
                                />                                    
                            )
                        }
                    })}
                </Parents>
            }
        </Container>
    )
});

export default FriendsList;