import * as React from 'react';
import styled from 'styled-components';
import { Button, TextArea } from '../shared/styled';
import { DataStoreContext } from '../../store/rootStore';
import { ETransactionType, ITransaction } from '../../store/transaction';

const Container = styled.div`
display: flex;
flex-direction: column;
align-items: center;
`;
interface Props {
    manageStatus: EManageStatus,
    contactedParentId: number | undefined,
    closeModal: () => void,
    sendTo: string | undefined,
    action: () => void | null,
    transac: ITransaction
}

export enum EManageStatus {
    NONE,
    ACCEPT,
    REFUSE,
    CANCEL_MY_DEMAND,
    CANCEL
}

const ManageTransaction: React.FC<Props> = (props: Props) => {

    const { transactions } = React.useContext(DataStoreContext);

    const [message, setMessage] = React.useState<string>('');

    const acceptOrRefuse = async () => {
        if (props.transac != null)
        {
            switch(props.manageStatus) 
            {
                // CANCEL_MY_DEMAND IS ONLY ALLOWED IF THE USER IS THE CREATOR OF THE DEMAND
                case EManageStatus.CANCEL_MY_DEMAND:
                    // CASE 1 : SINGLE DEMAND
                    if (props.contactedParentId != null && props.transac != null && props.transac.type === ETransactionType.SINGLE)
                    {
                        await transactions.cancelMyDemand(props.transac.id, props.contactedParentId, message);
                    }
                    // CASE 2 : PUBLIC DEMAND
                    if (props.transac != null && props.transac.type === ETransactionType.PUBLIC)
                    {
                        await transactions.cancelMyPublicDemand(props.transac.id);
                    }
                    break;
                case EManageStatus.CANCEL:
                    if (props.contactedParentId != null)
                    {
                        await transactions.cancelParentDemand(props.transac.id, props.contactedParentId, message);
                    }
                    break;
                case EManageStatus.REFUSE:
                    if (props.contactedParentId != null)
                    {
                        await transactions.declineTransaction(props.transac.id, props.transac.sender?.id, message);
                    }
                    break;
                case EManageStatus.ACCEPT:
                    await transactions.acceptTransaction(props.transac.id, props.transac.sender?.id, message);
                    break;
                default:
                    break;
            };
        };
        props.action(); // unselect event to close the first modal;
        props.closeModal(); // close current modal (submodal)
    };

    const writeMessage = (e : React.ChangeEvent<HTMLTextAreaElement>) => setMessage(e.target.value);
    const buttonTitle: string = props.manageStatus === EManageStatus.REFUSE ? 'Décliner' 
                                : (props.manageStatus === EManageStatus.CANCEL || props.manageStatus === EManageStatus.CANCEL_MY_DEMAND) ? 'Annuler' 
                                : 'Accepter';

    return (
        <Container>
            {props.transac.type === ETransactionType.SINGLE &&
                <TextArea 
                    rows={5}
                    cols={5}
                    placeholder={`Vous pouvez ici ajouter un petit mot pour ${props.sendTo}.`}
                    value={message}
                    onChange={e => writeMessage(e)}
                    width='90%'
                />
            }
            <Button 
                marginTop={1}
                marginBottom={1}
                onClick={acceptOrRefuse}
            >
                {buttonTitle}
            </Button>
        </Container>
    )
}

export default ManageTransaction;
