import * as React from 'react';
import { types, Instance } from 'mobx-state-tree';
import moment from "moment";
import 'moment/locale/fr'
import { Child } from './child';
import { Search } from './search';
import { ESearchSteps } from '../components/Search/ESearchSteps';
import { UserRegister } from './profile';
import { UserStore } from './user';
import { TransactionStore } from './transaction';
import { ParentStore } from './parent';
import { MessageStore } from './message';
import { NetworkStore } from './network';
import { SocialStore } from './social';

export const DataStore = types
    .model({
        register: UserRegister,
        childProfile: Child,
        search: Search,
        userLogged: UserStore,
        transactions: TransactionStore,
        parent: ParentStore,
        message: MessageStore,
        network: NetworkStore,
        social: SocialStore
    });

export type DataStoreType = Instance<typeof DataStore>;

export const Store: DataStoreType = DataStore.create({
    register: {
        email: '',
        password: '',
        firstname: '',
        lastname: '',
        address1: '',
        address2: '',
        phone: '',
        city: null,
        biography: '',
        birthdate: null,
        avatar: '',
        availabilities: [],
        activationCode: null
    },
    childProfile: {
        firstname: '',
        birthdate: null,
        section : '',
        biography : '',
        sex : '',
        avatar : '',
    },
    search : {
        currentStep: ESearchSteps.SELECT_DATE,
        date: moment().toDate(),
        start: null,
        end: null,
        results: null,
    },
    userLogged : {
        user: null,
        isAuth: false,
    },
    transactions: {
        list: null,
    },
    parent: {
        selectedParent: null,
    },
    message: {
        conversations: null,
        selectedConversation: null,
    },
    network: {
        city: null,
        parents: null,
        friends: null,
        pendingRelationships: null,
        fetchHasMoreParents: false,
        fetchHasMoreFriends: false,
        hasUnderstoodWarningBeforeInviting: false,
        hasUnderstoodWarningBeforeAccepting: false
    },
    social: {
        domain: {
            posts: null,
            fetchPostHasMore: false,
            selectedPost: null,
            selectedComment: null
        },
        ui: {}
    }
});

export const DataStoreContext = React.createContext(Store);
