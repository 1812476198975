import React, { useState, useCallback } from 'react'
import Post from '../Social/Post/Post';
import { observer } from 'mobx-react';
import { DataStoreContext } from '../../store/rootStore';
import Header from '../shared/Navigation/Header';
import { CircleShape, Container, EmptyData, H4, Illustration } from '../shared/styled';
import ShareSomething from '../Social/ShareSomething';
import { EContentType } from '../shared/CreateOrUpdateContent';
import studying from './../../assets/illustrations/studying.svg';
import { AppStoreContext } from '../../store/appStore';

const UserWall: React.FC = observer(() => {

    const [offset, setOffset] = useState(0);
    const { social } = React.useContext(DataStoreContext);
    const { app } = React.useContext(AppStoreContext);

    const posts = social.domain.posts != null ? social.domain.posts : [];

    const observer = React.useRef<IntersectionObserver | null>(null);
    
    const lastPostRef = useCallback((node: any) => {
        if (app.loading) 
        {
            return;
        };
        if (observer.current != null) 
        {
            observer.current.disconnect();
        };
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && social.domain.fetchPostHasMore) 
            {
                setOffset(offset + 10);
            }
        })
        if (node) 
        {
            observer.current.observe(node);
        }   
    }, [app.loading, social.domain.fetchPostHasMore]);

    const getPosts = async () => {
        app.setLoading(true);
        await social.domain.getPosts(offset);
        app.setLoading(false);
    };

    React.useEffect(() => {
        social.domain.setPosts([]);
        setOffset(0);
    }, []);

    React.useEffect(() => {
        getPosts();
    }, [offset]);

    return (
        <Container>
            <Header title={'Activités'} noMargin />
            <ShareSomething 
                title='Un mot à partager ?' 
                contentType={EContentType.CREATE_POST}
            />
            {posts.length === 0 ? 
                <EmptyData>
                    <CircleShape>
                        <Illustration src={studying} />
                    </CircleShape>
                    <H4>Il n'y a pas encore d'activités sur votre mur, soyer le premier parent de votre réseau à communiquer.</H4>
                </EmptyData>

            : posts.map((post, index) => {
                if (posts.length === index + 1) 
                {
                    return <Post
                                key={post.id}
                                post={post}
                                ref={lastPostRef}
                            />
                }
                else
                {
                    return <Post
                                key={post.id}
                                post={post}
                            />
                }
            })}
        </Container>
    )
});

export default UserWall;
