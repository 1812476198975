import { observer } from 'mobx-react';
import * as React from 'react';
import SearchBar from '../shared/Input/SearchBar';
import { DataStoreContext } from '../../store/rootStore';
import { IUser } from '../../store/user';
import ContactCard from './ContactCard';
import { useNavigate } from 'react-router-dom';
import { ERoutes } from '../shared/Navigation/ERoutes';
import { Container } from '../shared/styled';
import useSearchFriends from '../utils/useSearchFriends';
import Header from '../shared/Navigation/Header';
import { AppStoreContext } from '../../store/appStore';

const Contacts = observer(() => {

    let navigate = useNavigate();

    const { network, message } = React.useContext(DataStoreContext);
    const { app } = React.useContext(AppStoreContext)

    const friends: IUser[] = network.friends != null ? network.friends : [];

    // FOR SEARCH BAR:
    const { searchContent, handleSearchContent, filteredFriends } = useSearchFriends(friends);

    const contactFriend = (friendToContact: IUser) => {
        message.setSelectFriend(friendToContact);

        if (friendToContact != null)
        {
            navigate(`${ERoutes.Conversation}/${friendToContact.id}`);
        };

    };

    React.useEffect(() => {
        
        if (network.friends == null)
        {
            app.setLoading(true);
            network.getAllFriends();
            app.setLoading(false);
        };
    }, []);

    return (
        <Container>
            <Header title={'Vos contacts'} url={ERoutes.Messages} />
            <SearchBar
                label="Chercher un ami..."
                value={searchContent}
                action={handleSearchContent}
                marginBottom={3}
            />
            { filteredFriends?.map(friend => {
                return (
                    <ContactCard
                        key={friend.id} 
                        friend={friend}
                        action={() => contactFriend(friend)}
                    />
                )})
            }
        </Container>
  )
});

export default Contacts