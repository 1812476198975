import * as React from 'react';
import { Button, BUTTON_HEIGHT, BUTTON_WIDTH } from '../styled';
import { Theme } from '../../../assets/Theme';

interface Props {
    label: string;
    action: () => void;
    whiteBackground: boolean;
    disabled?: boolean;
    width?: number;
    height?: number;
};

const MiniButton: React.FC<Props> = (props: Props) => {

    return (
        <Button 
            border
            borderColor={props.disabled ? Theme.colors.white : Theme.colors.violet}
            color={props.whiteBackground || props.disabled ? Theme.colors.violet : Theme.colors.white}
            backgroundColor={props.disabled ? Theme.colors.greyBorder : props.whiteBackground ? Theme.colors.white : Theme.colors.violet}
            width={props.width ? props.width : BUTTON_WIDTH/2}
            height={props.height ? props.height : BUTTON_HEIGHT}
            marginTop={0.1}
            onClick={props.action}
        >
            {props.label}
        </Button>
    );
};

export default MiniButton; 