import { AxiosError } from 'axios';
import { observer } from 'mobx-react';
import * as React from 'react'
import styled from 'styled-components';
import { api } from '../../../api/api';
import { Theme } from '../../../assets/Theme';
import { DataStoreContext } from '../../../store/rootStore';
import { AlertStoreContext } from '../../../store/alertStore';
import { ICity } from '../../../store/user';
import { EToastStatus } from '../Information/Toast';
import { H3, INPUT_PADDING } from '../styled';
import SearchBar from './SearchBar';

const { REACT_APP_API_URL } = process.env;

const Container = styled.div`
width: 100%;
`;

const Results = styled.ul`
width: calc(100% - ${INPUT_PADDING});
padding-left: 2rem;
background-color: ${Theme.colors.powderWhite};
max-height: 60rem;
overflow: scroll;
position: absolute;
z-index: 999;
top: 0;
`;

const Item = styled.li`
padding-left: 1rem;
height: 3rem;
font-size: ${Theme.fontSizes.normal};
color: ${Theme.colors.violet};

&:hover {
    background-color: ${props => props.theme.colors.pinkMedium};
}
`;

interface IPropsCityInput {
    city?: ICity | null | undefined; // PROP EXISTS ONLY IF A CITY HAS ALREADY BEEN RECORDED
    fromSearch?: boolean; // TRUE IF CITY INPUT IS USED FROM NETWORK PARENT RESEARCH OTHERWISE IT IS USED FOR CREATE OR UPDATE USER PROFILE FROM REGISTER STORE
    action?: () => void; // USED TO RELOAD GET PARENTS ON SEARCH WHEN A NEW CITY HAS BEEN SELECTED
};

const CityInput: React.FC<IPropsCityInput> = observer((props: IPropsCityInput) => {

    const initialCityName: string = props.city ? props.city.city_realname : '';

    const [selectedCityName, setSelectedCityName] = React.useState<string>(initialCityName);
    const [suggestedCities, setSuggestedCities] = React.useState<ICity []>([]);
    const [hasSelected, setHasSelected] = React.useState<boolean>(props.city != null);

    const { register, network } = React.useContext(DataStoreContext);
    const { alert } = React.useContext(AlertStoreContext);

    const handleChange = (e : React.ChangeEvent<HTMLInputElement>) => {
        setHasSelected(false);
        setSelectedCityName(e.target.value);
    };

    const getSuggestedCitiesFrom = async (cityName : string | undefined) => {
        if(cityName != null)
        {
            try {
                if(cityName.trim().length > 0)
                {
                    const response = await api.get(`${REACT_APP_API_URL}/api/search/city/${cityName}`);
                    if (response.status === 200) 
                    {
                        setSuggestedCities(response.data);
                    };
                };
            } catch (error) {
                const err = error as AxiosError;
                if (err.response) 
                {
                    const message = err.response.data.message?.length > 0 ? err.response.data.message : "Des erreurs se sont produites :";
                    alert.setAlert(EToastStatus.FAIL, message, err.response.data.errors);
                };
            };
        }
    };

    const selectCity = (city: ICity) => {
        setSelectedCityName(city.city_realname);
        if (props.fromSearch)
        {
            network.setCity(city);
            if (props.action)
            {
                props.action(); // Reload search get parents
            };
        } else {
            register.setCity(city);  
        };
        setHasSelected(true);
    };

    React.useEffect(() => {
        if (!hasSelected)
        {
            const searchRequest = setTimeout(() => getSuggestedCitiesFrom(selectedCityName), 500);
            return () => clearTimeout(searchRequest);
        }
    }, [selectedCityName]);

    return (
        <Container>
            {!hasSelected &&
                <Results>
                    <H3>Sélectionnez votre ville :</H3>
                    {suggestedCities.map(city => 
                        <Item 
                            key={city.city_id}
                            onClick={() => selectCity(city)}
                        >
                            {city.city_realname}
                        </Item>
                    )}
                </Results>
            }
            <SearchBar 
                label="Chercher une ville..."
                action={handleChange} 
                value={selectedCityName} 
            />
        </Container>
    )
});

export default CityInput;