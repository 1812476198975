import { observer } from 'mobx-react';
import * as React from 'react';
import { DataStoreContext } from '../../store/rootStore';
import { AlertStoreContext } from '../../store/alertStore';
import { IUser } from '../../store/user';
import ContactCard from '../Messagerie/ContactCard';
import { ERoutes } from '../shared/Navigation/ERoutes';
import { CircleShape, Container, EmptyData, H4, Illustration, Text } from '../shared/styled';
import { ITransaction } from '../../store/transaction';
import globe from './../../assets/illustrations/globe.svg';
import { EToastStatus } from '../shared/Information/Toast';
import { api } from '../../api/api';
import Header from '../shared/Navigation/Header';

const { REACT_APP_API_URL } = process.env;

// THIS COMPONENT IS USED FOR PUBLIC GARDS IN ORDER TO SELECT THE PARENT TO GARD CHILDREN
const SelectCandidate: React.FC = observer(() => {

    const { transactions } = React.useContext(DataStoreContext);
    const { alert } = React.useContext(AlertStoreContext);

    const transaction: ITransaction | null = transactions.selectedTransaction;
    const candidates: IUser[] = transaction?.users != null ? transaction.users : [];

    const getIfUserIsAvailable = (candidate: IUser): boolean => {
        let isAvailable = false;
        if (transaction?.responses != null)
        {
            isAvailable = transaction.responses.some(resp => resp.userId === candidate.id && resp.parentIsAvailable);
        };
        return isAvailable;
    };

    const getIfUserIsValidate = (candidate: IUser): boolean => {
        let isValidate = false;
        if (transaction?.responses != null)
        {
            isValidate = transaction.responses.some(resp => resp.userId === candidate.id && resp.parentIsValidate);
        };
        return isValidate;
    };

    const selectCandidate = async (candidat: IUser) => {
        try 
        {
            const response = await api.put(`${REACT_APP_API_URL}/api/demands/public/candidate/select/${transaction?.id}/${candidat.id}`);
            if (response.status === 200 && response.data != null)
            {     
                const updatedTransaction = response.data[0];
                alert.setAlert(EToastStatus.SUCCESS, `Nous venons d'envoyer un mail à votre ami pour lui confirmer votre choix`, null);
                transactions.setSelectedTransaction(updatedTransaction);
            }
        } catch (error) {
            alert.setAlert(EToastStatus.FAIL, `Une erreur s'est produite durant la validation d'une candidature à une garde`, null);
        };
    };

    return (
        <Container>
            <Header title={'Résultat de votre demande'} url={ERoutes.Home}/>
            { candidates.length > 0 ?
                <>
                    <Text align='left' marginBottom='2rem'>
                        Suite à votre demande, vous avez reçu des réponses qui viennent de parents de votre réseau.
                    </Text>
                    <Text align='left' marginBottom='2rem'>
                        Si certains sont disponibles, il vous reste à choisir lequel sera en charge de s'occuper de votre enfant.
                    </Text>
                    { candidates.map(candidate => {
                        return (
                            <ContactCard
                                key={candidate.id} 
                                friend={candidate}
                                action={() => selectCandidate(candidate)}
                                isAvailable={getIfUserIsAvailable(candidate)}
                                isValidate={getIfUserIsValidate(candidate)}
                                transaction={transaction}
                            />
                        )})
                    }
                </>
                :
                    <EmptyData>
                        <CircleShape>
                            <Illustration src={globe} />
                        </CircleShape>
                        <H4>Vous n'avez pas encore reçu de réponses de votre réseau.</H4>
                    </EmptyData>
            }
        </Container>
  )
});

export default SelectCandidate