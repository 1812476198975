import * as React from 'react';
import styled from 'styled-components';
import selectPhoto from './../../../assets/icons/profile/photo-profil.svg';
import { AVATAR_HEIGHT, AVATAR_WIDTH } from '../../shared/styled';
interface ISelectPhoto {
    forProfile?: boolean;
    forRegister?: boolean;
    width?: number,
    height?: number,
    borderRadius?: number,
};

const SelectPhoto = styled.button<ISelectPhoto>`
background-color: ${props => props.theme.colors.pinkStrong};
background-image: url(${selectPhoto});
background-position: center;
background-repeat: no-repeat;
min-width: ${props => props.width ? props.width + 'rem' : '4rem'};
min-height: ${props => props.height ? props.height + 'rem' : '4rem'};
border-radius: ${props => props.borderRadius ? props.borderRadius + 'rem' : '50%'};
border: none;
padding: 0;
z-index: 9999;
${({ forProfile }) => forProfile && `
    position: absolute;
    left: calc(50% + ${AVATAR_WIDTH}rem/2 - 3rem);
    bottom: calc(-${AVATAR_HEIGHT}rem + 4rem/2);
`}
${({ forRegister }) => forRegister && `
    position: absolute;
    left: calc(50% + ${AVATAR_WIDTH}rem/2 - 3rem);
    bottom: 0;
`}
`;

const ImportFile = styled.input`
display: none;
`;

interface UploadImagesProps {
    forProfile?: boolean,
    forRegister?:boolean,
    action: ((e: React.ChangeEvent<HTMLInputElement>) => void) | (() => void),
    multiple: boolean,
    width?: number,
    height?: number,
    borderRadius?: number,
};

const UploadImages: React.FC<UploadImagesProps> = (props : UploadImagesProps) => {
    
    const inputFile = React.useRef<HTMLInputElement | null>(null);

    const handleFile = () => {
        if (inputFile.current != null)
        {
            inputFile.current.click()
        };
    };

    return (
        <>
            <SelectPhoto 
                onClick={handleFile} 
                forProfile={props.forProfile}
                forRegister={props.forRegister}
                width={props.width}
                height={props.height}
                borderRadius={props.borderRadius}
            />
            {props.multiple ? 
                <ImportFile 
                    type='file'
                    multiple
                    ref={inputFile}
                    onChange={e => props.action(e)}
                />
            :
                <ImportFile 
                    type='file'
                    ref={inputFile}
                    onChange={e => props.action(e)}
                />
            }
        </>
    );
};

export default UploadImages;