import * as React from 'react';
import { Button, Text } from '../shared/styled';
import { DataStoreContext } from '../../store/rootStore';
import ParentProfile from '../Parent/ParentProfile';
import { observer } from 'mobx-react-lite';
import { ESearchSteps } from './ESearchSteps';
import { CurrentStep as Container } from '../DirectPages/Search';

const ContactParent: React.FC = observer(() => {

    const { search, parent } = React.useContext(DataStoreContext);

    const validStep = () => {
        search.setCurrentStep(ESearchSteps.PREPARE_MESSAGE);
    };

    return (
        <>
            {parent.selectedParent != null ? 
            <Container>
                <ParentProfile user={parent.selectedParent}/>
                <Button onClick={validStep}>
                    Contacter {parent.selectedParent.firstname}
                </Button>
            </Container>
            :    
                <Text>
                    Aucun parent n'a été séléctionné pour la garde de vos enfants.
                </Text>
            }
        </>
    )
});

export default ContactParent;
