import { observer } from 'mobx-react';
import React from 'react';
import { Text, Icon, Button, Container } from '../../shared/styled';
import Header from '../../shared/Navigation/Header';
import success from '../../../assets/icons/success.svg'
import { useNavigate, useParams } from 'react-router-dom';
import { DataStoreContext } from '../../../store/rootStore';
import { AppStoreContext } from '../../../store/appStore';
import { AlertStoreContext } from '../../../store/alertStore';
import { EToastStatus } from '../../shared/Information/Toast';
import { ERoutes } from '../../shared/Navigation/ERoutes';
import { ValidAgreementsContainer } from './RegisterStep1';
import { ValidAgreementToggleButton } from '../../shared/Buttons/ToggleButton';
import IdenfyWebView from './IdenfyWebView';
import Agreement from '../../shared/Information/Agreement';
import { EAgreementsURL } from '../../utils/EAgreements';
import { EAutoIdentificationStatus, IIdenfyToken } from './CheckIdentity';



const RecheckIdentity: React.FC = observer(() => {

    const { register } = React.useContext(DataStoreContext);
    const { app } = React.useContext(AppStoreContext);
    const { alert } = React.useContext(AlertStoreContext);

    let { accountId } = useParams();

    const [autoIdentificationStatus, setAutoIdentificationStatus] = React.useState<EAutoIdentificationStatus | null>(null);
    const [token, setToken] = React.useState<IIdenfyToken | null>(null);
    const [startVerification, setStartVerification] = React.useState<boolean>(false);

    const [hasAcceptedConfidPolicy, setHasAcceptedConfidPolicy] = React.useState<boolean>(false);
    const handleAcceptConfidPolicy = () => setHasAcceptedConfidPolicy(!hasAcceptedConfidPolicy);

    const [source, setSource] = React.useState<EAgreementsURL | null>(null);
    const handleSelectedAgreementToShow = () => setSource(EAgreementsURL.IDENFY_CONFID_POLICY);
    const closeAgreementToShow = () => setSource(null);

    let navigate = useNavigate();

    const getAuthToken = async () => {
        app.setLoading(true);
        const { success, token } = await register.getNewAuthentificationToken(accountId);
        if (success)
        {
            setToken(token);
            setStartVerification(true);
        };
        app.setLoading(false);
    };

    const getVerificationFeedback = (message: MessageEvent | null) => {
        if (message?.data?.status != null)
        {
            setAutoIdentificationStatus(message.data.status.toUpperCase());
        };
    };
    
    React.useEffect(() => {
        if (autoIdentificationStatus === EAutoIdentificationStatus.APPROVED || autoIdentificationStatus === EAutoIdentificationStatus.FAILED)
        {
            alert.setAlert(EToastStatus.SUCCESS, "Nous allons maintenant vérifier votre identité, nous vous envoyons un mail dès que l'analyse est terminée.", null);
            setTimeout(() => navigate(ERoutes.Login), 3000);
        };
        if (autoIdentificationStatus === EAutoIdentificationStatus.UNVERIFIED)
        {
            alert.setAlert(EToastStatus.FAIL, "Votre vérification d'identité n'est pas finalisée, relancez la pour terminer votre inscription", null);
        };
        setStartVerification(false);
      }, [autoIdentificationStatus]);

    React.useEffect(() => {
        if (token != null && startVerification)
        {
            window.addEventListener("message", getVerificationFeedback, false);
        };
    }, [token, startVerification]);

    return (
        <>
            {startVerification && token != null ?
                <IdenfyWebView authToken={token.token} />
            :
            source != null ?
              <Agreement 
                  src={source}
                  visible={true}
                  close={closeAgreementToShow}
              />
              :
                <Container>
                    <Header 
                      title="(Re)vérifier son identité" 
                      noIcon
                    />
                    <Icon 
                        image={success} 
                        width={20} 
                        height={20}
                    />
                    <Text align='justify' marginTop='3rem' marginBottom='1rem'>
                      Pour vérifier à nouveau votre identité, munissez-vous de votre carte d'identité ou de votre passeport en cours de validité.
                    </Text>
                    <Text align='justify' marginBottom='1rem'>
                        La vérification est réalisée avec l'aide de notre partenaire "iDenfy". L'opération prend environ 2 minutes.
                    </Text>
                    <ValidAgreementsContainer>
                        <ValidAgreementToggleButton
                            label="J'ai lu et j'accepte la politique de confidentialité d'iDenfy*"
                            isSelected={hasAcceptedConfidPolicy}
                            onSelect={handleAcceptConfidPolicy}
                            marginBottom={2}
                            openAgreement={handleSelectedAgreementToShow}          
                        />
                    </ValidAgreementsContainer>
                    <Button
                        disabled={!hasAcceptedConfidPolicy}
                        onClick={getAuthToken}
                    >
                        Vérifier son identité
                    </Button>
                </Container>
            }
        </>
    )
});

export default RecheckIdentity