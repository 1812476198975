import { observer } from 'mobx-react';
import * as React from 'react';
import { useNavigate } from "react-router-dom";
import styled from 'styled-components';
import back from '../../../assets/icons/arrow-back.svg';
import { H3, Icon, NotificationBadge } from '../styled';
import { ERoutes } from './ERoutes';
import { DataStoreContext } from '../../../store/rootStore';
import favicon from './../../../assets/logo/favicon-couleur.svg';
import Avatar from '../Avatar';
import burgerMenu from './../../../assets/icons/burger-menu.svg';
import { Link } from 'react-router-dom';
import { IConversation } from '../../../store/message';
import { AppStoreContext } from '../../../store/appStore';
 
export const PADDING = '1rem';
export const HEADER_HEIGHT = '5.6rem';
export const MARGIN_BOTTOM_HEADER = '2rem'

const ICON_WIDTH = 3.2;
interface ContainerProps {
    noMargin?: boolean,
}

export const HeaderContainer = styled.div<ContainerProps>`
background: ${props => props.theme.colors.powderWhite};
height: ${HEADER_HEIGHT};
display: flex;
justify-content: space-between;
align-items: center;
width: 100%;
margin-bottom: ${props => props.noMargin ? 0 : MARGIN_BOTTOM_HEADER};
`;

const IconBack = styled.div`
background: url(${back}) left no-repeat ${props => props.theme.colors.powderWhite};
width: 3rem;
height: 3rem;
cursor: pointer;
`;

const EmptyBox = styled.div`
width: ${ICON_WIDTH}rem;
`;
interface Props {
    title? : string,
    url? : ERoutes | string,
    action?: (() => void) | null,
    noMargin?: boolean,
    noIcon?: boolean,
    icon?: JSX.Element | null,
}

const Header : React.FC<Props> = observer((props: Props) => {

    let navigate = useNavigate();

    const { userLogged, message, transactions, network } = React.useContext(DataStoreContext);
    const { app } = React.useContext(AppStoreContext);

    const moveToProfile = () => navigate(ERoutes.MyProfile);

    const moveBackHome = () => navigate(ERoutes.Home);

    // FOR UNREAD MESSAGES NOTIFICATIONS:
    const filteredConversations: any = message.conversations?.map(conv => {
        return {...conv, messages: conv.messages != null ? conv.messages.filter(mess => mess.recipientId === userLogged.user?.id && !mess.isRead) : null}
    });

    const hasUnreadMessages: boolean = filteredConversations?.some((transac: IConversation) => transac.messages?.length > 0);
    const hasPublicGardsNotAnswered: boolean = transactions.getNumberOfPublicDemandNotAnswered(userLogged.user?.id) > 0;
    const hasPendingRelationships: boolean = network.pendingRelationships != null && network.pendingRelationships.length > 0;

    const displayNotificationBadge: boolean = hasUnreadMessages || hasPublicGardsNotAnswered || hasPendingRelationships;


    return (
        <HeaderContainer noMargin={props.noMargin}>
            {props.url ?
                <Link to={props.url} style={{ display: 'flex', alignItems: 'center' }} >
                    <IconBack />
                </Link>
            : props.action != null ?
                <IconBack onClick={props.action}/>
            : 
                <Icon 
                    image={favicon} 
                    width={5.4} 
                    height={3.4}
                    onClick={moveBackHome}
            />
            }
            <H3>{props.title || ''}</H3>
            { props.noIcon ?
                <EmptyBox /> 
            : props.icon ?
                props.icon     
            :
                <Icon 
                    image={burgerMenu} 
                    width={ICON_WIDTH} 
                    height={ICON_WIDTH}
                    borderRadius='50%'
                    backgroundCover
                    onClick={app.handleOpenMenu}
                    position='relative'
                >
                    {displayNotificationBadge &&
                        <NotificationBadge />
                    }
                </Icon>

            }
        </HeaderContainer>
    )
});

export default Header;



