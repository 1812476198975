import { types, applySnapshot, flow } from "mobx-state-tree";
import { ICity, IUser } from "./user";
import { EDays } from "../components/utils/EDays";
import { api } from './../api/api';
import { EToastStatus, StoreAlert } from './alertStore';
import { IChild } from "./child";
import { StoreApp } from "./appStore";
import defaultUser from './../assets/icons/avatar/DefaultUser.png';

const { REACT_APP_API_URL }= process.env;


export enum EActivationCodeType {
    PREMIUM = 'premium',
    SPONSOR = 'sponsor'
};

export interface IActivationCode {
    id: number,
    code: string,
    type: EActivationCodeType,
    credits: number,
    isBlocked: boolean,
    sponsorAccountId: number | null,
    expirationDate: Date,
    createdAt: Date,
    updatedAt: Date,
    estabId: number | null
};

export interface IDay {
    label: EDays,
    start: Date,
    end: Date,
};

const Day = types
.model({
    label: types.number,
    start: types.Date,
    end: types.Date,
});

export const UserRegister = types
.model({
    id: types.optional(types.number, -1),
    email: types.maybeNull(types.optional(types.string, "")),
    password: types.maybeNull(types.optional(types.string, "")),
    firstname: types.string,
    lastname: types.string,
    city: types.maybeNull(types.frozen<ICity>()),
    address1: types.maybeNull(types.string),
    address2: types.maybeNull(types.string),
    phone: types.maybeNull(types.string),
    biography: types.string,
    birthdate: types.maybeNull(types.Date),
    avatar: types.maybeNull(types.string),
    availabilities: types.optional(types.array(Day), []),
    children: types.optional(types.array(types.frozen<IChild>()), []),
    activationCode: types.maybeNull(types.frozen<IActivationCode>()),
})
.actions(self => ({
    setEmail(email : string) {
        self.email = email;
    },
    setPassword(password: string) {
        self.password = password;
    },
    setFirstname(firstname : string) {
        self.firstname = firstname;
    },
    setLastname(lastname: string) {
        self.lastname = lastname;
    },
    setCity(city: ICity) {
        self.city = city;
    },
    setAddress1(address: string)
    {
        self.address1 = address
    },
    setAddress2(address: string)
    {
        self.address2 = address
    },
    setPhone(phone: string)
    {
        self.phone = phone
    },
    setBiography(biography: string) {
        self.biography = biography;
    },
    setBirthdate(birthdate: Date) {
        self.birthdate = birthdate;
    },
    setAvatar(avatarUrl : string) {
        self.avatar = avatarUrl;
    },
    setChildren(childToAdd: IChild) {
        if (self.children.some(child => child.firstname === childToAdd.firstname)) // if child already exists
        {
            self.children.replace(self.children.filter(child => child.firstname !== childToAdd.firstname))
        } 
        else
        {
            self.children.push(childToAdd);
        };
    },
    removeChild(childToRemove: IChild) {
        if (self.children.some(child => child.firstname === childToRemove.firstname)) // if child already exists
        {
            self.children.replace(self.children.filter(child => child.firstname !== childToRemove.firstname))
        } 
    },
    updateDay(dayToUpdate: IDay) {
        if (self.availabilities.some(day => day.label === dayToUpdate.label)) // if day already exists
            {
                self.availabilities.replace(self.availabilities.filter(day => day.label !== dayToUpdate.label))
            } 
        else
            {
                const newDay = Day.create(dayToUpdate); // add day among availabilities
                self.availabilities.push(newDay);
            }
    },
    setStartTime(dayToSet : IDay, startTime: Date) {
        self.availabilities.replace(self.availabilities.filter(day => day.label !== dayToSet.label));
        const dayUpdated = Day.create({...dayToSet, start: startTime});
        self.availabilities.push(dayUpdated);
    },
    setEndTime(dayToSet : IDay, endTime: Date) {
        self.availabilities.replace(self.availabilities.filter(day => day.label !== dayToSet.label));
        const dayUpdated = Day.create({...dayToSet, end: endTime});
        self.availabilities.push(dayUpdated);
    },
    copyUserInfo(userToUpdate: IUser) {
        if (userToUpdate.birthdate != null)
        {
            const availabilitiesToDate: IDay[] = userToUpdate.availabilities.map(e => 
                Day.create({
                    label: e.label,
                    start: new Date(e.start),
                    end: new Date(e.end)
                })
            );

            const updatedUser = {
                id: userToUpdate.id,
                email: userToUpdate.email,
                firstname: userToUpdate.firstname,
                lastname: userToUpdate.lastname,
                city: userToUpdate.city,
                address1: userToUpdate.address1,
                address2: userToUpdate.address2,
                phone: userToUpdate.phone,
                biography: userToUpdate.biography,
                birthdate: new Date(userToUpdate.birthdate),
                avatar: userToUpdate.avatar,
                availabilities: availabilitiesToDate,
            };
            applySnapshot(self, updatedUser);
        };
    },
    updateProfile: flow (function* (profile: any) {
        let operationSucceed = false;
        try 
        {
            const response = yield api.put(`${REACT_APP_API_URL}/api/users/my-profile`, profile)
            if (response.status === 200)
            {
                operationSucceed = true;
            };
        } catch (error: any) {
            const message = error.response?.data?.message?.length > 0 ? error.response.data.message : "Des erreurs se sont produites :";
            StoreAlert.alert.setAlert(EToastStatus.FAIL, message, error.response?.data?.errors);
        };

        return operationSucceed;
    }),
    uploadUserPhoto: flow (function* (data: FormData) {
        StoreApp.app.setLoading(true);
        try {
            const response = yield api.post(`${REACT_APP_API_URL}/api/users/my-profile/upload`, data);
            if (response.status === 200)
            {
                self.avatar = response.data;
            };
        } catch (error: any) {
            const message = error.response?.data?.message?.length > 0 ? error.response.data.message : "Des erreurs se sont produites :";
            StoreAlert.alert.setAlert(EToastStatus.FAIL, message, error.response?.data?.errors);
        };
        StoreApp.app.setLoading(false);
    }),
    getSuggestedCitiesFrom: flow (function* (cityName : string | undefined) {
        let suggestedCities: ICity[] = [];
        if(cityName != null && cityName.trim().length > 0)
        {
            try {
                const response = yield api.get(`${REACT_APP_API_URL}/api/search/city/${cityName}`);
                if (response.status === 200)
                {
                    suggestedCities = response.data;
                };
            } catch (error: any) {
                const message = error.response?.data?.message?.length > 0 ? error.response.data.message : "Des erreurs se sont produites :";
                StoreAlert.alert.setAlert(EToastStatus.FAIL, message, error.response?.data?.errors);
            };
        }
        return suggestedCities;
    }),
    subscribe : flow (function* (userProfile: FormData) {
        let success = false;
        try {
            const response = yield api.post(`${REACT_APP_API_URL}/api/register/subscribe`, userProfile);
            if (response.status === 200) 
            {
                // StoreAlert.alert.setAlert(EToastStatus.SUCCESS, "Nous venons de vous envoyer un email pour confirmer votre inscription. Si vous ne recevez pas l'email, pensez à vérifier dans vos SPAMS.", null);
                success = true;
            };
        } catch (error: any) {
            const message = error.response?.data?.message?.length > 0 ? error.response.data.message : "Des erreurs se sont produites :";
            StoreAlert.alert.setAlert(EToastStatus.FAIL, message, error.response?.data?.errors);
        };
        return success;
    }),
    checkActivationCodeValidity : flow (function* (code: string) {
        let success = false;
        try {
            const response = yield api.post(`${REACT_APP_API_URL}/api/register/activate-code`, { code : code });
            if (response.status === 200 && response.data != null) 
            {
                self.activationCode = response.data;
                success = true;
                StoreAlert.alert.setAlert(EToastStatus.SUCCESS, "Votre code d'activation est valide, vous pouvez vous inscrire", null);
            };
        } catch (error: any) {
            const message = error.response?.data?.message?.length > 0 ? error.response.data.message : "Des erreurs se sont produites :";
            StoreAlert.alert.setAlert(EToastStatus.FAIL, message, error.response?.data?.errors);
        };
        return success;
    }),
    createAccount : flow (function* () {
        let success = false;
        try {
            const response = yield api.post(`${REACT_APP_API_URL}/api/register/create/account`, { 
                activationCode: self.activationCode,
                email: self.email, 
                password: self.password
            });
            if (response.status === 200) 
            {
                success = true;
            };
        } catch (error: any) {
            const message = error.response?.data?.message?.length > 0 ? error.response.data.message : "Des erreurs se sont produites :";
            StoreAlert.alert.setAlert(EToastStatus.FAIL, message, error.response?.data?.errors);
        };
        return success;
    }),
    getAuthentificationToken : flow (function* () {
        let success = false;
        let token = null;
        try {
            const response = yield api.post(`${REACT_APP_API_URL}/api/register/account/identity/verification`, { 
                email: self.email, 
                password: self.password
            });
            if (response.status === 200) 
            {
                token = response.data
                success = true;
            };
        } catch (error: any) {
            const message = error.response?.data?.message?.length > 0 ? error.response.data.message : "Des erreurs se sont produites :";
            StoreAlert.alert.setAlert(EToastStatus.FAIL, message, error.response?.data?.errors);
        };
        return { success, token };
    }),
    getNewAuthentificationToken : flow (function* (accountId: string | undefined) {
        let success = false;
        let token = null;
        if (accountId != null)
        {
            try {
                const response = yield api.post(`${REACT_APP_API_URL}/api/register/account/identity/recheck/${accountId}`);
                if (response.status === 200) 
                {
                    token = response.data
                    success = true;
                };
            } catch (error: any) {
                const message = error.response?.data?.message?.length > 0 ? error.response.data.message : "Des erreurs se sont produites :";
                StoreAlert.alert.setAlert(EToastStatus.FAIL, message, error.response?.data?.errors);
            };
        }
        return { success, token };
    }),
    checkConfirmationCode : flow (function* (confirmationCode: string) {
        let success = false;
        try {
            const response = yield api.post(`${REACT_APP_API_URL}/api/register/check/confirmation-code`, { code : confirmationCode });
            if (response.status === 200) 
            {
                success = true;
            };
        } catch (error: any) {
            const message = error.response?.data?.message?.length > 0 ? error.response.data.message : "Des erreurs se sont produites :";
            StoreAlert.alert.setAlert(EToastStatus.FAIL, message, error.response?.data?.errors);
        };
        return success;
    }),
    changePassword : flow (function* (accessToken: string, password: string) {
        let success = false;
        try {
            const response = yield api.post(`${REACT_APP_API_URL}/api/auth/confirm/newPassword`, { accessToken, password });
            if (response.status === 200 && response.data != null) 
            {
                StoreAlert.alert.setAlert(EToastStatus.SUCCESS, response.data.message, null);
                success = true;
            };
        } catch (error: any) {
            const message = error.response?.data?.message?.length > 0 ? error.response.data.message : "Des erreurs se sont produites :";
            StoreAlert.alert.setAlert(EToastStatus.FAIL, message, error.response?.data?.errors);
        };
        return success;
    }),

}))
.views(self => ({
    dayIsAvailable(dayToCheck: IDay):boolean {
        return self.availabilities.some(day => day.label === dayToCheck.label);
    },
    userInformationNotComplete():boolean {
        const objectKeys = Object.keys(self);
        const objectValues = Object.values(objectKeys);
        return objectValues.some(value => value.length < 1);
    },
    foundDay(dayToFind : IDay): IDay {
        return self.availabilities.filter(day => day.label === dayToFind.label)[0];
    },
    getProfileAvatar() {
        let avatar: string = '';
        avatar = self.avatar != null && self.avatar.length > 0 ? self.avatar : defaultUser;
        return avatar;
    },
}))
;