import React from 'react';
import { Theme } from '../../../assets/Theme';
import styled from 'styled-components';

const CIRCLE_SHAPE_SIZE = 1;

const Container = styled.div<Props>`
width: ${CIRCLE_SHAPE_SIZE}rem;
height: ${CIRCLE_SHAPE_SIZE}rem;
border-radius: 50%;
background-color: ${props => props.isValid ? Theme.colors.greenToast : Theme.colors.warning};
margin-left: ${props => props.marginLeft ? props.marginLeft + 'rem' : 0};
`;

interface Props {
    isValid?: boolean,
    marginLeft?: number
};

const ValidityIndicator: React.FC<Props> = (props: Props) => {
    return (
        <Container 
            isValid={props.isValid} 
            marginLeft={props.marginLeft} 
        />
    );
};

export default ValidityIndicator;