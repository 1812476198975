import * as React from 'react';
import styled from 'styled-components';
import { Theme } from '../../../assets/Theme';
import { IComment } from '../../../store/social';
import { Button, TextArea } from '../../shared/styled';
import { DataStoreContext } from '../../../store/rootStore';
import { AppStoreContext } from '../../../store/appStore';

const Container = styled.div`
width: 100%;
margin-bottom: 2rem;
`;

const Message = styled.div`
background-color: ${Theme.colors.powderWhite};
border-radius: 1rem;
padding: 1rem;
margin-left: 0.5rem;
`;

interface PropsComment {
    comment: IComment;
    closeUpdateModal: () => void;
    postId: number;
};

const UpdateComment: React.FC<PropsComment> = (props: PropsComment) => {

    const [message, setMessage] = React.useState<string>(props.comment.text);

    const { social } = React.useContext(DataStoreContext);
    const { app } = React.useContext(AppStoreContext);

    const divRef = React.useRef<HTMLTextAreaElement>(null);

    const writeMessage = (e : React.ChangeEvent<HTMLTextAreaElement>): void => {
        const message = e.target.value;
        setMessage(message);
    };

    const updateComment = async () => {
        app.setLoading(true);
        await social.domain.updateComment(props.postId, props.comment.id, message);
        app.setLoading(false);
        props.closeUpdateModal();
    };

    React.useEffect(() => {
        if (divRef.current != null)
        {
            divRef.current.focus();
        };
    }, []);

    return (
        <Container>
            <Message>
                <TextArea 
                    ref={divRef}                
                    rows={5}
                    cols={5}
                    value={message}
                    onChange={e => writeMessage(e)}
                    width='88%'                
                />
            </Message>
            <Button onClick={updateComment}>
                Actualiser
            </Button>
        </Container>
    )
};

export default UpdateComment;