import React from 'react';
import { DataStoreContext } from '../../store/rootStore';
import { Button, Container, Icon, Text } from '../shared/styled';
import Header from '../shared/Navigation/Header';
import { useNavigate, useParams } from 'react-router-dom';
import { ERoutes } from '../shared/Navigation/ERoutes';

const ConfirmTransacIsDone: React.FC = () => {

    const { transactions } = React.useContext(DataStoreContext);
    let { transacId, parentId, responseCode } = useParams();

    let navigate = useNavigate();

    const sendConfirmationTransactionIsDone = async () => {
        await transactions.confirmTransactionIsDone(transacId, parentId, responseCode);
        navigate(ERoutes.Login);
    };

    return (
        <Container register>
            <Header 
                title='Rassurez votre ami' 
                noIcon
            />
            <Text marginBottom='1rem'>
                Tout s'est bien passé ?
            </Text>
            <Text marginBottom='1rem'>
                Pour informer votre ami que vous avez bien pris en charge ses enfants, merci d'appuyer sur le bouton ci-dessous.
            </Text>
            <Text marginBottom='1rem'>
                Nous allons ensuite lui envoyer une notification pour le prévenir que tout s'est bien passé.
            </Text>
            <Button onClick={sendConfirmationTransactionIsDone}>
                Tout s'est bien passé
            </Button>   
        </Container>
    )
};



export default ConfirmTransacIsDone