import * as React from 'react';
import styled from 'styled-components';
import { DataStoreContext } from '../../store/rootStore';
import { AlertStoreContext } from '../../store/alertStore';
import { observer } from 'mobx-react';
import { api } from '../../api/api';
import { ERoutes } from '../shared/Navigation/ERoutes';
import { EToastStatus } from '../shared/Information/Toast';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import { ESearchSteps } from './ESearchSteps';
import { CircleShape, EmptyData, H4, Illustration } from '../shared/styled';
import sendDemand from './../../assets/illustrations/send-demand.svg';
import SpinnerLoader from 'react-spinners/BeatLoader';
import { Theme } from '../../assets/Theme';
import { ETransactionType } from '../../store/transaction';

const { REACT_APP_API_URL } = process.env;

const Container = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
margin-top: 2rem;
`;

const LoadContainer = styled.div`
margin-top: 1rem;
margin-bottom: 2rem;
`;

interface Props {
    demandType: ETransactionType;
    message: string;
}

const SendDemand: React.FC<Props> = observer((props: Props) => {

    const { search, parent } = React.useContext(DataStoreContext);
    const { alert } = React.useContext(AlertStoreContext);

    let navigate = useNavigate();

    const resetStore = () => {
        parent.setSelectParent(null); // RESET SELECTED PARENT IF NEEDED
        search.setCurrentStep(ESearchSteps.SELECT_DATE); // RESET WORFLOW AT FIRST STEP
    };

    const successAlertMessage: string = props.demandType === ETransactionType.SINGLE ? 
                                        `Votre demande a bien été envoyée à ${parent.selectedParent?.firstname}`
                                        : `Votre demande a bien été envoyée à votre réseau`;

    const successfullSending = () => {
        navigate(ERoutes.Home);
        alert.setAlert(EToastStatus.SUCCESS, `${successAlertMessage}`, null);
        resetStore();
    };

    const createSingleDemand = async () => {
        try {
            const response = await api.post(`${REACT_APP_API_URL}/api/demands/single/create`, 
                                {   
                                    contactedParentId: parent.selectedParent?.id, 
                                    start: search.start, 
                                    end: search.end, 
                                    text: props.message,
                                    need: search.need
                                });           
            if (response.data != null)
            {
                successfullSending();
            };
        } catch (error) {
            const err = error as AxiosError;
            if (err.response) 
            {
                alert.setAlert(EToastStatus.FAIL, err.response.data.message, null);
            };
        };
    };

    const createPublicDemand = async () => {
        try {
            const response = await api.post(`${REACT_APP_API_URL}/api/demands/public/create`, 
                                {   
                                    start: search.start, 
                                    end: search.end, 
                                    text: props.message,
                                    need: search.need
                                });           
            if (response.data != null)
            {
                successfullSending();
            };
        } catch (error) {
            const err = error as AxiosError;
            if (err.response) 
            {
                alert.setAlert(EToastStatus.FAIL, err.response.data.message, null);
            };
        };
    };


    React.useEffect(() => {
        if (props.demandType === ETransactionType.PUBLIC)
        {
            setTimeout(() => createPublicDemand(), 5000);
        }
        if (props.demandType === ETransactionType.SINGLE)
        {
            setTimeout(() => createSingleDemand(), 5000);
        }
    }, [])

  return (
    <Container>
        <EmptyData>
            <CircleShape>
                <Illustration src={sendDemand} />
            </CircleShape>
            <H4>Veuillez patienter, nous sommes en train de notifier votre réseau.</H4>
        </EmptyData>
        <LoadContainer>
            <SpinnerLoader 
                color={Theme.colors.pinkStrong} 
                loading={true} 
                size={20}         
            />
        </LoadContainer>
    </Container>
  )
});

export default SendDemand;