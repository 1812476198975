import * as React from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { DataStoreContext } from '../../../store/rootStore';
import { ToggleButton } from '../Buttons/ToggleButton';
import { EDays } from '../../utils/EDays';
import { IDay } from '../../../store/profile';
import moment from "moment";
import TimeOptions from '../../utils/TimeOptions';
import { Label } from '../styled';
import { transformDateInSlot, transformSlotInDateTime } from '../../utils/dateManager';
import { edayToString } from '../../utils/edayToString';

const Container = styled.div`
display: flex;
flex-direction: column;
margin-top: 0.5rem;
padding: 0.5rem;
`;

const DayAvailibility = styled.div`
display: flex;
margin-bottom: 1rem;
`;

const SelectTime = styled.div`
display: flex;
justify-content: center;
align-items: center;
`;

enum ETime {
    START,
    END
}

export const daysOfWeek : IDay[] | any[] = [
    {
        label: EDays.MONDAY,
        start: moment().set({day : 1, hour : 12, minute : 0, second : 0}).toDate(),
        end: moment().set({day : 1, hour : 14, minute : 0, second : 0}).toDate(),
    },
    {
        label: EDays.THUESDAY,
        start: moment().set({day : 1, hour : 12, minute : 0, second : 0}).toDate(),
        end: moment().set({day : 1, hour : 14, minute : 0, second : 0}).toDate(),
    },
    {
        label: EDays.WEDNESDAY,
        start: moment().set({day : 1, hour : 12, minute : 0, second : 0}).toDate(),
        end: moment().set({day : 1, hour : 14, minute : 0, second : 0}).toDate(),
    },
    {
        label: EDays.THURSDAY,
        start: moment().set({day : 1, hour : 12, minute : 0, second : 0}).toDate(),
        end: moment().set({day : 1, hour : 14, minute : 0, second : 0}).toDate(),
    },
    {
        label: EDays.FRIDAY,
        start: moment().set({day : 1, hour : 12, minute : 0, second : 0}).toDate(),
        end: moment().set({day : 1, hour : 14, minute : 0, second : 0}).toDate(),
    },
    {
        label: EDays.SATURDAY,
        start: moment().set({day : 1, hour : 12, minute : 0, second : 0}).toDate(),
        end: moment().set({day : 1, hour : 14, minute : 0, second : 0}).toDate(),
    },
    {
        label: EDays.SUNDAY,
        start: moment().set({day : 1, hour : 12, minute : 0, second : 0}).toDate(),
        end: moment().set({day : 1, hour : 14, minute : 0, second : 0}).toDate(),
    },
]

const SelectAvailabilities : React.FC = observer(() => {

    const { register : user } = React.useContext(DataStoreContext);

    const handleTimePeriod = (e: React.ChangeEvent<HTMLSelectElement>, day: IDay, period: ETime): void => {
        const timeSelected = e.currentTarget.value;
        const fullDate = transformSlotInDateTime(timeSelected, day.label);
        updateDaysOfWeek(day, period, fullDate.toDate());
        
        switch(period) 
        {
            case ETime.START:
                user.setStartTime(day, fullDate.toDate());
                break;
            case ETime.END:
                user.setEndTime(day, fullDate.toDate());
                break;
            default:
                return;
        };
    };

    const updateDaysOfWeek = (day: IDay, period: ETime, timeSlot: Date): void => {
        let targetedDay = daysOfWeek.filter(el => el.label === day.label)[0];
        const dayIndex = daysOfWeek.indexOf(day);
        switch(period) 
        {
            case ETime.START:
                targetedDay = {...targetedDay, start : timeSlot};
                break;
            case ETime.END:
                targetedDay = {...targetedDay, end : timeSlot};
                break;
            default:
                break;
        };
        daysOfWeek.splice(dayIndex, 1, targetedDay);
    };

    return (
            <Container>
                {daysOfWeek.map(day => 
                    <DayAvailibility key={day.label}>
                        <ToggleButton 
                            value={day}
                            label={edayToString(day)}
                            isSelected={user.dayIsAvailable(day)}
                            onSelect={() => user.updateDay(day)}
                        />
                            <SelectTime>
                                <Label>
                                    De&nbsp; 
                                </Label>
                                {user.dayIsAvailable(day) ?
                                    <TimeOptions 
                                        handleTime={handleTimePeriod}
                                        day={day}
                                        period={ETime.START}
                                        value={transformDateInSlot(user.foundDay(day).start)}
                                    />
                                :
                                    <TimeOptions 
                                        handleTime={handleTimePeriod}
                                        day={day}
                                        period={ETime.START}
                                        value={transformDateInSlot(day.start)}
                                    />
                                }
                            </SelectTime>
                            <SelectTime>
                                <Label>
                                    À&nbsp;
                                </Label>
                                {user.dayIsAvailable(day) ?
                                    <TimeOptions 
                                        handleTime={handleTimePeriod}
                                        day={day}
                                        period={ETime.END}
                                        value={transformDateInSlot(user.foundDay(day).end)}
                                    />
                                :
                                    <TimeOptions 
                                        handleTime={handleTimePeriod}
                                        day={day}
                                        period={ETime.END}
                                        value={transformDateInSlot(day.end)}
                                    />
                                }
                            </SelectTime>
                    </DayAvailibility>
                )}   
            </Container>
    )
});

export default SelectAvailabilities;