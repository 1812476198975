import * as React from 'react'
import { Text } from '../shared/styled'
import styled from 'styled-components';
import { Theme } from "../../assets/Theme";

const DesktopContainer = styled.div`
background-color: ${Theme.colors.powderWhite};
width: 100vw;
height: 100vh;
display: flex;
align-items: center;
justify-content: center;
`;

const DesktopWaitingRoom: React.FC = () => {
  return (
    <DesktopContainer>
        <Text>
            Désolé mais le service est actuellement disponible uniquement depuis un mobile ou une tablette.
            <br /><br />
            Connectez-vous depuis votre smartphone ou votre tablette pour profiter pleinement du service.
        </Text>
    </DesktopContainer>
    )
}

export default DesktopWaitingRoom