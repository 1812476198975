export const getAge = (birthdate : Date | string | null) => {
    if (typeof birthdate === 'string') 
    {
        birthdate = new Date(birthdate);
    };
    if (birthdate != null)
    {
        const today = Date.now();
        const difference = today - birthdate.getTime();
        const userAge = new Date(difference);
        return Math.abs(userAge.getUTCFullYear() - 1970);
    };
};