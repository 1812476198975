import * as React from 'react';
import styled from 'styled-components';
import { Icon, Input, INPUT_BORDER_SIZE, INPUT_CONTAINER_HEIGHT, INPUT_PADDING } from '../styled';
import searchIcon from './../../../assets/icons/search/search-icon.svg';
import { Theme } from '../../../assets/Theme';

const SEARCH_ICON_WIDTH = 2.2;

const INPUT_WIDTH = `calc(100% - ${SEARCH_ICON_WIDTH}rem - ${INPUT_PADDING})`;

interface IInputContainer {
    marginBottom?: number,
}

const InputContainer = styled.div<IInputContainer>`
border: ${INPUT_BORDER_SIZE} solid ${Theme.colors.greyBorder};
width: 100%;
border-radius: 1rem;
height: ${INPUT_CONTAINER_HEIGHT};
background-color: ${Theme.colors.white};
display: flex;
align-items: center;
margin-bottom: ${props => props.marginBottom ? props.marginBottom +'rem' : 0};
`;

interface SearchBarProps {
    value: string;
    action: (e : React.ChangeEvent<HTMLInputElement>) => void;
    label: string;
    marginBottom?: number;
    onClick?: () => void;
};


const SearchBar: React.FC<SearchBarProps> = (props: SearchBarProps) => {

    return (
        <InputContainer
            onClick={props.onClick} 
            marginBottom={props.marginBottom}
        >
            <Icon
                image={searchIcon}
                width={SEARCH_ICON_WIDTH}
                height={SEARCH_ICON_WIDTH}
                marginLeft={'1rem'}
            />
            <Input
                id="city"
                type="text"
                placeholder={props.label}
                value={props.value}
                onChange={e => props.action(e)}
                width={INPUT_WIDTH}
                noBorder       
            />
        </InputContainer>
    )
};

export default SearchBar;