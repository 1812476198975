import * as React from 'react'
import styled from 'styled-components';
import { IUser } from '../../store/user';
import { Text, AvatarContainer, Icon, AVATAR_HEIGHT, AVATAR_WIDTH, AVATAR_WIDTH_LARGE_SCREEN, AVATAR_HEIGHT_LARGE_SCREEN } from '../shared/styled';
import { Container as Parent, Info, Item, Separator, Status } from './../Transactions/TransactionCard';
import { useNavigate } from 'react-router-dom';
import { DataStoreContext } from '../../store/rootStore';
import { AlertStoreContext } from '../../store/alertStore';
import habite from './../../assets/icons/profile/habite.svg';
import profil from './../../assets/icons/profile/profil.svg';
import parentHeart from './../../assets/icons/profile/parent.svg';
import check from './../../assets/icons/parentCard/check-green.svg';
import { Theme } from '../../assets/Theme';
import trash from './../../assets/icons/parentCard/trash.svg';
import accept from './../../assets/icons/parentCard/check-on.svg';
import { EToastStatus } from '../shared/Information/Toast';
import { ESearchSteps } from '../Search/ESearchSteps';
import defaultUser from './../../assets/icons/avatar/DefaultUser.png';
import NetworkWarnModal from '../shared/Information/NetworkWarnModal';
import { AppStoreContext } from '../../store/appStore';

const InTouchOptions = styled.div`
display: flex;
align-items: center;
`;

const BeInTouch = styled.button`
background-color: transparent;
width: 15rem;
height: 3rem;
border-radius: 2rem;
border-color: ${Theme.colors.pinkMedium};
`;

const Badge = styled.div`
display: flex;
justify-content: center;
width: 4rem;
position: absolute;
right: 0;
top: 0.5rem;
`;

interface ResultsProps {
    parent : IUser;
    isFriend: boolean;
    addParent: () => void;
    isInvitedByUser?: boolean;
    userIsInvited?: boolean;
    fromGard?: boolean;
}

const RelationCard = React.forwardRef<React.RefObject<any>,ResultsProps>((props: ResultsProps, ref: any) => {

    const { parent, search, network } = React.useContext(DataStoreContext);
    const { alert } = React.useContext(AlertStoreContext);
    const { app } = React.useContext(AppStoreContext)
    const [showWarnModal, setShowWarnModal] = React.useState<boolean>(false);

    const warnMessage: string = "Avant d'accepter un nouveau parent dans votre réseau, nous vous recommendons de l'avoir déjà rencontré et de vous être assuré que vous pouvez lui faire confiance.";

    const avatar: string = props.parent?.avatar?.length > 0 ? props.parent.avatar : defaultUser;
    const numberOfGardsDone: number = props.parent != null ?  props.parent.numberOfGardsDone : 0;

    const hasChildrenText: string = props.parent.children == null ? 'Tribu non renseignée' 
                                    : props.parent.children.length === 1 ? 'Parent de 1 enfant' 
                                    : `Parent de ${props.parent.children.length} enfants`

    let navigate = useNavigate();

    const acceptWarning = () => {
        setShowWarnModal(false);
        network.setHasUnderstoodWarningBeforeAccepting(true);
    };

    const moveToParentProfile = () => {
        if (props.parent != null) 
        {
            parent.setSelectParent(props.parent);
            if (props.fromGard)
            {
                search.setCurrentStep(ESearchSteps.CONTACT_PARENT);
                return;
            };
            navigate(`parent/${props.parent.id}`);
        };
    };

    const acceptRelationship = async () => {
        // For every first accept parent, show the warn modal before allowing to accept a parent
        if (network.hasUnderstoodWarningBeforeAccepting && !showWarnModal)
        {
            app.setLoading(true);
            const response = await network.acceptRelationship(props.parent);
            if (response)
            {
                alert.setAlert(EToastStatus.SUCCESS, `Vous êtes maintenant connectés !`, null);
            }
            else
            {
                alert.setAlert(EToastStatus.FAIL, `Une erreur s'est produite`, null);
            };
            app.setLoading(false);
        }
        else
        {
            setShowWarnModal(true);
        }; 
    };

    const refuseRelationship = () => network.refuseRelationship(props.parent);

    const displayContactInfo = (selectedParent : IUser | null) => {
        if (selectedParent != null) 
        {
            if (props.isInvitedByUser)
            {
                return (
                    <>
                        <Icon 
                            image={check}
                            width={2.5}
                            height={2.5}
                        />
                        <Text 
                            marginLeft='0.5rem'
                            size={app.deviceIsTablet() ? Theme.fontSizes.intermediate : Theme.fontSizes.normal}
                        >
                            Invitation envoyée
                        </Text>
                    </>
                )
            }
            else if (props.userIsInvited)
            {
                return (
                    <InTouchOptions>
                        <Status color={Theme.colors.greyLightest}>en attente</Status>
                        <Separator>&nbsp;</Separator>
                        <Icon 
                            image={trash} 
                            width={2.5} 
                            height={2.5}
                            onClick={refuseRelationship}
                        />
                        <Separator>&nbsp;</Separator>
                        <Icon 
                            image={accept} 
                            width={2.5} 
                            height={2.5}
                            onClick={acceptRelationship}
                        />
                    </InTouchOptions>
                )
            }
            else {
                return (
                    <BeInTouch onClick={() => props.addParent()}>
                        <Text size={app.deviceIsTablet() ? Theme.fontSizes.intermediate : Theme.fontSizes.normal}>
                            Se connecter
                        </Text>
                    </BeInTouch>
                )
            };
        };
    };
    
    return(
        <>
            {showWarnModal &&
                <NetworkWarnModal 
                    hasUnderstood={acceptWarning} 
                    isVisible={showWarnModal} 
                    close={() => setShowWarnModal(false)}
                    warnMessage={warnMessage}
                />
            }
            { props.parent != null && 
                    <Parent ref={ref}>
                        <AvatarContainer onClick={moveToParentProfile}>
                            <Icon 
                                image={avatar} 
                                width={app.deviceIsTablet() ? AVATAR_WIDTH_LARGE_SCREEN : AVATAR_WIDTH} 
                                height={app.deviceIsTablet() ? AVATAR_HEIGHT_LARGE_SCREEN : AVATAR_HEIGHT}
                                borderRadius={'50%'}
                                backgroundCover
                            />
                        </AvatarContainer>
                        <Info>
                            {numberOfGardsDone > 0 &&
                                <Badge>
                                    <Icon 
                                        image={network.getParentAward(props.parent)} 
                                        width={4} 
                                        height={4}
                                    />
                                </Badge>
                            }
                            <Item onClick={moveToParentProfile}>
                                <Icon 
                                    image={profil} 
                                    width={2.5} 
                                    height={2.5}
                                />
                                <Text 
                                    marginLeft={'1rem'} 
                                    align='left'
                                    size={app.deviceIsTablet() ? Theme.fontSizes.intermediate : Theme.fontSizes.normal}
                                >
                                    {props.parent.firstname} {props.parent.lastname}
                                </Text>
                            </Item>
                            <Item onClick={moveToParentProfile}>
                                <Icon 
                                    image={habite} 
                                    width={2.5} 
                                    height={2.5}
                                />
                                <Text 
                                    marginLeft={'1rem'} 
                                    align='left'
                                    size={app.deviceIsTablet() ? Theme.fontSizes.intermediate : Theme.fontSizes.normal}
                                >
                                    Habite {props.parent.city?.city_realname}
                                </Text>
                            </Item>
                            <Item onClick={moveToParentProfile}>
                                <Icon 
                                    image={parentHeart} 
                                    width={2.5} 
                                    height={2.5}
                                />
                                <Text 
                                    marginLeft={'1rem'} 
                                    align='left'
                                    size={app.deviceIsTablet() ? Theme.fontSizes.intermediate : Theme.fontSizes.normal}
                                >
                                    {hasChildrenText}
                                </Text>
                            </Item>
                            <Item 
                                justifyContent='center'
                                marginTop={1}
                                noMarginLeft
                            >
                                {!props.isFriend && 
                                    displayContactInfo(props.parent)
                                }  
                            </Item>
                        </Info>
                    </Parent>
        }
    </>
    )
});

export default RelationCard;




