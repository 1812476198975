import * as React from 'react';
import { observer } from 'mobx-react';
import { DataStoreContext } from '../../store/rootStore';
import styled from 'styled-components';
import { IMessage } from '../../store/message';
import Message from './Message';
import { Theme } from '../../assets/Theme';
import { dateToString } from '../utils/dateManager';
import WepoppinsMessage from './WepoppinsMessage';
import { ITransaction } from '../../store/transaction';

const Container = styled.div`
display: flex;
flex-direction: column;
padding: 1rem;
overflow: scroll;
height: 100%;
width: 100%;
`;

interface IMessageWrapper {
    isUserMessage: boolean,
}

const MessageWrapper = styled.div<IMessageWrapper>`
width: 100%;
display: flex;
${({ isUserMessage }) => isUserMessage && `
    flex-direction: row-reverse;
`}
`;

const Date = styled.h2`
width: 100%;
font-size: ${Theme.fontSizes.normal};
color: ${Theme.colors.greyStrong};
text-transform: capitalize;
font-weight: normal;
text-align: center; 
border-bottom: 0.1rem solid ${Theme.colors.greyStrong}; 
line-height: 0.1rem;
margin: 1rem 0 2rem;

& > span {
    background:${Theme.colors.powderWhite}; 
    padding:0 1rem; 
}
`;


type Props = {
    dates: string[];
    messages: IMessage[];
    fromWepoppins?: boolean; // Communication provided by Wepoppins service when user has received public demands
}

const Messages = observer((props: Props) => {

    const { transactions, userLogged : { user } } = React.useContext(DataStoreContext);

    // Scroll to bottom
    const divRef = React.useRef<null | HTMLDivElement>(null);

    const scrollToBottom = () =>
    {
        if (divRef?.current != null)
        {
            divRef.current.scrollIntoView(({ inline: 'center', block: 'nearest' }));
        }
    };

    const publicDemands: ITransaction[] = transactions.getPublicDemands(user?.id);

    React.useEffect(() =>
    {
        if (props.messages.length > 0)
        {
            scrollToBottom();
        }
    }, [props.messages.length]);

    return (
        <Container>
            {props.fromWepoppins ?
                publicDemands.map(transac => 
                    <WepoppinsMessage
                        key={transac.id} 
                        transac={transac} 
                    />
                )
            :
                props.dates.map(date => 
                    <div key={date}>
                        <Date>
                            <span>
                                {date}
                            </span>
                        </Date>
                        {props.messages.filter(message => dateToString(message.createdAt) == date).map((message: IMessage) => {
                            const isUserMessage: boolean = user?.id == message.senderId;
                                return (
                                    <MessageWrapper 
                                        key={message.id}
                                        isUserMessage={isUserMessage}
                                    > 
                                        <Message 
                                            isUserMessage={isUserMessage}
                                            message={message}
                                        />
                                    </MessageWrapper>
                                )
                            })
                        }
                    </div>
                )
            }
        <div ref={divRef} style={{marginBottom: '10rem'}}/>
    </Container>
)
});

export default Messages;