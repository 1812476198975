import * as React from 'react';
import styled from 'styled-components';
import { DataStoreContext } from '../../store/rootStore';
import { IUser } from '../../store/user';
import { observer } from 'mobx-react';
import { CurrentStep as Container } from '../DirectPages/Search';
import FriendsList from '../Network/FriendsList';
import { NetworkToggleButton } from '../shared/Buttons/ToggleButton';
import { Button, Text } from '../shared/styled';
import { Theme } from '../../assets/Theme';
import { ESearchSteps } from './ESearchSteps';
import useSearchFriends from '../utils/useSearchFriends';
import { ETransactionType } from '../../store/transaction';
import SearchBar from '../shared/Input/SearchBar';
import { PADDING } from '../shared/Navigation/Header';

const NavigationButtons = styled.div`
width: calc(100% - 2*${PADDING});
display: flex;
justify-content: space-around;
background: ${Theme.colors.powderWhite};
position: absolute;
bottom: 10%;
padding: 0.2rem;
z-index: 99999;
`;

interface Props {
    demandType: ETransactionType;
    setDemandType: () => void;
    moveBack: () => void;
}

const SelectParent: React.FC<Props> = observer((props: Props) => {

    const { network, search } = React.useContext(DataStoreContext);

    const friends: IUser[] = network.friends != null ? network.friends : [];
    const hasFriends: boolean = friends.length > 0

    const isPublicGard: boolean = props.demandType === ETransactionType.PUBLIC;

    // FOR SEARCH BAR:
    const { searchContent, handleSearchContent, filteredFriends } = useSearchFriends(friends)

    const moveForward = () => search.setCurrentStep(ESearchSteps.PREPARE_MESSAGE);

    return (
            <Container>
                { hasFriends &&
                <>
                    <SearchBar
                        value={searchContent}
                        action={handleSearchContent}
                        label='Rechercher un parent...'
                        marginBottom={2}
                    />
                    <NetworkToggleButton
                        label="Faire une demande à tout mon réseau"
                        isSelected={isPublicGard}
                        onSelect={props.setDemandType}
                        marginBottom={2}           
                    />
                </>
                }
                {isPublicGard && hasFriends ?
                <>
                    <Text marginTop='3rem'>
                        Tous les parents de votre réseau vont être consultés.
                    </Text>
                    <NavigationButtons>
                        <Button 
                            border
                            borderColor={Theme.colors.violet}
                            color={Theme.colors.violet}
                            backgroundColor='transparent'
                            width={16.3}
                            marginTop={0.1}
                            onClick={props.moveBack}
                        >
                            Retour
                        </Button>
                        <Button 
                            width={16.3}
                            marginTop={0.1}
                            onClick={moveForward}
                        >
                            Suivant
                        </Button>
                    </NavigationButtons>
                </>
                :
                    <FriendsList 
                        friends={filteredFriends}
                        fromGard={true} 
                    />
                }
            </Container>
    )
});

export default SelectParent;
