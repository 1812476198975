import * as React from 'react';
import styled from 'styled-components';
import { Text } from '../shared/styled';
import { DataStoreContext } from '../../store/rootStore';
import { observer } from 'mobx-react';
import { Theme } from '../../assets/Theme';

const WIDTH_NUMBER = 4;
const SHAPE_WIDTH = 4;

const Container = styled.div`
display: flex;
justify-content: space-around;
width: 100%;
margin-bottom: 1rem;
position: relative;
`;

interface LineProps {
    length: number,
    progression: number,
}

const Line = styled.div<LineProps>`
position: absolute;
top: calc(${SHAPE_WIDTH}rem / 2);
height: 0.2rem;
background-color: ${props => props.theme.colors.pinkMedium}; 
width: ${
	({ length }) => `
		calc(100vw / ${length / (length - 1)});
	`}
& > div {
    background-color: ${props => props.theme.colors.pinkLight};
    height: 100%;
    width: calc(calc(${props => props.progression * 100 + '%' }) - calc(${SHAPE_WIDTH}rem / 2));
    transition: all 0.50s ease;
}
`;

interface ItemProps {
    length: number,
}

const Item = styled.div<ItemProps>`
display: flex;
flex-direction: column;
align-items: center;
z-index: 1;
width: ${
	({ length }) => `
		calc(100% / ${length});
    `}
`;
interface NumberProps {
    enabled: boolean
}

const Shape = styled.div`
width: calc(${SHAPE_WIDTH}rem);
height: calc(${SHAPE_WIDTH}rem);
background-color: ${props => props.theme.colors.powderWhite};
display: flex;
align-items: center;
justify-content: center;
`;


const Number = styled.div<NumberProps>`
font-size: ${Theme.fontSizes.normal};
display: flex;
align-items: center;
justify-content: center;
width: ${WIDTH_NUMBER}rem;
height: ${WIDTH_NUMBER}rem;
border-radius: 50%;
background-color: ${props => props.theme.colors.pinkStrong}; 
opacity: 0.5;
transition: all 250ms ease;
${({ enabled }) => enabled && `
    opacity: 1;
    color: white;
`}
`;

const steps = [
    {
        id: 1,
        label: "Date",
    },
    {
        id: 2,
        label: "Foyer"
    },
    {
        id: 3,
        label: "Profil"
    },
    {
        id: 4,
        label: "Message"
    }
];

const Timeline: React.FC = observer(() => {

    const { search } = React.useContext(DataStoreContext);

    const length = steps.length;
    const progression = search.currentStep / length;

    return (
        <Container>
            <Line 
                length={length}
                progression={progression}
            >
                <div></div>
            </Line>
            {steps.map(step => 
                <Item 
                    key={step.id} 
                    length={length}
                >
                    <Shape>
                        <Number enabled={search.currentStep >= step.id}>
                            {step.id}
                        </Number>
                    </Shape>
                    <Text>
                        {step.label}
                    </Text>
                </Item>)
            }
        </Container>
    )
});

export default Timeline;
