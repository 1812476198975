import * as React from 'react';
import styled, { keyframes, css } from 'styled-components';
import home_on from './../../../assets/icons/footer/accueil-on.svg';
import home_off from './../../../assets/icons/footer/accueil-off.svg';
import faire_garder_on from './../../../assets/icons/footer/faire-garder-on.svg';
import faire_garder_off from './../../../assets/icons/footer/faire-garder-off.svg';
import activities_on from './../../../assets/icons/footer/activities-on.svg';
import activities_off from './../../../assets/icons/footer/activities-off.svg';
import about_on from './../../../assets/icons/footer/about-on.svg';
import about_off from './../../../assets/icons/footer/about-off.svg';
import reseau_on from './../../../assets/icons/footer/reseau-on.svg';
import reseau_off from './../../../assets/icons/footer/reseau-off.svg';
import messagerie_on from './../../../assets/icons/footer/messagerie-on.svg';
import messagerie_off from './../../../assets/icons/footer/messagerie-off.svg';
import profil_on from './../../../assets/icons/footer/profil-on.svg';
import profil_off from './../../../assets/icons/footer/profil-off.svg';
import calendar_on from './../../../assets/icons/footer/calendar-on.svg';
import calendar_off from './../../../assets/icons/footer/calendar-off.svg';
import contact_on from './../../../assets/icons/footer/contact-on.svg';
import contact_off from './../../../assets/icons/footer/contact-off.svg';
import sponsorize_on from './../../../assets/icons/footer/sponsorize-on.svg';
import sponsorize_off from './../../../assets/icons/footer/sponsorize-off.svg';
import logout_off from './../../../assets/icons/footer/logout-off.svg';
import { NavLink, useLocation } from "react-router-dom";
import { Theme } from '../../../assets/Theme';
import { observer } from 'mobx-react';
import { DataStoreContext } from '../../../store/rootStore';
import { AlertStoreContext } from '../../../store/alertStore';
import { ERoutes } from './ERoutes';
import { IConversation } from '../../../store/message';
import { Button, H3, Icon, NotificationBadge, P1 } from '../styled';
import close from './../../../assets/icons/close.svg';
import { HEADER_HEIGHT, MARGIN_BOTTOM_HEADER } from './Header';
import favicon from './../../../assets/logo/favicon-couleur.svg';
import { clearTokens } from '../../../api/api';
import Axios, { AxiosError } from 'axios';
import { EToastStatus } from '../Information/Toast';
import { IToken } from '../../../api/interceptors';
import { Modal } from '../Information/Modal';
import { AppStoreContext } from '../../../store/appStore';

const { REACT_APP_API_URL } = process.env;

const DELAY = 500; // ms
const CONTAINER_WIDTH = '100%';
const CONTAINER_HEIGHT = '100%';
const PADDING = '1rem';

const appear = keyframes`
0% {
	transform:translateX(${CONTAINER_WIDTH});
}
100% {
	transform: translate(-50%, 0);
}
`;

const disappear = keyframes`
0% {
	transform: translate(-50%, 0);
}
100% {
	transform:translateX(${CONTAINER_HEIGHT});
}
`;

interface ContainerProps {
    close: boolean,
};

const Container = styled.div<ContainerProps>`
z-index: 99999;
position: fixed;
left: 50%;
background: ${props => props.theme.colors.powderWhite};
width: ${CONTAINER_WIDTH};
height: ${CONTAINER_HEIGHT};
animation: ${props => props.close ? css`${disappear} ${DELAY}ms forwards` : css`${appear} ${DELAY}ms forwards`};
`;

const HeaderMenu = styled.div`
padding-left: ${PADDING};
padding-right: ${PADDING};
width: calc(100% - 2*${PADDING});
display: flex;
justify-content: space-between;
align-items: center;
height: ${HEADER_HEIGHT};
margin-bottom: ${MARGIN_BOTTOM_HEADER};
`;

const LinkContainer = styled.div`
width: 100%;
display: flex;
align-items: center;
border-bottom: 0.1rem solid ${Theme.colors.greyBorder};
height: 5rem;
`;
interface IFooterItems {
    text: string,
    path: ERoutes | string,
    imageActive: string,
    imagePassive: string,
};

const menuItems: IFooterItems[] = [
    {
        text: "Accueil",
        path: ERoutes.Home,
        imageActive: home_on,
        imagePassive: home_off
    },
    {
        text: "Demandes",
        path: ERoutes.Search,
        imageActive: faire_garder_on,
        imagePassive: faire_garder_off
    },
    {
        text: "Calendrier",
        path: ERoutes.Calendar,
        imageActive: calendar_on,
        imagePassive: calendar_off
    },
    {
        text: "Activités",
        path: ERoutes.Social,
        imageActive: activities_on,
        imagePassive: activities_off
    },
    {
        text: "Réseau",
        path: ERoutes.Network,
        imageActive: reseau_on,
        imagePassive: reseau_off
    },
    {
        text: "Messagerie",
        path: ERoutes.Messages,
        imageActive: messagerie_on,
        imagePassive: messagerie_off
    },
    {
        text: "Mon profil",
        path: ERoutes.MyProfile,
        imageActive: profil_on,
        imagePassive: profil_off
    },
    {
        text: "Nous contacter",
        path: ERoutes.Contact,
        imageActive: contact_on,
        imagePassive: contact_off
    },
    {
        text: "Parrainer un ami",
        path: ERoutes.Sponsorize,
        imageActive: sponsorize_on,
        imagePassive: sponsorize_off
    },
    {
        text: "A propos",
        path: ERoutes.About,
        imageActive: about_on,
        imagePassive: about_off
    }
];

const Menu: React.FC = observer(() => {

    const location = useLocation();
    const tokens: IToken = JSON.parse(sessionStorage.getItem('tokens') as string);
    const refreshToken = tokens?.refreshToken;  

    const { userLogged, message, transactions, network } = React.useContext(DataStoreContext);
    const { app } = React.useContext(AppStoreContext);

    const { alert } = React.useContext(AlertStoreContext);
    const isLogged = userLogged.user != null;

    // FOR LOGOUT
    const [ wantLogout, setWantLogout ] = React.useState<boolean>(false);
    const handleLogoutModal = (): void => setWantLogout(!wantLogout);

    // FOR UNREAD MESSAGES NOTIFICATIONS:
    const filteredConversations: any = message.conversations?.map(conv => {
        return {...conv, messages: conv.messages != null ? conv.messages.filter(mess => mess.recipientId === userLogged.user?.id && !mess.isRead) : null}
    });

    const hasUnreadMessages: boolean = filteredConversations?.some((transac: IConversation) => transac.messages?.length > 0);
    const hasPublicGardsNotAnswered: boolean = transactions.getNumberOfPublicDemandNotAnswered(userLogged.user?.id) > 0;
    const hasPendingRelationships: boolean = network.pendingRelationships != null && network.pendingRelationships.length > 0;

    // FOR DISCONNECTION
    const exit = async () => {
        try {
            const response = await Axios.post(`${REACT_APP_API_URL}/api/auth/logout`, { refreshToken }); // DO NOT USE INTERCEPTORS TO AVOID USELESS REFRESH TOKEN REQUEST
            if (response.status === 200)
            {
                alert.setAlert(EToastStatus.SUCCESS, "Merci et à bientôt !", null);
                userLogged.setIsAuth(false);
                app.handleOpenMenu();
                clearTokens();
            };
        } catch (error) {
            const err = error as AxiosError;
            if (err.response) 
            {
                alert.setAlert(EToastStatus.FAIL, err.response.data.message, null);
            };
        };
    };

    const displayNotificationBadge = (item: IFooterItems): boolean => item.path === ERoutes.Messages && (hasUnreadMessages || hasPublicGardsNotAnswered)
                                                                    || item.path === ERoutes.Network && hasPendingRelationships;

    const isActive = (item: IFooterItems):boolean => {
        if (item.path === location.pathname || location.pathname.includes(item.path)) 
        {
            return true;
        }
        return false;
    };

    let activeStyle = {
        color: Theme.colors.pinkStrong,
        textDecoration: "none",
      };

      let passiveStyle = {
        color: Theme.colors.greyMedium,
        textDecoration: "none",
      };
    
    return (
        <>
        { isLogged &&
            <Container close={!app.menuIsOpen}>
            { wantLogout &&
                <Modal
                    title='Souhaitez-vous vous déconnecter ?'
                    visible={wantLogout}
                    close={handleLogoutModal}
                    children={
                        <Button onClick={exit}>
                            Se déconnecter
                        </Button>
                    }
                />
            }
                <HeaderMenu>
                    <Icon 
                        image={favicon} 
                        width={5.4} 
                        height={3.4}
                    />
                    <H3>Menu</H3>
                    <Icon 
                        image={close} 
                        width={3.2} 
                        height={3.2}
                        borderRadius='50%'
                        backgroundCover
                        onClick={app.handleOpenMenu}  
                    />
                </HeaderMenu>
                {menuItems.map((item, index) =>
                    <NavLink 
                        key={index}          
                        to={item.path}
                        style={({ isActive }) => isActive ? activeStyle as React.CSSProperties : passiveStyle as React.CSSProperties }
                        onClick={app.handleOpenMenu}
                    >
                        <LinkContainer>
                            <Icon 
                                image={isActive(item) ? item.imageActive : item.imagePassive}
                                width={3.2} 
                                height={3.2}
                                backgroundPosition='bottom'
                                marginLeft='1rem'   
                                position='relative'        
                            >
                                {displayNotificationBadge(item) &&
                                    <NotificationBadge />
                                }
                            </Icon>
                            <P1 
                                color={isActive(item) ? Theme.colors.pinkStrong : Theme.colors.violet}
                                marginLeft='2rem'
                            >
                                {item.text}
                            </P1>
                        </LinkContainer>
                    </NavLink>
                )}
                <LinkContainer onClick={handleLogoutModal}>
                    <Icon 
                        image={logout_off}
                        width={3.2} 
                        height={3.2}
                        backgroundPosition='bottom'
                        marginLeft='1rem'           
                    />
                    <P1 
                        color={Theme.colors.violet}
                        marginLeft='2rem'
                    >
                        Se déconnecter
                    </P1>
                </LinkContainer>
            </Container>
        }
        </>
    )
});

export default Menu;


