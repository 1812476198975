import { observer } from 'mobx-react';
import React from 'react';
import { Text, Icon, Button } from '../../shared/styled';
import Header from '../../shared/Navigation/Header';
import success from '../../../assets/icons/success.svg'
import { useNavigate } from 'react-router-dom';
import { DataStoreContext } from '../../../store/rootStore';
import { AppStoreContext } from '../../../store/appStore';
import { AlertStoreContext } from '../../../store/alertStore';
import { EToastStatus } from '../../shared/Information/Toast';
import { ERoutes } from '../../shared/Navigation/ERoutes';
import { ValidAgreementsContainer } from './RegisterStep1';
import { ValidAgreementToggleButton } from '../../shared/Buttons/ToggleButton';
import IdenfyWebView from './IdenfyWebView';

export enum EAutoIdentificationStatus {
    APPROVED = "APPROVED",
    FAILED = "FAILED",
    UNVERIFIED = "UNVERIFIED"
};

export interface IIdenfyToken {
    token: string;
    accountId: string;
};

interface Props {
    goIdenfyConfidPolicy: () => void;
};

const CheckIdentity: React.FC<Props> = observer((props: Props) => {

    const { register } = React.useContext(DataStoreContext);
    const { app } = React.useContext(AppStoreContext);
    const { alert } = React.useContext(AlertStoreContext);

    const [autoIdentificationStatus, setAutoIdentificationStatus] = React.useState<EAutoIdentificationStatus | null>(null);
    const [token, setToken] = React.useState<string | null>(null);
    const [startVerification, setStartVerification] = React.useState<boolean>(false);

    const [hasAcceptedConfidPolicy, setHasAcceptedConfidPolicy] = React.useState<boolean>(false);
    const handleAcceptConfidPolicy = () => setHasAcceptedConfidPolicy(!hasAcceptedConfidPolicy);

    let navigate = useNavigate();

    const getAuthToken = async () => {
        app.setLoading(true);
        const { success, token } = await register.getAuthentificationToken();
        if (success)
        {
            setToken(token);
            setStartVerification(true);
        };
        app.setLoading(false);
    };

    const getVerificationFeedback = (message: MessageEvent | null) => {
        if (message?.data?.status != null)
        {
            setAutoIdentificationStatus(message.data.status.toUpperCase());
        };
    };

    React.useEffect(() => {
        if (autoIdentificationStatus === EAutoIdentificationStatus.APPROVED || autoIdentificationStatus === EAutoIdentificationStatus.FAILED)
        {
            alert.setAlert(EToastStatus.SUCCESS, "Nous allons maintenant vérifier votre identité, nous vous envoyons un mail dès que l'analyse est terminée.", null);
            setTimeout(() => navigate(ERoutes.Login), 3000);
        };
        if (autoIdentificationStatus === EAutoIdentificationStatus.UNVERIFIED)
        {
            alert.setAlert(EToastStatus.FAIL, "Votre vérification d'identité n'est pas finalisée, relancez la pour terminer votre inscription", null);
        };
        setStartVerification(false);
      }, [autoIdentificationStatus]);

    React.useEffect(() => {
        if (token != null && startVerification)
        {
            window.addEventListener("message", getVerificationFeedback, false);
        };
    }, [token, startVerification]);

    return (
        <>
            {startVerification && token != null ?
                <IdenfyWebView authToken={token} />
            :
                <>
                    <Header 
                        title="C'est presque terminé !" 
                        noIcon
                    />
                    <Icon 
                        image={success} 
                        width={20} 
                        height={20}
                    />
                    <Text align='justify' marginTop='3rem' marginBottom='1rem'>
                        Il ne reste plus qu'à vérifier votre identité afin de pouvoir profiter de Wepoppins.
                    </Text>
                    <Text align='justify' marginBottom='1rem'>
                        Pour vérifier votre identité, munissez-vous de votre carte d'identité ou de votre passeport en cours de validité.
                    </Text>
                    <Text align='justify' marginBottom='1rem'>
                        La vérification est réalisée avec l'aide de notre partenaire "iDenfy". L'opération prend environ 2 minutes.
                    </Text>
                    <ValidAgreementsContainer>
                        <ValidAgreementToggleButton
                            label="J'ai lu et j'accepte la politique de confidentialité d'iDenfy*"
                            isSelected={hasAcceptedConfidPolicy}
                            onSelect={handleAcceptConfidPolicy}
                            marginBottom={2}
                            openAgreement={props.goIdenfyConfidPolicy}          
                        />
                    </ValidAgreementsContainer>
                    <Button
                        disabled={!hasAcceptedConfidPolicy}
                        onClick={getAuthToken}
                    >
                        Vérifier son identité
                    </Button>
                </>
            }
        </>
    )
});

export default CheckIdentity