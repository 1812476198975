import * as React from 'react';
import { IImage } from '../../../store/social'; 
import styled from 'styled-components';
import { Modal, PADDING_MODAL } from '../../shared/Information/Modal';
import { Theme } from '../../../assets/Theme';
import { Text } from '../../shared/styled';

const BORDER_IMAGE = '0.1rem';
interface PictureContainerProps {
    src: string,
    coverPosition?: boolean,
    forceSize?: boolean
    margin?: string,
    width?: string,
    height?: number,
    borderRadius?: number,
    position?: string,
    showBorder?: boolean
};

const PictureContainer = styled.div`
width: 100%;
height: 25rem;
overflow-x: auto;
overflow-y: hidden;
`;

export const Picture = styled.div<PictureContainerProps>`
min-width: ${props => props.width ? `calc(${props.width} - 2*${BORDER_IMAGE})` : `calc(100% - 2*${BORDER_IMAGE})`};
height: ${props => props.height ? props.height + 'rem' : `calc(100% - 2*${BORDER_IMAGE})`};
background-image: url(${props => props.src});
background-size: ${props => props.coverPosition ? 'cover' : 'contain'};
background-position: center;
background-repeat: no-repeat;
background-repeat: no-repeat;
border: ${props => props.showBorder ? `${BORDER_IMAGE} solid ${Theme.colors.greyLight}` : 'none'};
border-radius: ${props => props.borderRadius ? props.borderRadius + 'rem' : 0};
margin: ${props => props.margin ? props.margin : 0};
position: ${props => props.position ? props.position : 'unset'};
${({ forceSize }) => forceSize && `
    width: calc(100% - ${PADDING_MODAL});
    height: 25rem;
`}
`;

const ModalContainer = styled.div`
max-height: 70vh;
width: 100%;
overflow: scroll;
`;

interface PicturesPros {
    list: IImage[];
};

const Pictures: React.FC<PicturesPros> = (props: PicturesPros) => {

    const [showListPictures, setShowListPictures] = React.useState<boolean>(false); 

    const closeList = () => setShowListPictures(false);
    
    const openList = () => setShowListPictures(true);

    const firstPicture: IImage = props.list[0];

    const displayFirstPicture = () => {
        if (props.list.length === 0)
        {
            return null;
        }
        else // At least ONE PHOTO
        {
            return (
                <Picture 
                    onClick={openList}
                    src={firstPicture.src} 
                    coverPosition
                />
            )
        };
    };

    const displayPicturesList = () => {
        if (props.list.length > 0)
        return (
            <ModalContainer>
                {props.list.map(image => 
                    <Picture 
                        src={image.src} 
                        key={image.id}
                        forceSize
                        coverPosition
                        margin='0 0 1rem 0'
                        showBorder
                    />)}
            </ModalContainer>    
        )
    };

    return (
        <>
            {showListPictures ?  
                <Modal 
                    title=''
                    close={closeList}
                    visible={showListPictures}
                    children={displayPicturesList()}
                    width='100%'          
                />
            : 
            <>
                <PictureContainer>
                    { displayFirstPicture() }
                </PictureContainer>
                { props.list.length > 1 &&
                    <Text size={Theme.fontSizes.small} align='right'>Voir toutes les photos envoyées</Text>
                }
            </>
            }
        </>
    )
};

export default Pictures