
import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { DataStoreContext } from '../../store/rootStore';
import styled from 'styled-components';
import { Icon, Text } from '../shared/styled';
import boy from '../../assets/icons/avatar/avatarBoy.png';
import girl from '../../assets/icons/avatar/avatarGirl.png';
import check from '../../assets/icons/parentCard/check-blanc.svg';
import { Theme } from '../../assets/Theme';

const Container = styled.div`
display: flex;
justify-content: space-between;
width: 100%;
margin-top: 2rem;
`;

interface PropsButton {
    selected: boolean,
}

const Button = styled.button<PropsButton>`
display: flex;
align-items: center;
background-color: ${props => props.theme.colors.white};
width: 49%;
height: 7rem;
border: 0.1rem solid #ccc;
box-sizing: border-box;
border-radius: 0.2rem;
margin-bottom: 1rem;
cursor: pointer;
position: relative;
padding: 1rem;
${({ selected }) => selected && `
    border: 0.2rem solid #CF5285;
`}
`;

interface PropsImage {
    boy: boolean,
};

const ImageContainer = styled.div<PropsButton>`
width: 5rem;
height: 5rem;
display: flex;
align-items: center;
justify-content: center;
border-radius: 50%;
background-color: ${props => props.selected ? props.theme.colors.pinkMedium : props.theme.colors.greyLightest};
transition: all 250ms;
`;

interface CheckedMarkProps {
    width: number,
    height: number,
    position: string,
    backgroundColor: string,
    marginLeft?: number;
    marginRight?: number;
};

export const CheckedMark = styled.div<CheckedMarkProps>`
display: flex;
align-items: center;
justify-content: center;
width: ${props => props.width + 'rem'};
height: ${props => props.height + 'rem'};
border-radius: 50%;
background-color: ${props => props.backgroundColor};
position: ${props => props.position};
right: 0.5rem;
top: 0.5rem;
margin-left: ${props => props.marginLeft ? props.marginLeft + 'rem' : 0};
margin-right: ${props => props.marginRight ? props.marginRight + 'rem' : 0};
`;

export enum ESex {
    BOY = 'boy',
    GIRL = 'girl'
};

interface Props {
    forceSelectAvatar: () => void;
}

const SelectSex: React.FC<Props> = observer((props: Props) => {

    const { childProfile } = React.useContext(DataStoreContext);
  
    const selectSex = (sex : ESex) => {
        childProfile.setSex(sex);
        props.forceSelectAvatar();
    };
    
    return (
        <Container>
            <Button 
                onClick={() => selectSex(ESex.BOY)}
                selected={childProfile.sex === ESex.BOY}
            >
                <ImageContainer selected={childProfile.sex === ESex.BOY}>
                    <Icon 
                        image={boy}
                        width={3}
                        height={3}
                        borderRadius={'50%'}                
                    />
                </ImageContainer>
                <Text marginLeft={"1rem"}>Garçon</Text>
                {childProfile.sex === ESex.BOY && 
                    <CheckedMark 
                        backgroundColor={Theme.colors.pinkStrong}
                        position='absolute'
                        width={2}
                        height={2}
                    >
                        <Icon 
                            image={check}
                            width={1.4}
                            height={1.4}
                            borderRadius={'50%'}
                        />
                    </CheckedMark>
                }
            </Button>
            <Button 
                onClick={() => selectSex(ESex.GIRL)}
                selected={childProfile.sex === ESex.GIRL}
            >
                <ImageContainer selected={childProfile.sex === ESex.GIRL}>
                    <Icon 
                        image={girl}
                        width={3}
                        height={3}
                        borderRadius={'50%'}                
                    />
                </ImageContainer>   
                <Text marginLeft={"1rem"}>Fille</Text>
                {childProfile.sex === ESex.GIRL && 
                    <CheckedMark 
                        backgroundColor={Theme.colors.pinkStrong}
                        position='absolute'
                        width={2}
                        height={2}
                    >                        
                        <Icon 
                            image={check}
                            width={1.4}
                            height={1.4}
                            borderRadius={'50%'}
                        />
                    </CheckedMark>
                }   
            </Button>
        </Container>
    );
  });
  
  export default SelectSex;

  