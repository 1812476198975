import * as React from 'react';
import styled from 'styled-components';
import { Icon } from './styled';
import defaultUser from './../../assets/icons/avatar/DefaultUser.png';
import { Theme } from '../../assets/Theme';

const AVATAR_WIDTH = 3.2;
const AVATAR_HEIGHT = 3.2;

interface IAvatarContainer {
    marginRight?: string,
    isWepoppins?: boolean,
}

const AvatarContainer = styled.div<IAvatarContainer>`
border-radius: 50%;
margin-right: ${props => props.marginRight || 0};
${({ isWepoppins }) => isWepoppins && `
    background-color: ${Theme.colors.pinkPastel};
`}
`;

type Props = {
    image: string | null;
    onClick: () => void;
    width?: number;
    height?: number;
    marginRight?: string;
    isWepoppins?: boolean;
}

const Avatar = (props: Props) => {

    const avatar: string = (props.image != null && props.image.length > 0) ? props.image : defaultUser

    return (
        <AvatarContainer 
            onClick={props.onClick}
            marginRight={props.marginRight}
            isWepoppins={props.isWepoppins}
        >
            <Icon
                image={avatar}
                width={props.width ? props.width : AVATAR_WIDTH}
                height={props.height ? props.height : AVATAR_HEIGHT}
                borderRadius='50%'
                backgroundCover
            />
        </AvatarContainer>
    )
}

export default Avatar;