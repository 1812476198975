import * as React from 'react';
import styled from 'styled-components';
import { Icon, P1, Text } from '../shared/styled';
import { IUser } from '../../store/user';
import Avatar from '../shared/Avatar';
import { Separator } from '../Transactions/TransactionCard';
import check from './../../assets/icons/parentCard/check-on.svg';
import isValidateIcon from './../../assets/icons/parentCard/check-green.svg';
import { ITransaction, ITransactionStatus } from '../../store/transaction';
import { Theme } from '../../assets/Theme';

const CONTAINER_HEIGHT = 3;

const PADDING = '0.5rem';

interface IContainer {
    alignEnd?: boolean;
}

const Container = styled.div<IContainer>`
display: flex;
align-items: center;
min-height: ${CONTAINER_HEIGHT}rem;
background-color: ${props => props.theme.colors.white};
padding: ${PADDING};
margin-bottom: 1rem;
border-radius: 0.8rem;
width: calc(100% - 2*${PADDING});
${({ alignEnd }) => alignEnd && `
    justify-content: space-between;
`}
`;

const ContactInfo = styled.div`
display: flex;
align-items: center;
`

interface Props {
    friend: IUser,
    action: () => void,
    isAvailable?: boolean, // True if the parent has answered "AVAILABLE" to the user PUBLIC demand
    isValidate?: boolean, // True if the user has selected this parent to make the gard
    transaction?: ITransaction | null,
};

const ContactCard: React.FC<Props> = (props: Props) => {

    const transationIsConfirmed: boolean = props.transaction?.status === ITransactionStatus.CONFIRMED;

    const getStatus = (): string => {
        let status = '';
        if (props.isAvailable != undefined)
        {
            status = props.isAvailable ? 'Dispo' : 'Pas dispo';
        }
        return status;
    };

    const getCardInfo = (): JSX.Element => {
        // CASE OF PUBLIC DEMAND CANDIDATE SELECTION
        if (props.isAvailable != undefined)
        {
            return (
                <Container alignEnd>
                    <ContactInfo>
                        <Avatar 
                            image={props.friend.avatar} 
                            onClick={() => null}
                            width={2.8}
                            height={2.8}
                        />
                        <P1 marginLeft='1.5rem'>
                            {props.friend?.firstname} {props.friend?.lastname}
                        </P1>
                        <Separator />
                        <Text size={Theme.fontSizes.small}>{ getStatus() }</Text>
                    </ContactInfo>
                    {(props.isAvailable && !transationIsConfirmed ) &&
                        <Icon 
                            image={check} 
                            width={2.5} 
                            height={2.5}
                            onClick={props.action}
                        />
                    }
                    {props.isValidate &&
                        <Icon 
                            image={isValidateIcon} 
                            width={2.5} 
                            height={2.5}
                        />
                    }
                </Container>
            );
        }
        // CASE OF CONTACTS FROM MESSAGERIE    
        else
        {
            return (
                <Container onClick={props.action}>
                    <Avatar 
                        image={props.friend.avatar} 
                        onClick={() => null}
                        width={2.8}
                        height={2.8}
                    />
                    <P1 marginLeft='1.5rem'>
                        {props.friend?.firstname} {props.friend?.lastname}
                    </P1>
                </Container>
            );
        };
    };

    return (
        <>
            {getCardInfo()}
        </>
    )
}

export default ContactCard;
