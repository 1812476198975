import { types, flow, cast } from "mobx-state-tree";
import { api } from "../api/api";
import { ICity, IUser } from "./user";
import oneStar from './../assets/icons/profile/oneStar.svg';
import twoStars from './../assets/icons/profile/twoStars.svg';
import threeStars from './../assets/icons/profile/threeStars.svg';
import bronzeMedal from './../assets/icons/profile/bronzeMedal.svg';
import silverMedal from './../assets/icons/profile/silverMedal.svg';
import goldMedal from './../assets/icons/profile/goldMedal.svg';
import coupe from './../assets/icons/profile/coupe.svg';
import { StoreAlert } from "./alertStore";
import { EToastStatus } from "../components/shared/Information/Toast";

const { REACT_APP_API_URL } = process.env;

export interface INetwork {
    id: number | null,
    status: string | null,
    senderUserId: number | null,
    invitedUserId: number | null
}

export const NetworkStore = types
.model({
    city: types.maybeNull(types.frozen<ICity>()),
    parents: types.maybeNull(types.frozen<IUser[]>()),
    friends: types.maybeNull(types.frozen<IUser[]>()),
    pendingRelationships: types.maybeNull(types.frozen<INetwork[]>()),
    fetchHasMoreParents: types.boolean,
    fetchHasMoreFriends: types.boolean,
    hasUnderstoodWarningBeforeInviting: types.boolean,
    hasUnderstoodWarningBeforeAccepting: types.boolean,
})
.actions(self => ({
    setParents(data : IUser[] | null) {
        self.parents = data;
    },
    setFriends(data : IUser[] | null) {
        if (data != null)
        {
            self.friends = data;
        }
    },
    setCity(city: ICity | null) {
        if (city != null)
        {
            self.city = city;
        };
    },
    setHasUnderstoodWarningBeforeInviting(status: boolean) {
        self.hasUnderstoodWarningBeforeInviting = status;
    },
    setHasUnderstoodWarningBeforeAccepting(status: boolean) {
        self.hasUnderstoodWarningBeforeAccepting = status;
    },
    getParents : flow(function* (offset: number) {
        const listToUpdate = self.parents != null ? [...self.parents] : [];
        let agreggateList: IUser[] = [];
        let hasMore = false;

        yield api
        .get(`${REACT_APP_API_URL}/api/network/parents/?offset=${offset}`)
        .then(resp => {
            const updatedList: IUser[] = resp.data;
            agreggateList  = [...listToUpdate, ...updatedList];
            hasMore = updatedList.length > 0;                
        })
        .catch(err => console.log(err));
       
        self.parents = cast(agreggateList);
        self.fetchHasMoreParents = hasMore;
    }),

    findParents : flow(function* (parentName: string, offset: number) {
        const listToUpdate = self.parents != null ? [...self.parents] : [];
        let agreggateList: IUser[] = [];
        let hasMore = false;

        yield api
        .get(`${REACT_APP_API_URL}/api/network/parents/find/${parentName}?offset=${offset}`)
        .then(resp => {
            const updatedList: IUser[] = resp.data;
            agreggateList  = [...listToUpdate, ...updatedList];
            hasMore = updatedList.length > 0;                
        })
        .catch(err => console.log(err));
    
        self.parents = cast(agreggateList);
        self.fetchHasMoreParents = hasMore;
    }),

    addParent : flow(function* (parent: IUser) {
        const updatedParentList = self.parents != null ? [...self.parents] : [];

            yield api
            .post(`${REACT_APP_API_URL}/api/network`, { contactedParentId : parent.id} )
            .then(resp => {
                const updatedUser: IUser = resp.data[0];
                const indexOfUpdatedUserRelation =  updatedParentList.findIndex(user => user.id === updatedUser.id);
                updatedParentList[indexOfUpdatedUserRelation] = updatedUser;
            })
            .catch(err => console.log(err));
       
        self.parents = updatedParentList;
    }),

    acceptRelationship : flow(function* (parent: IUser) {
        const updatedParentList = self.parents != null ? [...self.parents] : [];
        const indexOfTargetedParent =  updatedParentList.findIndex(user => user.id === parent.id);

        let isAccepted = false;

            yield api
            .put(`${REACT_APP_API_URL}/api/network/${parent.network?.id}`)
            .then(resp => {
                if (resp.status === 200)
                {
                    updatedParentList.splice(indexOfTargetedParent, 1);
                    isAccepted = true;
                }
            })
            .catch(err => console.log(err));
       
        self.parents = updatedParentList;
        return isAccepted;
    }),

    refuseRelationship : flow(function* (parent: IUser) {
        const updatedParentList = self.parents != null ? [...self.parents] : [];
        const indexOfTargetedParent =  updatedParentList.findIndex(user => user.id === parent.id);

        let deleted = false;

            yield api
            .delete(`${REACT_APP_API_URL}/api/network/${parent.network?.id}`)
            .then(resp => {
                if (resp.status === 200)
                {
                    StoreAlert.alert.setAlert(EToastStatus.SUCCESS, `Ce parent ne verra plus votre profil dans le réseau de parents`, null);
                    updatedParentList.splice(indexOfTargetedParent, 1);
                    deleted = true;
                }
            })
            .catch(err => StoreAlert.alert.setAlert(EToastStatus.FAIL, `Une erreur s'est produite`, null));

        self.parents = updatedParentList;
        return deleted;
    }),

    getFriends : flow(function* (offset: number) {
        const listToUpdate = self.friends != null ? [...self.friends] : [];
        let agreggateList: IUser[] = [];
        let hasMore = false;

        yield api
        .get(`${REACT_APP_API_URL}/api/network/friends/?offset=${offset}`)
        .then(resp => {
            const updatedList: IUser[] = resp.data;
            agreggateList  = [...listToUpdate, ...updatedList];
            hasMore = updatedList.length > 0;                
        })
        .catch(err => console.log(err));
            
        self.friends = cast(agreggateList);
        self.fetchHasMoreFriends = hasMore;        
    }),

    getAllFriends : flow(function* () {
        let friendsList: IUser[] = [];

            yield api
            .get(`${REACT_APP_API_URL}/api/network/all/friends/`)
            .then(resp => friendsList = resp.data)
            .catch(err => console.log(err));
            
        self.friends = cast(friendsList);
    }),

    getPendingRelationships : flow(function* () {
        let pendingRelationships: INetwork[] = [];

            yield api
            .get(`${REACT_APP_API_URL}/api/network/relations/pending`)
            .then(resp => pendingRelationships = resp.data)
            .catch(err => console.log(err));
            
        self.pendingRelationships = cast(pendingRelationships);
    }),
}))
.views(self => ({
    // THE USER HAS INVITED A PARENT TO BE IN TOUCH WITH HIM
    isInvitedByUser (parentToCheck: IUser): boolean {
        let isInvited = false;
        if (parentToCheck != null && self.parents != null)
        {
            isInvited = self.parents.some(parent => parent.id === parentToCheck.network?.invitedUserId && parent.network?.status === 'waiting');
        }
        return isInvited;
    },
    // THE USER HAS BEEN INVITED TO BE IN TOUCH WITH ANOTHER PARENT
    userIsInvited (parentToCheck: IUser, userId: number | undefined): boolean {
        let isInvited = false;
        if (parentToCheck != null && userId != undefined )
        {
            isInvited = parentToCheck.network?.invitedUserId === userId 
                        && parentToCheck.network?.status === 'waiting';
        }
        return isInvited;
    },
    // GET CURRENT PARENT AWARD BADGE
    getParentAward(parentToCheck: IUser): string {
        let award = '';
        const parentNumberOfGardsDone = parentToCheck.numberOfGardsDone;

        if (parentNumberOfGardsDone === 1)
        {
            award = oneStar;
        };
        if (parentNumberOfGardsDone === 2)
        {
            award = twoStars;
        };
        if (parentNumberOfGardsDone === 3)
        {
            award = threeStars;
        };
        if (parentNumberOfGardsDone > 3 && parentNumberOfGardsDone < 8)
        {
            award = bronzeMedal;
        };
        if (parentNumberOfGardsDone >= 8 && parentNumberOfGardsDone < 13)
        {
            award = silverMedal;
        };
        if (parentNumberOfGardsDone >= 13 && parentNumberOfGardsDone < 20)
        {
            award = goldMedal;
        };
        if (parentNumberOfGardsDone >= 20)
        {
            award = coupe;
        };
        return award
    }
}))
;


