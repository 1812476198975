export enum ERoutes {
    Landing = '/landing',
    Login = '/',
    DownloadApp = '/download/redirect',
    Contact = '/contact',
    Register = '/register',
    Cities = '/cities',
    RecheckIdentity = '/identity/recheck/:accountId',
    Confirmation = '/confirmation/:confirmationCode',
    ConfirmTransactionDone = '/done/transaction/:userId/:transacId/:parentId/:responseCode',
    ForgotPassword = '/forgot-password',
    NewPassword = '/newPassword/:accessToken',
    Home = '/home',
    About = '/about',
    Sponsorize = '/sponsorize',
    TransactionDetail = '/home/demand',
    Calendar = '/calendar',
    SelectCandidat = '/home/candidates',
    ChildDetail = '/child',
    Search = '/search',
    Network='/network',
    MyProfile = '/my-profile',
    MyChildren = '/my-children',
    Messages = '/messages',
    Friends = '/friends',
    CreateMessage = '/messages/create',
    Conversation='/conversation',
    Social = '/social',
    Comments = '/social/comments',
    CreateComment = '/social/comments/create',
    UpdateComment = '/social/comments/update',
    CreatePost = '/social/post/create',
    UpdatePost = '/social/post/update',
}