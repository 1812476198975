import React from 'react';
import { observer } from 'mobx-react';
import { IChild } from '../../store/child';
import { DataStoreContext } from '../../store/rootStore';
import { useNavigate } from 'react-router-dom';
import { AvatarContainer, AVATAR_HEIGHT, AVATAR_WIDTH, Container, Icon, ProfileContainer, ProfileInfo, Text } from '../shared/styled';
import Header from '../shared/Navigation/Header';
import { Item } from '../Transactions/TransactionCard';
import { getAge } from '../utils/getAge';
import profil from './../../assets/icons/profile/profil.svg';
import age from './../../assets/icons/profile/age.svg';
import section from './../../assets/icons/profile/childSection.svg';
import { AvatarContainerProfile, LineSeparator } from '../Parent/ParentProfile';

interface Props {
    child: IChild | null,
};

const ChildProfile: React.FC<Props> = observer((props: Props) => {

    const { childProfile } = React.useContext(DataStoreContext);

    let navigate = useNavigate();

    const avatar = childProfile.getChildAvatar(props.child);

    return (
        <Container>
            <Header 
                title={`Profil de ${props.child?.firstname}`} 
                action={() => navigate(-1)}
                noIcon
                noMargin
            />
            { props.child == null ?
                <Text>Aucun profil d'enfant n'a été trouvé</Text>
            :
                <ProfileContainer>
                    <AvatarContainerProfile>
                        <Icon
                            image={avatar} 
                            width={AVATAR_WIDTH} 
                            height={AVATAR_HEIGHT}
                            borderRadius={'50%'}
                            avatarProfile={true}
                        />
                    </AvatarContainerProfile>
                    <ProfileInfo>
                        <Item noMarginLeft marginTop={AVATAR_HEIGHT/2 + 1}>
                            <Icon 
                                image={profil} 
                                width={2.5} 
                                height={2.5}
                            />
                            <Text marginLeft={'1rem'}>{props.child.firstname}</Text>
                        </Item>
                        <Item noMarginLeft>
                            <Icon 
                                image={age} 
                                width={2.5} 
                                height={2.5}
                            />
                            <Text marginLeft={'1rem'}>{getAge(props.child.birthdate)} ans</Text>
                        </Item>
                        <Item noMarginLeft>
                            <Icon 
                                image={section} 
                                width={2.5} 
                                height={2.5}
                            />
                            <Text marginLeft={'1rem'}>{props.child.section}</Text>
                        </Item>
                        <LineSeparator />
                        <Item noMarginLeft>
                            <Text marginLeft={'1rem'} marginBottom={'1.5rem'}>
                                {props.child.biography}
                            </Text>
                        </Item>
                    </ProfileInfo>
                </ProfileContainer>                        
            }
        </Container>
    )
});

export default ChildProfile