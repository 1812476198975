import * as React from 'react';
import styled from 'styled-components';
import { Text } from '../styled';
import { IDay } from '../../../store/profile';
import { Theme } from '../../../assets/Theme';

const THUMB_WIDTH = '1.8rem';
const THUMB_HEIGHT = '1.8rem';

interface IContainer {
    marginBottom?: number,
}

const Container = styled.div<IContainer>`
display: flex;
width: 100%;
margin-bottom: ${props => props.marginBottom ? props.marginBottom + 'rem' : 0};
`;

const TextContainer = styled.div`
display: flex;
align-items: center;
min-width: 10rem;
`;

const CheckBoxWrapper = styled.div`
display: flex;
align-items: center;
position: relative;
`;

const CheckBoxLabel = styled.label`
position: absolute;
top: 0;
left: 0;
width: 4.2rem;
height: 2.6rem;
border-radius: 1.5rem;
background: ${Theme.colors.greyBorder};
cursor: pointer;
&::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: ${THUMB_WIDTH};
    height: ${THUMB_HEIGHT};
    margin: 0.3rem;
    background: #ffffff;
    box-shadow: 0.1rem 0.3rem 0.3rem 0.1rem rgba(0, 0, 0, 0.2);
    transition: 0.2s;
}
`;

const CheckBox = styled.input`
opacity: 0;
z-index: 1;
border-radius: 1.5rem;
width: 4.2rem;
height: 2.6rem;
margin: 0;
margin-left: 0.5rem;
&:checked + ${CheckBoxLabel} {
    background: ${Theme.colors.pinkStrong};
    &::after {
        content: "";
        display: block;
        border-radius: 50%;
        width: ${THUMB_WIDTH};
        height: ${THUMB_HEIGHT};
        margin-left: 2.1rem;
        transition: 0.2s;
    }
  }
`;

interface DaySlidingButton {
    value: IDay
    label : string;
    isSelected : boolean;
    onSelect: (day: IDay) => void
}

interface NetworkSlidingButton {
    label : string;
    isSelected : boolean;
    onSelect: () => void;
    marginBottom?: number;
};

interface ValidAgreementSlidingButton {
    label : string;
    isSelected : boolean;
    onSelect: () => void;
    marginBottom?: number;
    openAgreement: () => void;
};

export const ToggleButton : React.FC<DaySlidingButton> = ({ value, label, isSelected, onSelect }) => {
    return (
        <Container>
            <TextContainer>
                <Text>{label}</Text>
            </TextContainer>
            <CheckBoxWrapper onClick={() => onSelect(value)}>
                <CheckBox 
                    type="checkbox"
                    defaultChecked={isSelected}
                />
                <CheckBoxLabel htmlFor="checkbox" />
            </CheckBoxWrapper>
        </Container>
    )
};

export const NetworkToggleButton : React.FC<NetworkSlidingButton> = ({ label, isSelected, onSelect, marginBottom }) => {

    return (
        <Container marginBottom={marginBottom}>
            <CheckBoxWrapper onClick={onSelect}>
                <CheckBox 
                    type="checkbox"
                    defaultChecked={isSelected}
                />
                <CheckBoxLabel htmlFor="checkbox" />
            </CheckBoxWrapper>
            <TextContainer>
                <Text marginLeft='0.5rem'>{label}</Text>
            </TextContainer>
        </Container>
    )
};

export const ValidAgreementToggleButton : React.FC<ValidAgreementSlidingButton> = ({ label, isSelected, onSelect, marginBottom, openAgreement }) => {

    return (
        <Container marginBottom={marginBottom}>
            <CheckBoxWrapper onClick={onSelect}>
                <CheckBox 
                    type="checkbox"
                    defaultChecked={isSelected}
                />
                <CheckBoxLabel htmlFor="checkbox" />
            </CheckBoxWrapper>
            <TextContainer onClick={openAgreement}>
                <Text marginLeft='0.5rem' align='left'>{label}</Text>
            </TextContainer>
        </Container>
    )
};
