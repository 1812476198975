import * as React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { ProfileContainer, Profile, ProfileInfo, Icon, Form, FormItem, Input, Label, Text, TextArea, AVATAR_HEIGHT, AVATAR_WIDTH, DatePickerWrapperStyles, Title, H4, H3 } from '../shared/styled';
import { AvatarContainerProfile } from './ParentProfile';
import { DataStoreContext } from '../../store/rootStore';
import { AlertStoreContext } from '../../store/alertStore';
import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import fr from 'date-fns/locale/fr';
import calendar from './../../assets/icons/profile/calendar.svg';
import SelectAvailabilities from '../shared/Select/SelectAvailabilities';
import CityInput from '../shared/Input/CityInput';
import UploadImages from '../shared/Pictures/UploadImages';
import { api } from './../../api/api';
import { AxiosError } from 'axios';
import { EToastStatus } from '../shared/Information/Toast';
import defaultUser from './../../assets/icons/avatar/DefaultUser.png';
import { AppStoreContext } from '../../store/appStore';
import { AvatarLabelContainer } from '../Connexion/Register/RegisterStep2';
import ValidityIndicator from '../shared/Input/ValidityIndicator';
import TextInput, { LabelContainer } from '../shared/Input/TextInput';
import { phoneRegex } from '../utils/regex';
import TextAreaComponent from '../shared/Input/TextAreaComponent';
registerLocale("fr", fr);

const { REACT_APP_API_URL } = process.env;

const MARGIN_TOP = AVATAR_HEIGHT / 2;

const Availabilities = styled.div`
margin-top: 1.5rem;
display: flex;
flex-direction: column;
background-color: ${props => props.theme.colors.white};
border-radius: 1rem;
width: 100%;
`;

const Item = styled.div`
display: flex;
align-items: center;
padding: 1rem;
`;

const EditParentProfile : React.FC = observer(() => {

    const { register : user } = React.useContext(DataStoreContext);
    const { alert } = React.useContext(AlertStoreContext);
    const { app } = React.useContext(AppStoreContext)

    const avatar: string = user.avatar != null ? user.avatar : defaultUser;
    const userPhone: string = user.phone ? user.phone : '';
    const phoneIsValid: boolean = userPhone.match(phoneRegex) != null ? true : false;

    const selectPicture = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files != null)
        {
            const selectedFile: File = e.target.files[0];
            let data: FormData = new FormData();
            data.append("file", selectedFile);
            app.setLoading(true);
            try
            {
                const response = await api.post(`${REACT_APP_API_URL}/api/users/my-profile/upload`, data);
                if (response.status === 200)
                {
                    user.setAvatar(response.data);
                };
            } 
            catch (error) {
                const err = error as AxiosError;
                if (err.response) 
                {
                    alert.setAlert(EToastStatus.FAIL, `Une erreur s'est produite :`, err.response.data.errors);
                };
            };
            app.setLoading(false);
        };
    };
        
    return (
        <ProfileContainer>
            <Profile>
                <AvatarLabelContainer>
                    <Label align='center'>
                        Votre photo de profil*
                    </Label>
                    <ValidityIndicator isValid={avatar != null} marginLeft={1} />
                </AvatarLabelContainer>
                <AvatarContainerProfile>
                    <Icon 
                        image={avatar} 
                        width={AVATAR_WIDTH} 
                        height={AVATAR_HEIGHT}
                        borderRadius={'50%'}
                        avatarProfile={true}
                    />
                    <UploadImages 
                        forProfile
                        action={selectPicture}
                        multiple={false}
                    />
                </AvatarContainerProfile>
                <ProfileInfo>
                    <Form>
                        <FormItem 
                            padding={1} 
                            marginTop={MARGIN_TOP}
                            marginBottom={2}
                        >
                            <H3 marginTop={'3rem'}>
                                {user.firstname} {user.lastname}
                            </H3>
                        </FormItem>
                        <FormItem padding={1} marginBottom={2}>
                            <LabelContainer>
                                <Label>
                                    Date de naissance*
                                </Label>
                                <ValidityIndicator isValid={user.birthdate != null} />
                            </LabelContainer>
                            <DatePicker
                                selected={user.birthdate}
                                onChange={(date: Date) => user.setBirthdate(date)}
                                wrapperClassName='date_picker full-width'
                                locale={"fr"}
                                dateFormat="P"
                                openToDate={new Date(1990, 0, 1)}
                                showYearDropdown
                                dropdownMode="select"
                                dateFormatCalendar="MMMM"
                            />
                            <DatePickerWrapperStyles />
                        </FormItem>
                        <TextInput
                            id='phone'
                            type='text'
                            label='Téléphone*'
                            placeholder="Votre numéro de téléphone portable"
                            value={user.phone || ''}
                            onChange={e => user.setPhone(e.target.value)}
                            validityIndicator
                            isValid={phoneIsValid}
                            marginBottom={2}
                            padding={1}
                        />
                        <TextInput
                            id='address1'
                            type='text'
                            label='Votre adresse*'
                            placeholder="Tapez votre addresse"
                            value={user.address1 || ''}
                            onChange={e => user.setAddress1(e.target.value)}
                            validityIndicator
                            isValid={user.address1 != ''}
                            marginBottom={2}
                            padding={1}
                        />
                        <TextInput
                            id='address2'
                            type='text'
                            label='Adresse (complément)'
                            placeholder="Complément d'adresse si nécessaire"
                            value={user.address2 || ''}
                            onChange={e => user.setAddress2(e.target.value)}
                            marginBottom={2}
                            padding={1}
                        />
                        <FormItem marginBottom={2} padding={1} >
                            <LabelContainer>
                                <Label>
                                    Ville*
                                </Label>
                                <ValidityIndicator isValid={user.city != null} />
                            </LabelContainer>
                            <CityInput city={user.city} />
                        </FormItem>
                        <TextAreaComponent 
                            id='biography'
                            label='Quelques mots sur vous*'
                            placeholder="Quelques mots sur vous"
                            value={user.biography}
                            onChange={e => user.setBiography(e.target.value)}
                            validityIndicator
                            isValid={user.biography.length > 0}
                            marginBottom={2}  
                            padding={1}              
                        />
                    </Form>
                </ProfileInfo>
                <Availabilities>
                    <Item>
                        <Icon 
                            image={calendar} 
                            width={3} 
                            height={3}
                            />
                        <LabelContainer>
                            <Text marginLeft={'1rem'}>Mes dispos pour garder vos enfants</Text>
                            <ValidityIndicator isValid={user.availabilities.length > 0} />
                        </LabelContainer>
                    </Item>
                    <SelectAvailabilities />
                </Availabilities>
            </Profile>
        </ProfileContainer>
    )
});

export default EditParentProfile;
