import * as React from 'react';
import Header, { PADDING } from '../shared/Navigation/Header';
import Loader from '../shared/Loader';
import { IUser } from '../../store/user';
import { Button, Container, Icon, Text } from '../shared/styled';
import { ETransactionNeed, IResponse, ITransaction, ITransactionStatus } from '../../store/transaction';
import { ERoutes } from '../shared/Navigation/ERoutes';
import ParentProfile, { Availabilities as DemandContainer } from '../Parent/ParentProfile';
import informationIcon from './../../assets/icons/profile/information.svg';
import { Item, Status } from './TransactionCard';
import { Theme } from '../../assets/Theme';
import { INeedOptions, needOptions } from '../shared/Input/InputSelect';
import styled from 'styled-components';
import time from '../../assets/icons/transaction/time.svg';
import message from '../../assets/icons/transaction/message.svg';
import response from '../../assets/icons/transaction/reply.svg';
import localisation from '../../assets/icons/transaction/localisation.svg';
import userInfo from '../../assets/icons/transaction/user-info.svg';
import phoneCallIcon from '../../assets/icons/social/phone-call.svg';
import messageReply from '../../assets/icons/transaction/message-reply.svg';
import { dateToString, transformDateToString } from '../utils/dateManager';
import { Modal } from '../shared/Information/Modal';
import ManageTransaction, { EManageStatus } from '../Transactions/ManageTransaction';
import { useNavigate } from 'react-router-dom';
import { DataStoreContext } from '../../store/rootStore';
import trash from '../../assets/icons/parentCard/trash.svg';
import { observer } from 'mobx-react';

const MARGIN_BOTTOM = 1;

const DemandDetails = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
padding-left: ${PADDING};
padding-right: ${PADDING};
`;

const Need = styled.div`
display: flex;
align-items: center;
margin-bottom: ${MARGIN_BOTTOM}rem;
`;

const Buttons = styled.div`
display: flex;
justify-content: space-around;
margin-top: 2rem;
width: 100%;
`;

interface Props {
    user: IUser | null,
    transac: ITransaction | null
    previousRoute?: ERoutes
}

const TransactionDetail: React.FC<Props> = observer((props : Props) => {

    const { userLogged, transactions } = React.useContext(DataStoreContext);

    let navigate = useNavigate();

    const [showDeclineModal, setShowDeclineModal] = React.useState<boolean>(false);
    const [showAcceptModal, setShowAcceptModal] = React.useState<boolean>(false);

    const infoStatus: string = transactions.getTransactionStatus(props.transac);

    const openDeclineModal = () => setShowDeclineModal(true);

    const openAcceptModal = () => setShowAcceptModal(true);

    const backHome = () => navigate(ERoutes.Home);
    
    const isMyDemand: boolean = props.transac?.sender?.id === userLogged.user?.id;
    const transacIsFinished: boolean = props.transac != null && new Date(props.transac?.start) < new Date();

    const senderMessage: string = props.transac?.message != null && props.transac.message.length > 0 ? props.transac.message : `Aucun message particulier n'a été laissé`;

    const hasAnswered: boolean = props.transac?.responses != null ? props.transac.responses?.some(resp => resp.userId === userLogged.user?.id && resp.parentIsAvailable != null) : false;
    const parentResponse: IResponse | null =  props.transac?.responses != null ? props.transac.responses.filter(resp => resp.parentIsValidate)[0] : null;
    const parentResponseMessage: string | null = parentResponse != null ? parentResponse.message : `Aucun message n'a été laissé`;
    const hasMessage: boolean = parentResponse?.message != null && parentResponse.message.length > 0;

    const titleDemandHours: string = props.transac?.need === ETransactionNeed.GARD ? 
                                    `Le ${dateToString(props.transac?.start)} de ${transformDateToString(props.transac?.start)} à ${transformDateToString(props.transac?.end)}` 
                                   : `Le ${dateToString(props.transac?.start)} à ${transformDateToString(props.transac?.start)}`;

    const displayTransactionNeed = (): JSX.Element => {
        if (props.transac != null)
        {
            const need: INeedOptions = needOptions.filter(option => option.id === props.transac?.need)[0];
            return (
                <Need>
                    <Icon 
                        image={need.icon} 
                        width={2.5} 
                        height={2.5}                          
                    />
                    <Text 
                        align='left'
                        marginLeft='0.5rem'
                    >
                        {need.title}
                    </Text>
                </Need>
            );    
        }
        else 
        {
            return <></>;
        }
    };

    return (
        <>
            {showDeclineModal && props.transac != null &&
                <Modal 
                    title={isMyDemand ? 'Annuler ma demande' : 'Je ne suis plus disponible'}
                    visible={showDeclineModal}
                    close={() => setShowDeclineModal(false)}
                    children={
                        <ManageTransaction
                            manageStatus={isMyDemand ? EManageStatus.CANCEL : EManageStatus.REFUSE} 
                            contactedParentId={props.transac.sender?.id}
                            closeModal={() => setShowDeclineModal(false)}
                            sendTo={props.transac.sender.firstname}
                            action={backHome}
                            transac={props.transac}
                        />
                    }
                />
            }
            {showAcceptModal && props.transac != null &&
                <Modal 
                    title='Je suis disponible'
                    visible={showAcceptModal}
                    close={() => setShowAcceptModal(false)}
                    children={
                        <ManageTransaction
                            manageStatus={EManageStatus.ACCEPT} 
                            contactedParentId={props.transac.sender?.id}
                            closeModal={() => setShowAcceptModal(false)}
                            sendTo={props.transac.sender.firstname}
                            action={backHome}
                            transac={props.transac}
                        />
                    }
                />
            }

            {props.user != null && props.transac != null ?         
                <Container>
                    <Header 
                        title={'Détail de la demande'}
                        url={props.previousRoute ? props.previousRoute : ERoutes.Home}
                        icon={props.transac.status !== ITransactionStatus.CANCELED && !transacIsFinished ?
                            <Icon 
                                image={trash} 
                                width={2} 
                                height={2}
                                onClick={openDeclineModal}
                            />
                            : null
                        }
                        noIcon={props.transac.status === ITransactionStatus.CANCELED}
                    />
                    <ParentProfile user={props.user} hideAvailabilities />
                    <DemandContainer>
                        <Item padding={1} noMarginLeft>
                            <Icon 
                                image={informationIcon} 
                                width={2.5} 
                                height={2.5}
                            />
                            <Text 
                                color={Theme.colors.pinkStrong} 
                                marginLeft={'1rem'}
                                marginRight={'2rem'}
                                align='left'
                                bold
                            >
                                Informations sur la demande
                            </Text>
                        </Item>
                        <DemandDetails>
                            { displayTransactionNeed() }
                            <Item 
                                noMarginLeft 
                                align='flex-start'
                                marginBottom={MARGIN_BOTTOM}
                            >
                                <Icon 
                                    image={time} 
                                    width={2.5} 
                                    height={2.5}
                                />
                                <Text 
                                    align='left'
                                    marginLeft={'0.5rem'}
                                >
                                    {titleDemandHours}
                                </Text>
                            </Item>
                            <Item 
                                noMarginLeft 
                                align='flex-start'
                            >
                                <Icon 
                                    image={message} 
                                    width={2.5} 
                                    height={2.5}
                                />
                                <Text 
                                    align='left'
                                    marginLeft={'0.5rem'}
                                >
                                    {senderMessage}
                                </Text>
                            </Item>
                        </DemandDetails>
                    </DemandContainer>

                    <DemandContainer>
                        <Item padding={1} noMarginLeft>
                            <Icon 
                                image={userInfo} 
                                width={2.5} 
                                height={2.5}
                            />
                            <Text 
                                color={Theme.colors.pinkStrong} 
                                marginLeft={'1rem'}
                                marginRight={'2rem'}
                                align='left'
                                bold
                            >
                                Coordonnées de {props.user?.firstname}
                            </Text>
                        </Item>
                        <DemandDetails>
                            <Item 
                                noMarginLeft 
                                align='flex-start'
                            >
                                <Icon 
                                    image={phoneCallIcon} 
                                    width={2.2} 
                                    height={2.2}
                                />
                                <Text 
                                    align='left'
                                    marginLeft={'0.5rem'}
                                >
                                    { props.user?.phone }
                                </Text>
                            </Item>
                            <Item 
                                noMarginLeft 
                                align='flex-start'
                            >
                                <Icon 
                                    image={localisation} 
                                    width={2.5} 
                                    height={2.5}
                                />
                                <div>
                                    <Text align='left' marginLeft={'0.5rem'}>
                                        { props.user?.address1 }
                                    </Text>
                                    <Text align='left' marginLeft={'0.5rem'}>
                                        { props.user?.address2 }
                                    </Text>
                                    <Text align='left' marginLeft={'0.5rem'}>
                                        { props.user?.city?.city_postal_code } { props.user?.city?.city_realname }
                                    </Text>
                                </div>
                            </Item>
                        </DemandDetails>
                    </DemandContainer>


                    <DemandContainer>
                        <Item padding={1} noMarginLeft>
                            <Icon 
                                image={response} 
                                width={2.5} 
                                height={2.5}
                            />
                            <Text 
                                color={Theme.colors.pinkStrong} 
                                marginLeft={'1rem'}
                                marginRight={'2rem'}
                                bold
                                align='left'
                            >
                                Réponse à la demande
                            </Text>
                            <Status color={transactions.getTransactionStatusColor(props.transac)}>
                                {infoStatus}
                            </Status>
                        </Item>
                        { hasMessage &&
                            <DemandDetails>
                                <Item 
                                    noMarginLeft 
                                    align='flex-start'
                                >
                                    <Icon 
                                        image={messageReply} 
                                        width={2.5} 
                                        height={2.5}
                                    />
                                    <Text 
                                        align='left'
                                        marginLeft={'0.5rem'}
                                    >
                                        {parentResponseMessage}
                                    </Text>
                                </Item>
                            </DemandDetails>
                        }
                    </DemandContainer> 
                    { !hasAnswered && props.transac?.sender?.id !== userLogged.user?.id && !transacIsFinished &&
                        <Buttons>
                            <Button 
                                border
                                borderColor={Theme.colors.violet}
                                color={Theme.colors.violet}
                                backgroundColor='transparent'
                                width={14}
                                marginTop={0.1}
                                onClick={openDeclineModal}
                            >
                                Indisponible
                            </Button>
                            <Button 
                                width={14}
                                marginTop={0.1}
                                onClick={openAcceptModal}
                            >
                                Disponible
                            </Button>
                        </Buttons>
                    }        
                </Container>
            : 
                <Loader />
            }
        </>

    )
});

export default TransactionDetail;
