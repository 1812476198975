import * as React from 'react';
import { DataStoreContext } from '../../../store/rootStore';
import { IComment } from '../../../store/social';
import { Modal } from '../../shared/Information/Modal';
import { Button } from '../../shared/styled';
import UpdateComment from './UpdateComment';
import { MenuContainer, MenuOption } from '../styled';
import { AppStoreContext } from '../../../store/appStore';
interface CommentMenuProps {
    currentComment: IComment;
    closeMenu: () => void;
    postId: number;
}

const CommentMenu: React.FC<CommentMenuProps> = (props: CommentMenuProps) => {

    const { social } = React.useContext(DataStoreContext);
    const { app } = React.useContext(AppStoreContext);

    const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false);
    const [showUpdateModal, setShowUpdateModal] = React.useState<boolean>(false);

    const ref = React.useRef<HTMLDivElement>(null);

    const selectComment = () => social.domain.setSelectedComment(props.currentComment);

    const handleDeleteModal = () => setShowDeleteModal(!showDeleteModal);
    
    const handleUpdateModal = () => {
        if (showUpdateModal)
        {
            props.closeMenu();
        }
        setShowUpdateModal(!showUpdateModal);
    };

    const deleteComment = async () => {
        if (social.domain.selectedComment != null)
        {
            app.setLoading(true);
            await social.domain.deleteComment(props.postId, props.currentComment.id);
            app.setLoading(false);
        };
        setShowDeleteModal(false);
    };

    const handleClickOutside = (event: any) => {
        if (ref.current && !ref.current.contains(event.target)) {
            props.closeMenu();
        }
    };

    React.useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    return (
        <div ref={ref} onClick={handleClickOutside}>
            { showDeleteModal &&
                <Modal
                    title='Confirmez-vous la suppression de ce commentaire ?'
                    visible={showDeleteModal}
                    close={handleDeleteModal}
                    width='80%'
                    children={
                        <Button onClick={deleteComment}>
                            Supprimer
                        </Button>
                    }
                />
            }
            { showUpdateModal &&
                <Modal
                    title='Modifier le commentaire'
                    visible={showUpdateModal}
                    close={handleUpdateModal}
                    width='80%'
                    children={
                        <UpdateComment 
                            comment={props.currentComment}
                            closeUpdateModal={handleUpdateModal}
                            postId={props.postId}
                        />
                    }
                />
            }
            <MenuContainer 
                onClick={selectComment}
            >
                <MenuOption onClick={handleUpdateModal}>
                    Modifier
                </MenuOption>
                <MenuOption onClick={handleDeleteModal}>
                    Supprimer
                </MenuOption>
            </MenuContainer>
        </div>
    )
};

export default CommentMenu;