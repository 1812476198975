import * as React from 'react';
import styled from 'styled-components';
import { P1, P2, Text } from '../shared/styled';
import { IConversation } from '../../store/message';
import { DataStoreContext } from '../../store/rootStore';
import defaultUser from './../../assets/icons/avatar/DefaultUser.png';
import { IUser } from '../../store/user';
import Avatar from '../shared/Avatar';
import wepoppinsFavicon from './../../assets/logo/favicon-couleur.svg';
import { Theme } from '../../assets/Theme';

const CONTAINER_HEIGHT = 5;

const Container = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
min-height: ${CONTAINER_HEIGHT}rem;
background-color: ${props => props.theme.colors.white};
padding: 0.5rem;
margin-bottom: 1rem;
border-radius: 0.8rem;
`;

const SubContainer = styled.div`
display: flex;
align-items: center;
width: 90%;
`;

const UserIdentity = styled.div`
margin-left: 1.5rem;
`;

const UnreadMessageBadge = styled.div`
width: 2.4rem;
height: 2.4rem;
background-color: ${Theme.colors.pinkStrong};
border-radius: 50%;
display: flex;
justify-content: center;
align-items: center;
`;

interface Props {
    conversation: IConversation | null,
    openConversation: () => void,
    fromWepoppins: boolean, // Only true when it concerns Public Demands => in this case, the conversation author is Wepoppins
    unreadMessage: number,
}

const ConversationCard: React.FC<Props> = (props: Props) => {

    const { message, userLogged: { user } } = React.useContext(DataStoreContext);

    const friend: IUser | undefined = message.getUserInTouch(props.conversation, user?.id);
    const friendAvatar: string = (friend != null && friend.avatar != null) ? friend.avatar : defaultUser;

    const lastMessage: string = message.getLastConversationMessage(props.conversation);

    const getSenderIdentity = (): JSX.Element => {
        if (props.fromWepoppins)
        {
            return (
                <UserIdentity onClick={props.openConversation}>
                    <P1>
                        Wepoppins
                    </P1>
                    <Text 
                        align='left'
                        limitTextHorizontal
                    >
                        {lastMessage}
                    </Text>
                </UserIdentity>
            )
        }
        else
        {
            return(
                <UserIdentity onClick={props.openConversation}>
                    <P1>
                        {friend?.firstname} {friend?.lastname}
                    </P1>
                    <Text 
                        align='left'
                        limitTextHorizontal
                    >
                        {lastMessage}
                    </Text>
                </UserIdentity>
            )
        };
    };

    return (
            <Container onClick={props.openConversation}>
                <SubContainer>
                    <Avatar 
                        image={props.fromWepoppins ? wepoppinsFavicon : friendAvatar} 
                        onClick={props.openConversation}
                        width={4.8}
                        height={4.8}
                        isWepoppins={props.fromWepoppins}
                    />
                    { getSenderIdentity() }
                </SubContainer>
                { props.unreadMessage > 0 &&
                    <UnreadMessageBadge>
                        <P2>{props.unreadMessage}</P2>
                    </UnreadMessageBadge>
                }
            </Container>
    )
}

export default ConversationCard;
