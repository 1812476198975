import styled from 'styled-components';
import { Theme } from '../../assets/Theme';

export const MenuContainer = styled.ul`
margin: 0;
padding: 0;
`;

export const MenuOption = styled.li`
font-size: ${Theme.fontSizes.normal};
width: 10rem;
list-style: none;
text-align: right;
background-color: transparent;
`;