import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Axios from 'axios';
import { ERoutes } from '../shared/Navigation/ERoutes';
import { DataStoreContext } from '../../store/rootStore';
import Header from '../shared/Navigation/Header';
import { EToastStatus } from '../shared/Information/Toast';
import { AlertStoreContext } from '../../store/alertStore';
import { Container } from '../shared/styled';
import { AppStoreContext } from '../../store/appStore';

const { REACT_APP_API_URL } = process.env;

const ConfirmationCode: React.FC = () => {

    const { alert } = React.useContext(AlertStoreContext);
    const { app } = React.useContext(AppStoreContext);

    let navigate = useNavigate();

    let { confirmationCode } = useParams();

    const verifyConfirmationCode = async () => {
        app.setLoading(true);
        try 
        {
            const response = await Axios.get(`${REACT_APP_API_URL}/api/auth/confirm/${confirmationCode}`);
            if (response.status === 200)
            {
                alert.setAlert(EToastStatus.SUCCESS, "Votre compte a bien été validé, vous pouvez maintenant vous connecter", null)
            };
        }
        catch (err)
        {
            alert.setAlert(EToastStatus.FAIL, "Une erreur s'est produite pendant la vérification de votre compte, veuillez réessayer à nouveau", null)
        }
        app.setLoading(false);
        navigate(ERoutes.Login);
    };

    React.useEffect(() => {
        if (confirmationCode != null)
        {
            verifyConfirmationCode();
        }
        else 
        {
            navigate(ERoutes.Login);
            alert.setAlert(EToastStatus.FAIL, "Le code d'activation n'est plus valide, veuillez refaire une demande", null)
        };
    }, [])

    return (
        <Container>
            <Header 
                title='Vérification en cours'
                noIcon
            />
        </Container>
  )
}

export default ConfirmationCode;