import * as React from 'react';
import Header from '../shared/Navigation/Header';
import ParentProfile from '../Parent/ParentProfile';
import Loader from '../shared/Loader';
import { IUser } from '../../store/user';
import { Container } from '../shared/styled';
import { ERoutes } from '../shared/Navigation/ERoutes';
interface Props {
    user: IUser | null,
    previousRoute: ERoutes
}

const Parent: React.FC<Props> = (props : Props) => {
    return (
        <>
            {props.user != null ?         
                <Container>
                    <Header 
                        title={`Profil de ${props.user.firstname}`}
                        url={props.previousRoute}
                    />
                    <ParentProfile user={props.user}/>
                </Container>
            : 
                <Loader />
            }
        </>

    )
};

export default Parent;
