import { types, flow } from "mobx-state-tree";
import { IUser } from "./user";
import { api } from './../api/api';
import { IChild } from "./child";

const { REACT_APP_API_URL } = process.env;

export const ParentStore = types
.model({
    selectedParent: types.maybeNull(types.frozen<IUser>()),
    selectedChild: types.maybeNull(types.frozen<IChild>())
})
.actions(self => ({
    setSelectParent(parent : IUser | null) {
        self.selectedParent = parent;
    },
    setSelectChild(child : IChild | null) {
        self.selectedChild = child;
    },
    getParentData : flow (function* (parentId: number) {
        try {
            const response = yield api.get(`${REACT_APP_API_URL}/api/search/${parentId}`);
            if (response)
            {
                self.selectedParent = response.data;
            };
        } catch (error) {
            console.log("error, cannot get parent data = ", error)
        }
    }),
}));


