import * as React from 'react';
import styled from 'styled-components';
import { IDay } from '../../store/profile';

interface PropsSelect {
    height?: number,
}

const Select = styled.select<PropsSelect>`
margin-right: 0.5rem;
height: ${props => props.height ? props.height + 'rem' : '2.6rem'};
`;

enum ETime {
    START,
    END
}
interface Props {
    handleTime: (e: React.ChangeEvent<HTMLSelectElement>, day: IDay, period: ETime) => void;
    day: IDay;
    period: ETime;
    height?: number;
    value?: string
};

const TimeOptions : React.FC<Props> = (props: Props) => {

    return (
        <Select 
            onChange={e => props.handleTime(e, props.day, props.period)}
            height={props.height}
            value={props.value}
        >
            <option>Heure :</option>
            <option>00:00</option>
            <option>00:15</option>
            <option>00:30</option>
            <option>00:45</option>
            <option>01:00</option>
            <option>01:15</option>
            <option>01:30</option>
            <option>01:45</option>
            <option>02:00</option>
            <option>02:15</option>
            <option>02:30</option>
            <option>02:45</option>
            <option>03:00</option>
            <option>03:15</option>
            <option>03:30</option>
            <option>03:45</option>
            <option>04:00</option>
            <option>04:15</option>
            <option>04:30</option>
            <option>04:45</option>
            <option>05:00</option>
            <option>05:15</option>
            <option>05:30</option>
            <option>05:45</option>
            <option>06:00</option>
            <option>06:15</option>
            <option>06:30</option>
            <option>06:45</option>
            <option>07:00</option>
            <option>07:15</option>
            <option>07:30</option>
            <option>07:45</option>
            <option>08:00</option>
            <option>08:15</option>
            <option>08:30</option>
            <option>08:45</option>
            <option>09:00</option>
            <option>09:15</option>
            <option>09:30</option>
            <option>09:45</option>
            <option>10:00</option>
            <option>10:15</option>
            <option>10:30</option>
            <option>10:45</option>
            <option>11:00</option>
            <option>11:15</option>
            <option>11:30</option>
            <option>11:45</option>
            {/* <option selected>---</option> */}
            <option>12:00</option>
            <option>12:15</option>
            <option>12:30</option>
            <option>12:45</option>
            <option>13:00</option>
            <option>13:15</option>
            <option>13:30</option>
            <option>13:45</option>
            <option>14:00</option>
            <option>14:15</option>
            <option>14:30</option>
            <option>14:45</option>
            <option>15:00</option>
            <option>15:15</option>
            <option>15:30</option>
            <option>15:45</option>
            <option>16:00</option>
            <option>16:15</option>
            <option>16:30</option>
            <option>16:45</option>
            <option>17:00</option>
            <option>17:15</option>
            <option>17:30</option>
            <option>17:45</option>
            <option>18:00</option>
            <option>18:15</option>
            <option>18:30</option>
            <option>18:45</option>
            <option>19:00</option>
            <option>19:15</option>
            <option>19:30</option>
            <option>19:45</option>
            <option>20:00</option>
            <option>20:15</option>
            <option>20:30</option>
            <option>20:45</option>
            <option>21:00</option>
            <option>21:15</option>
            <option>21:30</option>
            <option>21:45</option>
            <option>22:00</option>
            <option>22:15</option>
            <option>22:30</option>
            <option>22:45</option>
            <option>23:00</option>
            <option>23:15</option>
            <option>23:30</option>
            <option>23:45</option>
        </Select>   
        
    )
}

export default TimeOptions;