import * as React from 'react';
import styled from 'styled-components';
import { childrenAvatars } from '../../assets/icons/avatar/list';
import { Icon } from '../shared/styled';
import { Theme } from '../../assets/Theme';
import { CheckedMark } from './SelectSex';
import check from './../../assets/icons/parentCard/check-blanc.svg';
import { DataStoreContext } from '../../store/rootStore';
import { observer } from 'mobx-react';

const Container = styled.div`
display: flex;
align-items: center;
justify-content: center;
flex-wrap: wrap;
overflow-y: scroll;
height: 50vh;
width: 80vw;
`;

const Cell = styled.div`
display: flex;
align-items: center;
justify-content: center;
border: 0.1rem solid ${Theme.colors.greyLight};
border-radius: 0.5rem;
margin: 0.1rem;
position: relative;
:hover {
    background: ${Theme.colors.pinkStrong};
}
`;

const Image = styled.img`
height: 5.1rem;
`;

interface Props {
    action: () => void;
}

const SelectAvatar: React.FC<Props> = observer((props : Props) => {

    const { childProfile } = React.useContext(DataStoreContext);

    const selectAvatar = (avatar: string): void => {
        childProfile.setAvatar(avatar);
        props.action() // close Modal
    };

    const currentAvatar = childProfile.avatar;
        
    return (
        <Container>
            {childrenAvatars.map((item, index) => 
                <Cell key={index} onClick={() => selectAvatar(item)}>
                    <Image src={item}/>
                    { currentAvatar == item &&  
                        <CheckedMark
                            backgroundColor={Theme.colors.pinkStrong}
                            position='absolute'
                            width={2}
                            height={2}                    
                        >
                            <Icon 
                                image={check}
                                width={1.4}
                                height={1.4}
                                borderRadius={'50%'}
                            />
                        </CheckedMark>
                    }
                </Cell>
            )}
        </Container>
    );
});

export default SelectAvatar;
