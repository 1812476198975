import moment from "moment";


export const transformSlotInDateTime = (timeSlot : string, dayLabel: number) => {
    let hour = parseInt(timeSlot.slice(0,2));
    let minutes = parseInt(timeSlot.slice(3,5));
    let slotInDateFormat = moment().set({day : dayLabel, hour : hour, minute : minutes, second : 0});
    return slotInDateFormat;
};

export const transformDateInSlot = (date : Date): string => {
    const hours = date.getHours();
    const formatedHours = ("0" + hours).slice(-2);
    const minutes = date.getMinutes();
    const formatedMinutes = ("0" + minutes).slice(-2);
    const dateInSlotFormat = `${formatedHours}:${formatedMinutes}`;
    return dateInSlotFormat;
};

export const transformDateToString = (date : Date | undefined): string => {
    if (date != null)
    {
        const dateToUpdate = new Date(date);
        const hours = dateToUpdate.getHours();
        const formatedHours = ("0" + hours).slice(-2);
        const minutes = dateToUpdate.getMinutes();
        const formatedMinutes = ("0" + minutes).slice(-2);
        const dateInStringFormat = `${formatedHours}h${formatedMinutes}`;
        return dateInStringFormat;
    }
    else
    {
        return 'N/A'
    }
};

export const dateToString = (date : Date | undefined): string => {
    if (date != null)
    {
        const dt = moment(date, "YYYY-MM-DD HH:mm:ss");
        const fullDate = dt.format("dddd DD MMMM")
        return fullDate;
    }
    else
    {
        return '';
    };
};