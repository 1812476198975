import * as React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { Text, AvatarContainer, Icon } from '../shared/styled';
import edit from './../../assets/icons/profile/edit.svg';
import trash from './../../assets/icons/parentCard/trash.svg';
import { Separator } from './../Transactions/TransactionCard';
import { IChild } from '../../store/child';
import { getAge } from '../utils/getAge';
import { DataStoreContext } from '../../store/rootStore';

const CONTAINER_HEIGHT = 8;

const AVATAR_CHILD_WIDTH = 7;
const AVATAR_CHILD_HEIGHT = 7;

const Container = styled.div`
display: flex;
height: ${CONTAINER_HEIGHT}rem;
background-color: ${props => props.theme.colors.powderWhite};
margin-bottom: 1rem;
position: relative;
width: 100%;
`;

const Info = styled.div`
width: calc(100% - ${AVATAR_CHILD_WIDTH}rem / 2);
display: flex;
flex-direction: column;
justify-content: center;
background-color: ${props => props.theme.colors.white};
border-radius: 1rem;
margin-left: calc(${AVATAR_CHILD_WIDTH}rem / 2);
background-color: ${props => props.theme.colors.white};
border-radius: 1rem;
position: relative;
`;

const FirstInfo = styled.div`
display: flex;
align-items: center;
height: calc(${CONTAINER_HEIGHT}rem/2);
`;

const Item = styled.div`
display: flex;
align-items: center;
margin-left: calc(${AVATAR_CHILD_WIDTH}rem/2 + 1rem);
`;

const EditCard = styled.div`
display: flex;
align-items: center;
justify-content: center;
position: absolute;
top: 35%;
right: 1rem;
`;

interface Props {
    child: IChild;
    editChild?: (childToEdit : IChild) => void;
    removeChild?: () => void;
};

const ChildCard : React.FC<Props> = observer((props : Props) => {

    const { childProfile } = React.useContext(DataStoreContext);

    const childAvatar: string = childProfile.getChildAvatar(props.child);

    return (
        <Container>
            <AvatarContainer>
                <Icon 
                    image={childAvatar} 
                    width={AVATAR_CHILD_WIDTH} 
                    height={AVATAR_CHILD_HEIGHT}
                    borderRadius={'50%'}
                    backgroundCover
                />
            </AvatarContainer>
            <Info>
                <FirstInfo>
                    <Item>
                        <Text bold={true} marginLeft={'1rem'}>
                            {props.child.firstname}
                        </Text>
                    </Item>
                    <Separator />
                    <Text>
                        {getAge(props.child.birthdate)} ans
                    </Text>
                </FirstInfo>
                    <Item>
                        <Text marginLeft={'1rem'}>
                            {props.child.section} 
                        </Text>
                    </Item>
                {props.editChild != null && 
                    <EditCard onClick={() => props.editChild? props.editChild(props.child) : null}>
                        <Icon 
                            image={edit} 
                            width={3} 
                            height={3}
                        />
                    </EditCard>
                }
                {props.removeChild != null && 
                    <EditCard onClick={props.removeChild}>
                        <Icon 
                            image={trash} 
                            width={3} 
                            height={3}
                        />
                    </EditCard>
                }
            </Info>
        </Container>
    )
});

export default ChildCard;
