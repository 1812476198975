import * as React from 'react';
import styled from 'styled-components';
import { Container, FOOTER_HEIGHT } from '../../shared/styled';
import { DataStoreContext } from '../../../store/rootStore';
import Comment from './Comment';
import { ERoutes } from '../../shared/Navigation/ERoutes';
import ShareSomething from '../ShareSomething';
import { observer } from 'mobx-react';
import Header, { HEADER_HEIGHT } from '../../shared/Navigation/Header';
import { EContentType } from '../../shared/CreateOrUpdateContent';

const Body = styled.div`
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: space-between;
height: calc(100vh - ${FOOTER_HEIGHT} - ${HEADER_HEIGHT});
`;

const CommentsList = styled.div`
width: 100%;
overflow: scroll;
`;

const CommentList = observer(() => {

    const { social } = React.useContext(DataStoreContext);

    const comments =  social.domain.selectedPost?.comments != null ? social.domain.selectedPost?.comments : [];

    // Scroll to bottom
    const divRef = React.useRef<null | HTMLDivElement>(null);

    const scrollToBottom = () =>
    {
        if (divRef?.current != null)
        {
            divRef.current.scrollIntoView(({ behavior: 'smooth', inline: 'center', block: 'nearest' }));
        }
    };

    React.useEffect(() =>
    {
        if (comments.length > 0)
        {
            scrollToBottom();
        }
    }, [comments]);

    return (
        <Container>
            <Header title='Commentaire(s)' url={ERoutes.Social} /> 
            <Body>
                <CommentsList>
                    {comments.map(comment => 
                        <Comment
                            key={comment.id}
                            comment={comment}
                            postId={social.domain.selectedPost?.id}
                        />
                    )}
                    <div ref={divRef} />
                </CommentsList>
                <ShareSomething 
                    title='Votre commentaire ...'
                    contentType={ EContentType.CREATE_COMMENT }
                />
            </Body>
        </Container>
    )
});

export default CommentList;