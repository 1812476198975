import { flow, types, cast } from "mobx-state-tree";
import { IUser } from "./user";
import { api } from './../api/api';
import { Theme } from "../assets/Theme";
import { EToastStatus, StoreAlert } from "./alertStore";

const { REACT_APP_API_URL } = process.env;

export enum ETransactionNeed {
    GARD = "GARD",
    RUN = "RUN"
}

export enum ETransactionType {
    SINGLE = 1,
    PUBLIC = 2
}

export interface IResponse {
    id: number,
    parentIsAvailable: boolean | null,
    parentIsValidate: boolean,
    demandId: number,
    userId: number,
    message: string | null
}

export enum ITransactionStatus {
    OPENED = 'opened',
    CONFIRMED = 'confirmed',
    CANCELED = 'canceled'
}

export interface ITransaction {
    id: number,
    status: ITransactionStatus,
    start: Date,
    end: Date,
    type: ETransactionType,
    need: ETransactionNeed,
    message: string | null,
    sender: IUser,
    users: IUser[] | null,
    responses: IResponse[] | null
};

export const TransactionStore = types
.model({
    list: types.maybeNull(types.array(types.frozen<ITransaction>())),
    selectedTransaction: types.maybeNull(types.frozen<ITransaction>()),
})
.actions((self) => {
    const getTransactions = flow(function* () {
        try {
            const response  = yield api.get(`${REACT_APP_API_URL}/api/demands`)
            if (response != null)
            {
                self.list = response.data;
            }
        } catch (error)
        {
            console.log(`Une erreur s'est produite durant la récupération des demandes : ${error}`);
        };
    });
    const declineTransaction = flow(function* (transacId: number, contactedParentId: number, message: string) {
        const updatedTransactionList = self.list != null ? [...self.list] : [];
        const indexOfTransactionToDecline =  updatedTransactionList.findIndex(transac => transac.id === transacId);
            try {
                const response = yield api.post(`${REACT_APP_API_URL}/api/demands/decline`, 
                {   
                    contactedParentId: contactedParentId, 
                    demandId: transacId,
                    text: message 
                });
    
                if (response.status === 200)
                {
                    const updatedTransaction: ITransaction = response.data[0];
                    updatedTransactionList[indexOfTransactionToDecline] = updatedTransaction;
                }
            } catch (error)
            {
                console.log(`Une erreur s'est produite durant l'annulation de la garde : ${error}`);
            };
        self.list = cast(updatedTransactionList);
    });
    const cancelMyDemand = flow(function* (transacId: number, contactedParentId: number, message: string) {
        const updatedTransactionList = self.list != null ? [...self.list] : [];
        const indexOfTransactionToCancel =  updatedTransactionList.findIndex(transac => transac.id === transacId);
            try {
                const response = yield api.put(`${REACT_APP_API_URL}/api/demands/cancel/my-demand`, 
                {   
                    contactedParentId: contactedParentId, 
                    demandId: transacId,
                    text: message 
                });
    
                if (response.status === 200)
                {
                    const updatedTransaction: ITransaction = response.data[0];
                    updatedTransactionList[indexOfTransactionToCancel] = updatedTransaction;
                }
            } catch (error)
            {
                console.log(`Une erreur s'est produite durant l'annulation de la garde du parent qui l'a créée: ${error}`);
            };
        self.list = cast(updatedTransactionList);
    });
    const cancelMyPublicDemand = flow(function* (transacId: number) {
        const updatedTransactionList = self.list != null ? [...self.list] : [];
        const indexOfTransactionToCancel =  updatedTransactionList.findIndex(transac => transac.id === transacId);
            try {
                const response = yield api.put(`${REACT_APP_API_URL}/api/demands/public/cancel/my-demand`, 
                {   
                    demandId: transacId,
                });    
                if (response.status === 200)
                {
                    const updatedTransaction: ITransaction = response.data[0];
                    updatedTransactionList[indexOfTransactionToCancel] = updatedTransaction;
                }
            } catch (error)
            {
                console.log(`Une erreur s'est produite durant l'annulation de la garde publique du parent qui l'a créée: ${error}`);
            };
        self.list = cast(updatedTransactionList);
    });
    const cancelParentDemand = flow(function* (transacId: number, contactedParentId: number, message: string) {
        const updatedTransactionList = self.list != null ? [...self.list] : [];
        const indexOfTransactionToDecline =  updatedTransactionList.findIndex(transac => transac.id === transacId);
            try {
                const response = yield api.post(`${REACT_APP_API_URL}/api/demands/cancel`, 
                {   
                    contactedParentId: contactedParentId, 
                    demandId: transacId,
                    text: message 
                });
    
                if (response.status === 200)
                {
                    const updatedTransaction: ITransaction = response.data[0];
                    updatedTransactionList[indexOfTransactionToDecline] = updatedTransaction;
                }
            } catch (error)
            {
                console.log(`Une erreur s'est produite durant l'annulation de la garde du parent: ${error}`);
            };
        self.list = cast(updatedTransactionList);
    });
    const acceptTransaction = flow(function* (transacId: number, inDemandParentId: number, message: string) {

        let updatedTransactionList = self.list != null ? [...self.list] : [];
        const indexOfTransaction =  updatedTransactionList.findIndex(transac => transac.id === transacId);

        try {
            const response = yield api.post(`${REACT_APP_API_URL}/api/demands/accept`, 
            {   
                contactedParentId: inDemandParentId, 
                demandId: transacId,
                text: message 
            });
            if (response.status === 200)
            {   
                const updatedTransaction: ITransaction = response.data[0];
                updatedTransactionList[indexOfTransaction] = updatedTransaction;
            }
        } catch (error)
        {
            console.log(`Une erreur s'est produite durant l'acceptation de la garde : ${error}`);
        };
        self.list = cast(updatedTransactionList);
    });

    const candidate = flow(function* (transaction: ITransaction) {
        let updatedTransactionList: ITransaction[] = self.list != null ? [...self.list] : [];
        let transactionClone: ITransaction = {...transaction};

        try {
            const response = yield api.post(`${REACT_APP_API_URL}/api/demands/public/candidate/${transaction.id}/${transaction.sender.id}`);
            if (response.status === 200 && response.data != null)
            {     
                const indexOfTargetedTransaction =  updatedTransactionList.findIndex(transac => transac.id === transaction.id);
                const transacResponses: IResponse[] = transaction.responses != null ? [...transaction.responses] : [];
                // ADD NEW RESPONSE IN CURRENT RESPONSES
                transacResponses.push(response.data);
                // UPDATE THE TRANSAC RESPONSES
                transactionClone.responses = transacResponses;
                // UPDATE THE TRANSACTIONS LISTS
                updatedTransactionList[indexOfTargetedTransaction] = transactionClone;
            }
        } catch (error)
        {
            console.log(`Une erreur s'est produite durant la candidature à une garde : ${error}`);
        };
        self.list = cast(updatedTransactionList);
    });

    const decline = flow(function* (transaction: ITransaction) {
        let updatedTransactionList: ITransaction[] = self.list != null ? [...self.list] : [];
        let transactionClone: ITransaction = {...transaction};

        try {
            const response = yield api.post(`${REACT_APP_API_URL}/api/demands/public/decline/${transaction.id}/${transaction.sender.id}`);
            if (response.status === 200 && response.data != null)
            {     
                const indexOfTargetedTransaction =  updatedTransactionList.findIndex(transac => transac.id === transaction.id);
                const transacResponses: IResponse[] = transaction.responses != null ? [...transaction.responses] : [];
                // ADD NEW RESPONSE IN CURRENT RESPONSES
                transacResponses.push(response.data);
                // UPDATE THE TRANSAC RESPONSES
                transactionClone.responses = transacResponses;
                // UPDATE THE TRANSACTIONS LISTS
                updatedTransactionList[indexOfTargetedTransaction] = transactionClone;
            }
        } catch (error)
        {
            console.log(`Une erreur s'est produite au moment de décliner la garde : ${error}`);
        };
        self.list = cast(updatedTransactionList);
    });

    const setSelectedTransaction = function(transaction: ITransaction | null) {
        self.selectedTransaction = transaction;
    };


    const confirmTransactionIsDone = flow(function* (transacId: string | undefined, parentId: string | undefined, responseCode: string | undefined) {
        let success = false;
        if (transacId != null && parentId != null && responseCode != null)
        {
            try {
                const response  = yield api.post(`${REACT_APP_API_URL}/api/demands/done/${transacId}/${parentId}/${responseCode}`);
                if (response.status === 200)
                {
                    success = true;
                    StoreAlert.alert.setAlert(EToastStatus.SUCCESS, `Merci, nous venons de prévenir votre ami que tout s'est bien passé.`, null);
                }
            } catch (error: any) {
                const message = error.response?.data?.message?.length > 0 ? error.response.data.message : "Des erreurs se sont produites :";
                StoreAlert.alert.setAlert(EToastStatus.FAIL, message, error.response?.data?.errors);
            };
        }
        return success;
    });

    return { 
        getTransactions, declineTransaction, acceptTransaction, setSelectedTransaction, candidate, decline, cancelMyDemand, cancelMyPublicDemand, cancelParentDemand, confirmTransactionIsDone
    };
})
.views(self => ({
    getUserDemands(userLoggedId: number | undefined) {
        const demands: ITransaction[] = self.list != null ? [...self.list] : [];
        if (demands.length > 0 && userLoggedId != null)
        {
            return demands.filter(transaction => transaction.sender?.id === userLoggedId);
        }
        else
        {
            return demands;         
        }
    },
    getUserGards(userLoggedId: number | undefined) {
        const gards: ITransaction[] = self.list != null ? [...self.list] : [];
        if (gards.length > 0 && userLoggedId != null)
        {
            return gards.filter(transaction => transaction.sender != null).filter(transaction => transaction.sender?.id !== userLoggedId && transaction.type === ETransactionType.SINGLE && transaction.responses?.some(resp => resp.parentIsValidate && resp.userId === userLoggedId)
                                            || transaction.sender?.id !== userLoggedId && transaction.type === ETransactionType.PUBLIC && transaction.responses?.some(resp => resp.parentIsValidate && resp.userId === userLoggedId))
        }
        else
        {
            return gards;         
        }; 
    },
    getPublicDemands(userLoggedId: number | undefined) {
        const publicDemands: ITransaction[] = self.list != null ? [...self.list] : [];
        if (publicDemands.length > 0 && userLoggedId != null)
        {
            return publicDemands.filter(transaction => transaction.sender?.id !== userLoggedId 
                                                    && transaction.type === ETransactionType.PUBLIC);
        }
        else
        {
            return publicDemands;       
        };
    },
    getNumberOfPublicDemandNotAnswered(userLoggedId: number | undefined)
    {
        let number = 0;
        let publicDemands: ITransaction[] | null = null;

        // KEEP ONLY PUBLIC GARDS FROM TRANSACTIONS LIST
        if (self.list != null && userLoggedId != null)
        {
            publicDemands = self.list.filter(transaction => transaction.sender?.id !== userLoggedId 
                                                    && transaction.type === ETransactionType.PUBLIC
                                                    && new Date(transaction.start) > new Date()
                                                    );
        };
        if (publicDemands != null)
        {
            // KEEP ONLY PUBLIC GARDS WITH NO RESPONSES (NULL) OR WITH RESPONSES FROM CURRENT USER
            const filteredPublicDemands: ITransaction[] = publicDemands.map(transac => {
                return {...transac, responses: transac.responses != null ? transac.responses.filter(resp => resp.userId === userLoggedId) : null}});

            // GET THE NUMBER OF NOT ANSWERED
            number = filteredPublicDemands.filter(transac => transac.responses == null || transac.responses?.length < 1 ).length;
        }
        return number;
    },
    getTransactionStatusColor(transac: ITransaction) 
    {
        let color = '';
        const status: ITransactionStatus = transac.status;
        switch(status)
        {
            case ITransactionStatus.OPENED:
                color = Theme.colors.greyLightest;
                break;
            case ITransactionStatus.CONFIRMED:
                color = Theme.colors.greenToast;
                break;
            case ITransactionStatus.CANCELED:
                color = Theme.colors.warning;
                break;
        };
        return color;
    },
    getTransactionStatus(transac: ITransaction | null)
    {
        let displayStatus = '';
        if (transac != null)
        {
            const currentStatus: ITransactionStatus = transac.status;
            const transacIsFinished: boolean = new Date(transac.start) < new Date();
            
            transacIsFinished ? displayStatus = 'terminée' 
            : currentStatus === ITransactionStatus.CONFIRMED ? displayStatus = 'validée' 
            : currentStatus === ITransactionStatus.CANCELED ? displayStatus = 'annulée' 
            : displayStatus = 'en attente';    
        }
        return displayStatus;
    }
}))