import * as React from 'react';
import styled from 'styled-components';
import { ERoutes } from './Navigation/ERoutes';
import Header, { HEADER_HEIGHT, PADDING } from './Navigation/Header';
import { Button, Container, FOOTER_HEIGHT, TextArea } from './styled';
import { DataStoreContext } from '../../store/rootStore';
import { useLocation, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Theme } from '../../assets/Theme';
import UploadImages from './Pictures/UploadImages';
import { Picture } from './Pictures/Pictures';
import { AppStoreContext } from '../../store/appStore';

const INPUT_TEXT_AREA_WIDTH = `calc(100% - 2*${PADDING})`; 

const Body = styled.div`
width: calc(100% + 2*${PADDING});
display: flex;
flex-direction: column;
align-items: center;
justify-content: space-between;
background-color: ${Theme.colors.white};
height: calc(100vh - ${FOOTER_HEIGHT} - ${HEADER_HEIGHT});
padding-left: ${PADDING};
padding-right: ${PADDING};
`;

const BodyFooter = styled.div`
width: 100%;
`;

const AddPicturesContainer = styled.div`
display: flex;
justify-content: flex-start;
width: 100%;
margin: 1rem 0 1rem 0;
padding-left: ${PADDING};
overflow-y: auto;
`;

const PreviewPictures = styled.div`
display: flex;
width: 100%;
`;

const ConfirmCreation = styled.div`
width: 100%;
display: flex;
justify-content: center;
`;

export enum EContentType {
    CREATE_POST,
    UPDATE_POST,
    CREATE_COMMENT,
    CREATE_MESSAGE
}

interface PropsCreatePost {
    contentType: EContentType,
    title: string;
}

const CreateOrUpdateContent: React.FC<PropsCreatePost> = observer((props: PropsCreatePost) => {

    const { social, message } = React.useContext(DataStoreContext);
    const { app } = React.useContext(AppStoreContext);

    const getInitialTextValue = (): string => {
        let value = '';
        switch(props.contentType) 
        {
            case EContentType.CREATE_POST:
                break;
            case EContentType.UPDATE_POST:        
                value = social.domain.selectedPost?.text || '';
                break;
            case EContentType.CREATE_COMMENT:
                break;
            case EContentType.CREATE_MESSAGE:
                break;
            default:
                break;
        };
        return value;
    };

    const location = useLocation();
    const pathname = location.pathname;

    const getBackUrl = (): ERoutes | string => {
        if (pathname.search(ERoutes.Messages) !== -1 && message.selectedFriend != null)
        {
            return `${ERoutes.Conversation}/${message.selectedFriend.id}`;
        }
        if (pathname.search(ERoutes.Social) !== -1)
        {
            return ERoutes.Social;
        }
        else
        {
            return ERoutes.Home;
        }
    };

    const [text, setText] = React.useState<string>(getInitialTextValue());
    const [preview, setPreview] = React.useState<string[] | null>(null);

    const post = React.useRef(new FormData());
    const contentNotNull = React.useRef(false);

    const ref = React.useRef<HTMLTextAreaElement>(null);

    let navigate = useNavigate();

    const writeMessage = (e : React.ChangeEvent<HTMLTextAreaElement>): void => {
        const value: string = e.target.value;
        setText(value);
    };

    const createOrUpdateMessage = async () => {

        let data: FormData = post.current;
        if (data != null)
        {
            data.append('text', text);
            post.current = data;
        }

        app.setLoading(true);
        if (props.contentType === EContentType.UPDATE_POST && social.domain.selectedPost != null) // Case of POST UPDATE
        {
            await social.domain.updatePost(social.domain.selectedPost.id, post?.current);
            navigate(ERoutes.Social);
        }
        if (props.contentType === EContentType.CREATE_POST) // Case of NEW POST
        {
            await social.domain.createPost(post?.current);
            navigate(ERoutes.Social);
        }
        if (props.contentType === EContentType.CREATE_COMMENT && social.domain.selectedPost != null) // Case of NEW COMMENT
        {
            await social.domain.sendComment(social.domain.selectedPost.id, text);
            navigate(ERoutes.Comments);
        }
        if (props.contentType === EContentType.CREATE_MESSAGE) // Case of NEW MESSAGE
        {
            await message.sendMessage(post?.current);
            navigate(`${ERoutes.Conversation}/${message.selectedFriend?.id}`);
        }
        app.setLoading(false);
    };

    const selectMultiplePictures = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files != null)
        {
            const files = e.target.files;
            let data: FormData = post.current;
            let previews = [];

            if (data != null)
            {
                if (files != null)
                {
                    for (let i = 0; i < files.length; i++) {
                        data.append("files", files[i]);
                        previews.push(URL.createObjectURL(e.target.files[i]));
                    };
                    post.current = data;
                    contentNotNull.current = true;
                    setPreview(previews);
                };
            }
        };
    };

    const disablePublishButton: boolean = text.length < 1 && contentNotNull.current === false;

    React.useEffect(() => {
        if (ref.current != null)
        {
            ref.current.focus();
        }
    }, [])

    return (
        <Container>
            <Header title={props.title} url={getBackUrl()} />
            <Body>
                <TextArea 
                    rows={15}
                    cols={5}
                    placeholder={'Que souhaitez-vous partager à votre réseau ?'}
                    value={text}
                    onChange={writeMessage} 
                    backgroundColor={Theme.colors.white}  
                    ref={ref}
                    noBorder
                    width={INPUT_TEXT_AREA_WIDTH}       
                />
                <BodyFooter>
                    <AddPicturesContainer>
                        <UploadImages 
                            action={selectMultiplePictures}  
                            multiple={true}
                            width={7.8}
                            height={7.8}
                            borderRadius={12}
                        />
                        <PreviewPictures>
                            {preview?.map(img => 
                                <Picture 
                                    key={img}
                                    src={img}
                                    width={'7.8rem'}
                                    height={7.8}
                                    margin='0 0 0 1rem'
                                    borderRadius={12}
                                />
                            )}
                        </PreviewPictures>
                    </AddPicturesContainer>
                    <ConfirmCreation>
                        <Button 
                            disabled={disablePublishButton}
                            onClick={createOrUpdateMessage}
                            marginBottom={2}
                        >
                            Publier
                        </Button>
                    </ConfirmCreation>
                </BodyFooter>
            </Body>


        </Container>
    )
});

export default CreateOrUpdateContent;