import * as React from 'react';
import styled from 'styled-components';
import { ESection } from '../../Child/CreateOrEditChild';
import { Text } from '../styled';
import radioOn from './../../../assets/icons/radio-on.svg';
import radioOff from './../../../assets/icons/radio-off.svg';
import { ETransactionNeed } from '../../../store/transaction';

const Container = styled.div`
display: flex;
position: relative;
`;
interface IRadioButtonLabel {
    checked: boolean;
}

const RadioButtonLabel = styled.label<IRadioButtonLabel>`
position: absolute;
width: 2rem;
height: 2rem;
border-radius: 50%;
background-image: url(${radioOff});
${({ checked }) => checked && `
    background-image: url(${radioOn});
    transition: all 250ms;
`}
`;

const Input = styled.input`
opacity: 0;
z-index: 1;
cursor: pointer;
width: 2.1rem;
height: 2.1rem;
&:hover + ${RadioButtonLabel} {
    background-image: url(${radioOn});
    transition: all 250ms;
  }
`;

interface InputRadioProps {
    label?: string;
	value: ESection | ETransactionNeed;
	checked: boolean;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    name: string;
};

const RadioButton : React.FC<InputRadioProps> = (props : InputRadioProps) => {
    return (
        <Container>
            <Input 
                type="radio"
                onChange={e => props.onChange(e)}
                name={props.name}
                value={props.value}
                checked={props.checked}
            />
            <RadioButtonLabel checked={props.checked}/>
            <Text marginLeft={'0.5rem'} align='left'>{props.label ? props.label : props.value}</Text>
        </Container>
    )
};

export default RadioButton;
