export const childrenAvatars = [
    'https://wepoppins-bucket.s3.eu-west-3.amazonaws.com/wepoppins/kids-avatars/boy_1.png',
    'https://wepoppins-bucket.s3.eu-west-3.amazonaws.com/wepoppins/kids-avatars/boy_11.png',
    'https://wepoppins-bucket.s3.eu-west-3.amazonaws.com/wepoppins/kids-avatars/boy_12.png',
    'https://wepoppins-bucket.s3.eu-west-3.amazonaws.com/wepoppins/kids-avatars/boy_13.png',
    'https://wepoppins-bucket.s3.eu-west-3.amazonaws.com/wepoppins/kids-avatars/boy_3.png',
    'https://wepoppins-bucket.s3.eu-west-3.amazonaws.com/wepoppins/kids-avatars/boy_4.png',
    'https://wepoppins-bucket.s3.eu-west-3.amazonaws.com/wepoppins/kids-avatars/boy_5.png',
    'https://wepoppins-bucket.s3.eu-west-3.amazonaws.com/wepoppins/kids-avatars/boy_6.png',
    'https://wepoppins-bucket.s3.eu-west-3.amazonaws.com/wepoppins/kids-avatars/boy_7.png',
    'https://wepoppins-bucket.s3.eu-west-3.amazonaws.com/wepoppins/kids-avatars/boy_9.png',
    'https://wepoppins-bucket.s3.eu-west-3.amazonaws.com/wepoppins/kids-avatars/boy.png',
    'https://wepoppins-bucket.s3.eu-west-3.amazonaws.com/wepoppins/kids-avatars/kid_7.png',
    'https://wepoppins-bucket.s3.eu-west-3.amazonaws.com/wepoppins/kids-avatars/daughter.png',
    'https://wepoppins-bucket.s3.eu-west-3.amazonaws.com/wepoppins/kids-avatars/girl_1.png',
    'https://wepoppins-bucket.s3.eu-west-3.amazonaws.com/wepoppins/kids-avatars/girl_10.png',
    'https://wepoppins-bucket.s3.eu-west-3.amazonaws.com/wepoppins/kids-avatars/girl_11.png',
    'https://wepoppins-bucket.s3.eu-west-3.amazonaws.com/wepoppins/kids-avatars/girl_12.png',
    'https://wepoppins-bucket.s3.eu-west-3.amazonaws.com/wepoppins/kids-avatars/girl_13.png',
    'https://wepoppins-bucket.s3.eu-west-3.amazonaws.com/wepoppins/kids-avatars/girl_14.png',
    'https://wepoppins-bucket.s3.eu-west-3.amazonaws.com/wepoppins/kids-avatars/girl_15.png',
    'https://wepoppins-bucket.s3.eu-west-3.amazonaws.com/wepoppins/kids-avatars/girl_2.png',
    'https://wepoppins-bucket.s3.eu-west-3.amazonaws.com/wepoppins/kids-avatars/girl_4.png',
    'https://wepoppins-bucket.s3.eu-west-3.amazonaws.com/wepoppins/kids-avatars/girl_6.png',
    'https://wepoppins-bucket.s3.eu-west-3.amazonaws.com/wepoppins/kids-avatars/girl_7.png',
    'https://wepoppins-bucket.s3.eu-west-3.amazonaws.com/wepoppins/kids-avatars/girl_8.png',
    'https://wepoppins-bucket.s3.eu-west-3.amazonaws.com/wepoppins/kids-avatars/girl_9.png',
    'https://wepoppins-bucket.s3.eu-west-3.amazonaws.com/wepoppins/kids-avatars/girl_21.png',
    'https://wepoppins-bucket.s3.eu-west-3.amazonaws.com/wepoppins/kids-avatars/girl_22.png',
    'https://wepoppins-bucket.s3.eu-west-3.amazonaws.com/wepoppins/kids-avatars/girl_23.png',
    'https://wepoppins-bucket.s3.eu-west-3.amazonaws.com/wepoppins/kids-avatars/girl_19.png',
    'https://wepoppins-bucket.s3.eu-west-3.amazonaws.com/wepoppins/kids-avatars/girl_20.png',
    'https://wepoppins-bucket.s3.eu-west-3.amazonaws.com/wepoppins/kids-avatars/girl.png',
    'https://wepoppins-bucket.s3.eu-west-3.amazonaws.com/wepoppins/kids-avatars/girl_18.png',
    'https://wepoppins-bucket.s3.eu-west-3.amazonaws.com/wepoppins/kids-avatars/girl_24.png',
    'https://wepoppins-bucket.s3.eu-west-3.amazonaws.com/wepoppins/kids-avatars/girl_17.png',
    'https://wepoppins-bucket.s3.eu-west-3.amazonaws.com/wepoppins/kids-avatars/kid_8.png',
    'https://wepoppins-bucket.s3.eu-west-3.amazonaws.com/wepoppins/kids-avatars/kid_9.png',
    'https://wepoppins-bucket.s3.eu-west-3.amazonaws.com/wepoppins/kids-avatars/kid_10.png',
    'https://wepoppins-bucket.s3.eu-west-3.amazonaws.com/wepoppins/kids-avatars/kid_11.png',
    'https://wepoppins-bucket.s3.eu-west-3.amazonaws.com/wepoppins/kids-avatars/kid_12.png',
    'https://wepoppins-bucket.s3.eu-west-3.amazonaws.com/wepoppins/kids-avatars/kid_1.png',
    'https://wepoppins-bucket.s3.eu-west-3.amazonaws.com/wepoppins/kids-avatars/kid_3.png',
    'https://wepoppins-bucket.s3.eu-west-3.amazonaws.com/wepoppins/kids-avatars/kid_4.png',
    'https://wepoppins-bucket.s3.eu-west-3.amazonaws.com/wepoppins/kids-avatars/kid_5.png',
    'https://wepoppins-bucket.s3.eu-west-3.amazonaws.com/wepoppins/kids-avatars/kid_6.png',
    'https://wepoppins-bucket.s3.eu-west-3.amazonaws.com/wepoppins/kids-avatars/kid_15.png',
    'https://wepoppins-bucket.s3.eu-west-3.amazonaws.com/wepoppins/kids-avatars/kid_16.png',
    'https://wepoppins-bucket.s3.eu-west-3.amazonaws.com/wepoppins/kids-avatars/girl_16.png',
    'https://wepoppins-bucket.s3.eu-west-3.amazonaws.com/wepoppins/kids-avatars/kid.png',
    'https://wepoppins-bucket.s3.eu-west-3.amazonaws.com/wepoppins/kids-avatars/kid_17.png',
    'https://wepoppins-bucket.s3.eu-west-3.amazonaws.com/wepoppins/kids-avatars/kid_13.png',
    'https://wepoppins-bucket.s3.eu-west-3.amazonaws.com/wepoppins/kids-avatars/kid_14.png',
]