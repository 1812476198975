import * as React from 'react';
import { useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { ITransaction, ETransactionType } from '../../store/transaction';
import TransactionCard from './TransactionCard';
import girlReading from './../../assets/illustrations/girl-reading.svg';
import { CircleShape, EmptyData, H4, Illustration } from '../shared/styled';
import { observer } from 'mobx-react';
import { DataStoreContext } from '../../store/rootStore';
import { ERoutes } from '../shared/Navigation/ERoutes';
import { IUser } from '../../store/user';
import { Parents } from '../Network/ParentsList';

const Container = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
width: 100%;
margin-top: 3rem;
margin-bottom: 2rem;
`;

interface IProps {
    showDemands: boolean;
}

const Transactions: React.FC<IProps> = observer((props: IProps) => {

    const { parent, transactions, userLogged } = React.useContext(DataStoreContext);

    let navigate = useNavigate();

    const userDemands: ITransaction[] = transactions.getUserDemands(userLogged.user?.id);

    const userGards: ITransaction[] = transactions.getUserGards(userLogged.user?.id);

    const hasNoDemands: boolean = userDemands.length < 1;
    const hasNoGards: boolean = userGards.length < 1;

    const list = props.showDemands ? userDemands : userGards;

    const openTransactionDetail = async (selectedParent: IUser | null, transac: ITransaction) => {
        if (selectedParent != null && transac != null)
        {
            
            parent.setSelectParent(selectedParent);
            transactions.setSelectedTransaction(transac);
            navigate(ERoutes.TransactionDetail);
        };
    };

    // This function is used for PUBLIC DEMANDS in order to select a candidate among the list
    const openCandidates = (transac: ITransaction) => {
        if (transac != null)
        {
            transactions.setSelectedTransaction(transac);
            navigate('candidate');
        };
    };

    const getValidatedParent = (transac: ITransaction): IUser | null => {
        let validatedParentId: number;
        let validatedParent: IUser | null = null;
        if (transac != null && transac.responses != null && transac.users != null)
        {
            validatedParentId = transac.responses.filter(resp => resp.parentIsValidate)[0].userId;
            validatedParent = transac.users.filter(user => user.id === validatedParentId)[0]; 
        };
        return validatedParent;
    };

    const userTransactions = list.map((transac, index) => {

        if (transac.type === ETransactionType.SINGLE && transac.users != null)
        {
            const parentContacted = transac.sender?.id === userLogged.user?.id ? getValidatedParent(transac) : transac.sender;

            return (
                <TransactionCard 
                    key={transac.id}
                    transac={transac}
                    contactedParent={parentContacted}
                    onClick={() => openTransactionDetail(parentContacted, transac)}
                    action={() => null}
                />
            );
        }
        else
        {
            return (
                <TransactionCard 
                    key={transac.id}
                    transac={transac}
                    contactedParent={null}
                    onClick={() => openCandidates(transac)}
                    action={() => null}
                />
            );
        };
    });

    const getTransactions = () => {
        if (props.showDemands)
        {
            if (hasNoDemands)
            {
                return (
                    <EmptyData>
                        <CircleShape>
                            <Illustration src={girlReading} />
                        </CircleShape>
                        <H4>Vous n'avez pas encore fait de demandes.</H4>
                    </EmptyData>

                )
            } else {
                return (
                    <Container>
                        <Parents>
                            { userTransactions }
                        </Parents>
                    </Container>            
                )
            }
        } else {
            if (hasNoGards)
            {
                return (
                    <EmptyData>
                        <CircleShape>
                            <Illustration src={girlReading} />
                        </CircleShape>
                        <H4>Vous n'avez pas encore de demandes auxquelles répondre.</H4>
                    </EmptyData>
                )
            } else {
                return (
                    <Container>
                        <Parents>
                            { userTransactions }
                        </Parents>
                    </Container>            
                )
            };
        };
    };

    return (
        <>
            { getTransactions() }
        </>
    )
});

export default Transactions;