import * as React from 'react';
import { Container, Text, Form, Button } from '../shared/styled';
import { ERoutes } from '../shared/Navigation/ERoutes';
import { useNavigate } from "react-router-dom";
import Axios, { AxiosError } from 'axios';
import { observer } from 'mobx-react';
import { EToastStatus } from '../shared/Information/Toast';
import { AlertStoreContext } from '../../store/alertStore';
import Header from '../shared/Navigation/Header';
import TextInput from '../shared/Input/TextInput';
import { emailRegex } from '../utils/regex';

const { REACT_APP_API_URL } = process.env;

const ForgotPassword : React.FC = observer(() => {

    let navigate = useNavigate();

    const { alert } = React.useContext(AlertStoreContext);

    const [email, setEmail] = React.useState<string>('');

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    };

    const emailIsValid: boolean = email != null ? emailRegex.test(email) : false;

    const askNewPassword = async () => {
        try {
            const response = await Axios.post(`${REACT_APP_API_URL}/api/auth/forgotPassword/`, { email });
            if (response.data != null) 
            {
                alert.setAlert(EToastStatus.SUCCESS, response.data.message, null);
                navigate(ERoutes.Login);
            };
        } catch (error) {
            const err = error as AxiosError;
            if (err.response) 
            {
                const message = err.response.data.message?.length > 0 ? err.response.data.message : "Des erreurs se sont produites :";
                alert.setAlert(EToastStatus.FAIL, message, err.response.data.errors);
            };
        };
    };

    return (
        <Container register>
            <Header title='Mot de passe oublié' url={ERoutes.Login} noIcon/>
            <Text align='left' marginBottom="0.5rem" >
                Saisissez votre adresse email utilisée lors de votre inscription. 
                <br /><br />
                Nous allons ensuite vous envoyer un email contenant un nouveau mot de passe.
            </Text>
            <Form>
                <TextInput
                    id="email"
                    type="email"
                    label='Email*'
                    placeholder=" Tapez votre adresse email"
                    value={email}
                    onChange={e => handleChange(e)}            
                    validityIndicator
                    isValid={emailIsValid}        
                />
            </Form>
            <Button onClick={askNewPassword}>
                Envoyer   
            </Button>           
        </Container>
    )
});

export default ForgotPassword;
