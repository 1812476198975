import * as React from 'react';
import { observer } from 'mobx-react';
import { DataStoreContext } from '../../store/rootStore';
import { AlertStoreContext } from '../../store/alertStore';
import { Container, Button } from '../shared/styled';
import Header from '../shared/Navigation/Header';
import SelectOptions from '../shared/Select/SelectOptions';
import ParentProfile from '../Parent/ParentProfile';
import EditParentProfile from '../Parent/EditParentProfile';
import { api } from '../../api/api';
import { ERoutes } from '../shared/Navigation/ERoutes';
import { AxiosError } from 'axios';
import { EToastStatus } from '../shared/Information/Toast';
import { AppStoreContext } from '../../store/appStore';

const { REACT_APP_API_URL } = process.env;

const MyProfile : React.FC = observer(() => {

    const { userLogged, register } = React.useContext(DataStoreContext);
    const { userLogged : { user } } = React.useContext(DataStoreContext);
    const { alert } = React.useContext(AlertStoreContext);
    const { app } = React.useContext(AppStoreContext)

    const [isEditing, setIsEditing] = React.useState<boolean>(false);

    const editProfile = () => {
        if (user != null)
        {
            register.copyUserInfo(user);
            setIsEditing(true);
        };
    };

    // Update user data information 
    const saveProfile = async () => {
        if (register != null)
        {  
            app.setLoading(true);
            try 
            {
                const response = await api.put(`${REACT_APP_API_URL}/api/users/my-profile`, register)
                if (response.status === 200)
                {
                    userLogged.getUserData();
                };
            } 
            catch (error) {
                const err = error as AxiosError;
                if (err.response) 
                {
                    const message = err.response.data.message?.length > 0 ? err.response.data.message : "Des erreurs se sont produites :";
                    alert.setAlert(EToastStatus.FAIL, message, err.response.data.errors);
                };
            };
            app.setLoading(false);
        };
        setIsEditing(false);
    };

    return (
        <>
            {user != null &&
                <>
                    {!isEditing ?
                        <Container>
                            <Header title={'Mon profil'} />
                            <SelectOptions 
                                option1={'Moi'}
                                option2={'Mes enfants'}
                                url1={ERoutes.MyProfile}
                                url2={ERoutes.MyChildren}
                            />
                            <ParentProfile user={user}/>
                            <Button
                                onClick={editProfile} 
                                marginBottom={0}
                            >
                                Modifier mon profil
                            </Button>
                        </Container>
                    :
                        <Container>
                            <Header title={'Modifier votre profil'} />
                            <EditParentProfile />
                            <Button
                                onClick={saveProfile} 
                                marginBottom={0}
                                marginTop={0}
                            >
                                Enregistrer
                            </Button>
                        </Container>
                    }
                </>
            }
        </>
    )
});

export default MyProfile;
