import * as React from 'react';
import styled from 'styled-components';
import { Button, Text, H4 } from '../shared/styled';
import DatePicker, { registerLocale } from 'react-datepicker';
import { DataStoreContext } from '../../store/rootStore';
import { AlertStoreContext } from '../../store/alertStore';
import { ESearchSteps } from './ESearchSteps';
import { observer } from 'mobx-react';
import { CurrentStep as Container } from '../DirectPages/Search';
import { createGlobalStyle } from 'styled-components';
import fr from 'date-fns/locale/fr';
import { EToastStatus } from '../shared/Information/Toast';
import { Theme } from '../../assets/Theme';
import { ETransactionNeed } from '../../store/transaction';
import InputSelect from '../shared/Input/InputSelect';

registerLocale("fr", fr);

const SelectNeed = styled.div`
width: calc(100% - 1rem);
margin-top: 1rem;
`;

const CalendarDatePickerWrapperStyles = createGlobalStyle`

    .react-datepicker {
        border: none;
        margin-top: 1rem;
        margin-bottom: 1rem;
        width: 100%;
    }

    .react-datepicker__navigation {
        top: 1.2rem;
    }
   
    .react-datepicker__header {
        background-color: ${Theme.colors.white};
        border: none;
        padding: 0;
        margin-top: 2rem;
        margin-bottom: 1rem;
        font-weight: 600;
        text-transform: capitalize;
    }

    .react-datepicker__month-container {
        width: 100%;
    }

    .react-datepicker__current-month {
        text-transform: capitalize;
        color: ${Theme.colors.violet};
        margin-bottom: 1rem;
        font-size: 1.6rem;
    }

    .react-datepicker__day {
        color: ${Theme.colors.violet};
        line-height: 4.3rem;
        font-size: 1.4rem;
        /* Large devices (portrait tablets and large phones, 768px and up) */
        @media only screen and (min-width: 768px) {
            line-height: 7.3rem;
        }
    }
    
    .react-datepicker__day--selected {
        background-color: ${Theme.colors.pinkStrong};
        color: ${Theme.colors.white};
        border-radius: 50%;
    }

    .react-datepicker__day-name {
        color: ${Theme.colors.violet};
        font-size: 1.6rem;
        line-height: 3.7rem;
    }

    .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
        width: calc(100% / 8);
        height: auto;
    }

    .react-datepicker__day--selected:hover {
        background-color: ${Theme.colors.pinkStrong};
        border-radius: 50%;
    }
    
`;
const DatePickerWrapperStyles = createGlobalStyle`
    .react-datepicker__input-container {
        display: flex;
        align-items: center;
        padding: 0.5rem;
        border: 0.1rem solid ${props => props.theme.colors.greyBorder};
        border-radius: 1rem;
        width: 14rem;
        height: 3rem;
    }
    .react-datepicker__input-container input {
        border: none;
        height: 4.5rem;
        background: transparent;
        color: ${props => props.theme.colors.violet};
        width: 14rem;
        font-size: 1.6rem;
    }

    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
        font-size: 1.6rem;
        height: 3rem;
        padding: 0.5rem 1rem;
        white-space: nowrap;
        align-items: center;
        display: flex;
        justify-content: center;
    }
`;

const Times = styled.div`
display: flex;
justify-content: space-between;
width: 100%;
`;

interface TimeSelect {
    marginRight?: number;
}

const TimeSelect = styled.div<TimeSelect>`
display: flex;
flex-direction: column;
align-items: flex-start;
margin-right: ${props => props.marginRight ? props.marginRight + 'rem' : 0};
`;

enum ETime {
    START,
    END
};

enum SELECT_DATA_SUB_STEPS {
    SELECT_NEED,
    SELECT_DATE,
    SELECT_TIME
}

const SelectDate: React.FC = observer(() => {

    const { search } = React.useContext(DataStoreContext);
    const { alert } = React.useContext(AlertStoreContext);

    // Allow to show or to hide the 3 substeps
    const [currentSubStep, setCurrentSubStep] = React.useState<SELECT_DATA_SUB_STEPS>(SELECT_DATA_SUB_STEPS.SELECT_NEED);
    
    const selectNeedType = (needId: ETransactionNeed) => {
        console.log('selected need === ', needId)
        search.setNeed(needId);
        setCurrentSubStep(SELECT_DATA_SUB_STEPS.SELECT_DATE);
    };

    const titleStartHour: string = search.need === ETransactionNeed.RUN ? '' : 'Heure de début';

    const todayDate: Date = new Date();
    const todayDateAtBeginning: number = todayDate.setHours(0, 0, 1); // The smallest value of today date in order to allow to plan a gard on the same day than today

    const selectDate = (date: Date | null) => {

        if (date != null)
        {    
            if (date.getTime() < todayDateAtBeginning)
            {
                alert.setAlert(EToastStatus.FAIL, `La date choisie est antérieure à celle d'aujourd'hui`, null)
            }
            else
            {
                search.setDate(date);
                setCurrentSubStep(SELECT_DATA_SUB_STEPS.SELECT_TIME);     
            };
        };
    };

    const handleTimePeriod = (date: Date | null, period: ETime): void => {
        if (date != null)
        {
            const hour = date.getHours();
            const minutes = date.getMinutes();
            const currentDate = search.date;
            const dateToSet = new Date(currentDate.setHours(hour, minutes, 0, 0));
            if (dateToSet < todayDate)
            {
                return alert.setAlert(EToastStatus.FAIL, `L'horaire choisi est antérieur à l'heure actuel`, null)
            }
            switch(period) 
            {
                case ETime.START:
                    // IF END TIME NOT SET YET, SET DIRECTLY THE CURRENT START TIME WITHOUT VERIFICATION
                    if (search.end == null)
                    {
                        search.setStart(dateToSet);
                    }
                    // OTHERWISE CHECK IF START < END
                    else 
                    {
                        dateToSet >= search.end ?
                            alert.setAlert(EToastStatus.FAIL, `L'heure de début doit être inférieure à celle de fin`, null)
                        : search.setStart(dateToSet);
                    };
                    break;
                case ETime.END:
                    // IF START TIME NOT SET YET, SET DIRECTLY THE CURRENT END TIME WITHOUT VERIFICATION
                    if (search.start == null)
                    {
                        search.setEnd(dateToSet);
                    }
                    // OTHERWISE CHECK IF END > START
                    else 
                    {
                        dateToSet <= search.start ?
                            alert.setAlert(EToastStatus.FAIL, `L'heure de fin doit être supérieure à celle de début`, null)
                        : search.setEnd(dateToSet);
                    };
                    break;
                default:
                    return;
            };
        };
    };

    const infoNotComplete: boolean = search.start == null || (search.end == null && search.need === ETransactionNeed.GARD) || search.date == null;

    const validStep = (): void => {
        search.setCurrentStep(ESearchSteps.SELECT_PARENT);
    };

    React.useEffect(() => {
        search.reset();
    }, [])

    return (
        <Container>
            <SelectNeed>
                <H4 align='left' marginBottom='1rem'>De quoi avez-vous besoin ?</H4>
                <InputSelect 
                    setNeed={selectNeedType}
                    selectedNeed={search.need}
                />
            {currentSubStep >= SELECT_DATA_SUB_STEPS.SELECT_DATE &&
                <>
                    <H4 align='left' marginBottom='0.5rem' marginTop='1rem'>Quand ?</H4>
                    <DatePicker 
                        selected={search.date}
                        onChange={(date: Date | null) => selectDate(date)}
                        inline 
                        locale={"fr"}           
                    />
                    <CalendarDatePickerWrapperStyles />
                </>
            }
            {currentSubStep === SELECT_DATA_SUB_STEPS.SELECT_TIME &&
                <>
                    <H4 align='left' marginBottom='1rem' marginTop='1rem'>À quelle heure ?</H4>
                    <Times>
                        <TimeSelect marginRight={2}>
                            <Text align='left' marginBottom='0.5rem'>
                                { titleStartHour } 
                            </Text>
                            <DatePicker
                                selected={search.start}
                                onChange={(date: Date | null) => handleTimePeriod(date, ETime.START)}
                                showTimeSelect
                                showTimeSelectOnly
                                locale={"fr"}
                                timeIntervals={15}
                                dateFormat="HH:mm"
                            />
                            <DatePickerWrapperStyles />
                        </TimeSelect>
                        {search.need === ETransactionNeed.GARD &&
                            <TimeSelect>
                                <Text align='left' marginBottom='0.5rem'>
                                    Heure de fin
                                </Text>
                                <DatePicker
                                    selected={search.end}
                                    onChange={(date: Date | null) => handleTimePeriod(date, ETime.END)}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    locale={"fr"}
                                    timeIntervals={15}
                                    dateFormat="HH:mm"
                                />
                            </TimeSelect>
                        }
                    </Times>
                </>
            }
            </SelectNeed>
            <Button
                onClick={validStep}
                disabled={infoNotComplete}
            >
                Suivant
            </Button>
        </Container>
    )
});

export default SelectDate;
