import * as React from 'react';
import styled from 'styled-components';
import Header from '../shared/Navigation/Header';
import { CircleShape, Container, EmptyData, Illustration, H4, UpFooterButton } from '../shared/styled';
import { DataStoreContext } from '../../store/rootStore';
import ConversationCard from '../Messagerie/ConversationCard';
import { IConversation } from '../../store/message';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import SearchBar from '../shared/Input/SearchBar';
import schoolBus from './../../assets/illustrations/school-bus.svg';
import addIcon from './../../assets/icons/add-icon.svg';
import { ERoutes } from '../shared/Navigation/ERoutes';
import { IUser } from '../../store/user';
import { ITransaction } from '../../store/transaction';

const PublicDemands = styled.div`
width: 100%;
`;
const Conversations = styled.div`
width: 100%;
`;

const Messagerie: React.FC = observer(() => {

    let navigate = useNavigate();

    const { transactions, userLogged, message } = React.useContext(DataStoreContext);
    const { message : { conversations } } = React.useContext(DataStoreContext);

    const conversationsList: IConversation[] = conversations != null ? conversations : [];
    // ONLY TYPE PUBLIC_GARD
    const publicDemands: ITransaction[] = transactions.getPublicDemands(userLogged.user?.id);

    // FOR SEARCH BAR:
    const [searchContent, setSearchContent] = React.useState<string>('');
    const [filteredConversations, setFilteredConversations] = React.useState<IConversation[]>(conversationsList);

    const hasConversations: boolean = conversationsList.length > 0;

    const handleSearch = (e : React.ChangeEvent<HTMLInputElement>) => {
        const textContent: string = e.target.value;
        setSearchContent(textContent);

        if(textContent.length > 0)
        {
            const notAnsweredPublicDemands: IConversation[] = [...filteredConversations.filter(
                    conv => conv.users.filter(user => 
                                            user.id != userLogged.user?.id && user.lastname
                                            && (user.lastname.toUpperCase().startsWith(textContent.toUpperCase())
                                            || user.firstname.toUpperCase().startsWith(textContent.toUpperCase()))
                                        )[0])];
            setFilteredConversations(notAnsweredPublicDemands);
        }
        else
        {
            setFilteredConversations(conversationsList);
        };
    };

    const selectConversation = (conversation: IConversation) => {
        if (conversation != null)
        {
            const friendInTouch: IUser | undefined = message.getUserInTouch(conversation, userLogged.user?.id);
            message.selectConversation(conversation);
            message.setSelectFriend(friendInTouch); // USED FOR ROUTING FOR COMPONENT CONVERSATION

            if (friendInTouch != null)
            {
                navigate(`${ERoutes.Conversation}/${friendInTouch.id}`);
            };
        };
    };

    const openWepoppinsConversation = () => navigate(`${ERoutes.Conversation}/wepoppins`);

    const addConversation = () => navigate(ERoutes.Friends);

    React.useEffect(() => {
        message.resetConversation(); // prevent message display from previous conversation 
    }, []);

    return (
        <Container>
            <Header title='Messagerie'/>
            <SearchBar
                label="Chercher une conversation..."
                value={searchContent}
                action={handleSearch}
                marginBottom={3}
            />
            { publicDemands.length > 0 &&
                <PublicDemands>
                    <ConversationCard 
                        openConversation={openWepoppinsConversation} 
                        conversation={null}
                        fromWepoppins={true}
                        unreadMessage={transactions.getNumberOfPublicDemandNotAnswered(userLogged.user?.id)}
                    />
                </PublicDemands>
            }
            { hasConversations ? 
                <Conversations>
                    {filteredConversations.map(conversation => 
                        <ConversationCard
                            openConversation={() => selectConversation(conversation)} 
                            key={conversation.id}
                            conversation={conversation} 
                            fromWepoppins={false}
                            unreadMessage={message.getNumberOfUnreadMessages(conversation, userLogged.user?.id)}
                        />
                    )}
                </Conversations>
            : 
                <EmptyData>
                    <CircleShape>
                        <Illustration src={schoolBus} />
                    </CircleShape>
                    <H4>Vous n'avez pas encore engagé de conversations avec des parents.</H4>
                </EmptyData>
            }
            <UpFooterButton 
                image={addIcon}
                width={5.6}
                height={5.6}
                onClick={addConversation} 
            />
        </Container>
    )
});

export default Messagerie;
