import React from 'react';
import { Theme } from '../../../assets/Theme';
import styled from 'styled-components';
import { FormItem, Icon, Label, Text } from '../styled';
import ValidityIndicator from './ValidityIndicator';

const INPUT_CONTAINER_HEIGHT = '5.8rem';
const INPUT_BORDER_SIZE = '0.1rem';
const INPUT_PADDING = '1rem';

interface IInput {
    width?: string,
    noBorder?: boolean
}

const Input = styled.input<IInput>`
width: ${props => props.width ? props.width : `calc(100% - ${INPUT_PADDING})`};
height: 4.8rem;
background-color: ${Theme.colors.white};
border: none;
padding-left: ${INPUT_PADDING};
color: ${props => props.theme.colors.violet};
border: 0.1rem solid ${Theme.colors.greyBorder};
border-radius: 1rem;
font-size: 1.6rem;
::placeholder { 
    color: ${Theme.colors.greyBorder}; 
    font-size: 1.6rem;
    font-weight: 400;
}
&:-webkit-autofill {
    transition: "color 9999s ease-out, background-color 9999s ease-out";
    transition-delay: 9999s;
}
${({ noBorder }) => noBorder && `
    border: none;
`}
`;

const InputContainer = styled.div`
position: relative;
`;

export const LabelContainer = styled.div`
width: 100%;
display: flex;
align-items: center;
justify-content: space-between;
`;


interface Props {
    id: string,
    label: string,
    type: string,
    value: string,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
    placeholder?: string,
    validityIndicator?: boolean,
    isValid?: boolean,
    helpText?: string,
    width?: string,
    noBorder?: boolean,
    padding?: number;
    marginBottom?: number,
    marginTop?: number,
    iconSrc?: string,
    onClickIcon?: () => void,
}

const TextInput: React.FC<Props> = (props: Props) => {
    return (
        <FormItem 
            marginBottom={props.marginBottom} 
            marginTop={props.marginTop}
            padding={props.padding}
        >
            <LabelContainer>
                <Label>{props.label}</Label>
                {props.validityIndicator && <ValidityIndicator isValid={props.isValid} />}
            </LabelContainer>
            <InputContainer>
                <Input 
                    id={props.id}
                    type={props.type}
                    placeholder={props.placeholder}
                    value={props.value}
                    onChange={props.onChange}
                    width={props.width}
                    noBorder={props.noBorder}
                />
                {props.iconSrc &&
                    <Icon 
                        image={props.iconSrc}
                        width={3}
                        height={3}
                        inputPlaceholder={true}
                        onClick={props.onClickIcon}
                    />    
                }
            </InputContainer>
            {props.helpText && 
                <Text
                    color={Theme.colors.greyLabel}
                    size={Theme.fontSizes.small}
                    align='justify'
                    noLineHeight
                    marginTop='0.5rem'
                    padding='0 0.2rem'
                >
                    {props.helpText}
                </Text>}
        </FormItem>
    );
};

export default TextInput;