import * as React from 'react';
import { DataStoreContext } from '../../../store/rootStore';
import { IPost } from '../../../store/social';
import { useNavigate } from 'react-router-dom';
import { ERoutes } from '../../shared/Navigation/ERoutes';
import { Modal } from '../../shared/Information/Modal';
import { Button } from '../../shared/styled';
import { MenuContainer, MenuOption } from '../styled';
import { AppStoreContext } from '../../../store/appStore';
interface PostMenuProps {
    currentPost: IPost;
    closeMenu: () => void;
}

const PostMenu: React.FC<PostMenuProps> = (props: PostMenuProps) => {

    const { social } = React.useContext(DataStoreContext);
    const { app } = React.useContext(AppStoreContext);

    const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false);

    let navigate = useNavigate();

    const ref = React.useRef<HTMLDivElement>(null);

    const selectPost = () => social.domain.setSelectedPost(props.currentPost);

    const updatePost = () => navigate(ERoutes.UpdatePost);

    const handleDeleteModal = () => setShowDeleteModal(!showDeleteModal);

    const deletePost = async () => {
        if (social.domain.selectedPost != null)
        {
            app.setLoading(true);
            await social.domain.deletePost(social.domain.selectedPost.id);
            app.setLoading(false);
        };
        setShowDeleteModal(false);
    };

    const handleClickOutside = (event: any) => {
        if (ref.current && !ref.current.contains(event.target)) {
            props.closeMenu();
        }
    };

    React.useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    return (
        <div ref={ref} onClick={handleClickOutside}>
            { showDeleteModal &&
                <Modal
                    title='Confirmez-vous la suppression de cette publication ?'
                    visible={showDeleteModal}
                    close={handleDeleteModal}
                    width='80%'
                    children={
                        <Button onClick={deletePost}>
                            Supprimer
                        </Button>
                    }
                />
            }
            <MenuContainer onClick={selectPost}>
                <MenuOption onClick={updatePost}>
                    Modifier
                </MenuOption>
                <MenuOption onClick={handleDeleteModal}>
                    Supprimer
                </MenuOption>
            </MenuContainer>
        </div>
    )
};

export default PostMenu;