import * as React from 'react';
import styled from 'styled-components';
import Header, { PADDING } from '../shared/Navigation/Header';
import { useLocation } from 'react-router-dom';
import { DataStoreContext } from '../../store/rootStore';
import { observer } from 'mobx-react';
import SelectOptions from '../shared/Select/SelectOptions';
import { ERoutes } from '../shared/Navigation/ERoutes';
import ParentsList from '../Network/ParentsList';
import FriendsList from '../Network/FriendsList';
import { IUser } from '../../store/user';

const Container = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
padding: 0 ${PADDING} ${PADDING} ${PADDING};
`;

export const CurrentStep = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
width: 100%;
`;

const List = styled.div`
width: 100%;
`;

const MyNetwork: React.FC = observer(() => {

    const location = useLocation();

    const { network } = React.useContext(DataStoreContext);

    const showMyCircle: boolean = location.pathname === `${ERoutes.Network}/friends`;

    const friends: IUser[] = network.friends != null ? network.friends : [];

    return (
        <Container>
            <Header 
                title={"Réseau de parents"}
            />
            <SelectOptions 
                option1={'Rechercher'}
                option2={'Mon réseau'}
                url1={ERoutes.Network}
                url2={`${ERoutes.Network}/friends`}
            />
            <List>
                {showMyCircle ? 
                    <FriendsList friends={friends} />
                : 
                    <ParentsList />
                }
            </List>
        </Container>
    )
});

export default MyNetwork;