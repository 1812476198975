import * as React from 'react';
import styled from 'styled-components';
import { Theme } from '../../../assets/Theme';
import { Icon, Text } from '../styled';
import arrowDown from './../../../assets/icons/search/arrow-down.svg';
import arrowUp from './../../../assets/icons/search/arrow-up.svg';
import drive from './../../../assets/icons/search/drive.svg';
import takeCare from './../../../assets/icons/search/take-care.svg';
import { ETransactionNeed } from '../../../store/transaction';

const PADDING = '1rem'

interface IContainerInputSelect {
    opened: boolean;
}

export const InputSelectContainer = styled.div<IContainerInputSelect>`
width: 100%;
height: 4.8rem;
${({ opened }) => opened && `
    min-height: 11rem;
`}
`;

export const OptionInputSelect = styled.div`
display: flex;
align-items: center;
position: relative;
border-radius: 0.5rem;
border-bottom: 0.1rem solid ${Theme.colors.greyBorder};
background-color: ${props => props.theme.colors.white};
padding-left: ${PADDING};
padding-right: ${PADDING};
height: 4.8rem;
&:hover {
    background-color: ${props => props.theme.colors.pinkMedium};
}
`;

export interface INeedOptions {
    id: ETransactionNeed,
    title: string,
    icon: string
}

export const needOptions: INeedOptions[] = [
    {
        id: ETransactionNeed.GARD,
        title: "Faire garder mon enfant",
        icon: takeCare
    },
    {
        id: ETransactionNeed.RUN,
        title: "Accompagner mon enfant",
        icon: drive
    }
];

type Props = {
    setNeed: (needId: ETransactionNeed) => void;
    selectedNeed: ETransactionNeed | null;
}

const InputSelect = (props: Props) => {

    const [hasOpen, setHasOpen] = React.useState<boolean>(false);

    const handleOpenOptions = () => setHasOpen(!hasOpen);

    const selectNeed = (needId : ETransactionNeed) => {
        props.setNeed(needId);
        handleOpenOptions();
    };

    const displaySelectedOption = (): JSX.Element => {
        if (props.selectedNeed != null)
        {
            const selectedOption: INeedOptions = needOptions.filter(option => option.id === props.selectedNeed)[0];
            return (
                <OptionInputSelect onClick={handleOpenOptions}>
                    <Icon 
                        image={selectedOption.icon} 
                        width={2.5} 
                        height={2.5}                          
                    />
                    <Text 
                        align='left'
                        marginLeft='0.5rem'
                    >
                        {selectedOption.title}
                    </Text>
                    <Icon 
                        image={arrowDown} 
                        width={3} 
                        height={3}
                        inputPlaceholder                        
                    />
                </OptionInputSelect>
            );    
        }
        else 
        {
            return (
                <OptionInputSelect onClick={handleOpenOptions}>
                    <Text 
                        align='left'
                        marginLeft='0.5rem'
                    >
                        Choisir
                    </Text>
                    <Icon 
                        image={arrowDown} 
                        width={3} 
                        height={3}
                        inputPlaceholder                        
                    />
                </OptionInputSelect>
            );
        }
    };

    const displayOptions = () => {
        return(
            <>
                {needOptions.map((option, index) => 
                    <OptionInputSelect
                        key={option.id}
                        onClick={() => selectNeed(option.id)}
                    > 
                        <Icon 
                            image={option.icon} 
                            width={2.5} 
                            height={2.5}                          
                        />
                        <Text 
                            align='left'
                            marginLeft='0.5rem'
                        >
                            {option.title}
                        </Text>
                        {index === 0 && 
                            <Icon 
                                onClick={handleOpenOptions}
                                image={arrowUp} 
                                width={3} 
                                height={3}
                                inputPlaceholder                        
                            />
                        }  
                    </OptionInputSelect>     
                )}
            </>
        )
    };

    return (
        <InputSelectContainer opened={hasOpen}>
            { hasOpen ?
                displayOptions()      
            :
                displaySelectedOption()
            }
        </InputSelectContainer>
    );
};

export default InputSelect;