import React from 'react'
import { AppStoreContext } from '../../store/appStore';
import { IActivationCode } from '../../store/profile';
import { DataStoreContext } from '../../store/rootStore';
import Header from '../shared/Navigation/Header';
import { CircleShape, Container, EmptyData, Illustration, Text, Title } from '../shared/styled';
import sponsoring from './../../assets/illustrations/sponsor.png'

const Sponsorize: React.FC = () => {

    const { userLogged } = React.useContext(DataStoreContext);
    const { app } = React.useContext(AppStoreContext);

    const [sponsorCode, setSponsorCode] = React.useState<IActivationCode | null>(null);

    const getSponsorCode = async () => {
        app.setLoading(true);
        let myCode = await userLogged.getSponsorCode();
        if (myCode != null)
        {
            setSponsorCode(myCode);
        };
        app.setLoading(false);
    };

    React.useEffect(() => {
        getSponsorCode();
    }, []);

    return (
        <Container>
            <Header title={'Parrainer un ami'} />
            <Text marginTop='1rem' marginBottom='2rem'>
                Vous pouvez offrir un accès gratuit à l'application pendant 1 an à 5 personnes de votre choix.
            </Text>
            <Text marginTop='1rem' marginBottom='2rem'>
                Votre code parrainage à transmettre :
            </Text> 
            <Title marginTop='1rem' marginBottom='2rem'>
                {sponsorCode?.code}
            </Title>
            <EmptyData> 
                <CircleShape>
                    <Illustration src={sponsoring}/>
                </CircleShape>
            </EmptyData>
            <Text marginTop='1rem' marginBottom='2rem'>
                Votre filleul devra utiliser ce code lors de son inscription.
            </Text>

        </Container>
    )
}

export default Sponsorize