import React from 'react';
import { Theme } from '../../../assets/Theme';
import styled from 'styled-components';
import { FormItem, Label, Text } from '../styled';
import ValidityIndicator from './ValidityIndicator';
import { PADDING } from '../Navigation/Header';
import { LabelContainer } from './TextInput';

interface TextAreaProps {
    width?: string;
    borderRadius?: number;
    backgroundColor?: string;
    noBorder?: boolean
}

export const Input = styled.textarea<TextAreaProps>`
width: ${props => props.width ? props.width : 'auto'};
background: ${props => props.backgroundColor ? props.backgroundColor : 'transparent'};
padding: ${PADDING};
color: ${props => props.theme.colors.violet};
border: ${props => props.noBorder ? 'none' : `0.1rem solid ${Theme.colors.greyBorder}`};
border-radius: ${props => props.noBorder ? 0 : props.borderRadius ? props.borderRadius + 'rem' : '1rem'};
font-family: 'Manrope';
font-size: 1.6rem;
resize: none;
::placeholder { 
    color: ${Theme.colors.greyBorder}; 
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 2.24rem; 
}
`;

interface Props {
    id: string,
    label: string,
    value: string,
    onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void,
    placeholder?: string,
    validityIndicator?: boolean,
    isValid?: boolean,
    helpText?: string,
    width?: string,
    noBorder?: boolean,
    marginBottom?: number,
    marginTop?: number,
    borderRadius?: number;
    backgroundColor?: string;
    padding?: number;
}

const TextAreaComponent: React.FC<Props> = (props: Props) => {
    return (
        <FormItem 
            marginBottom={props.marginBottom} 
            marginTop={props.marginTop}
            padding={props.padding}
        >
            <LabelContainer>
                <Label>{props.label}</Label>
                {props.validityIndicator && <ValidityIndicator isValid={props.isValid} />}
            </LabelContainer>
            <Input 
                id={props.id}
                placeholder={props.placeholder}
                value={props.value}
                onChange={props.onChange}
                width={props.width}
                noBorder={props.noBorder}
                rows={5}
                cols={5}
            />
            {props.helpText && 
                <Text
                    color={Theme.colors.greyLabel}
                    size={Theme.fontSizes.small}
                    align='justify'
                    noLineHeight
                    marginTop='0.5rem'
                    padding='0 0.2rem'
                >
                    {props.helpText}
                </Text>}
        </FormItem>
    );
};

export default TextAreaComponent;