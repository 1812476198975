import * as React from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { Form, FormItem, Label, Button, DatePickerWrapperStyles, Icon, AVATAR_WIDTH, AVATAR_HEIGHT } from '../../shared/styled';
import { DataStoreContext } from '../../../store/rootStore';
import { AlertStoreContext } from '../../../store/alertStore';
import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import fr from 'date-fns/locale/fr';
import { EToastStatus } from '../../shared/Information/Toast';
import SearchBar from '../../shared/Input/SearchBar';
import Header from '../../shared/Navigation/Header';
import UploadImages from '../../shared/Pictures/UploadImages';
import { phoneRegex } from '../../utils/regex';
import TextInput, { LabelContainer } from '../../shared/Input/TextInput';
import ValidityIndicator from '../../shared/Input/ValidityIndicator';

export const AvatarContainer = styled.div`
display: flex;
align-items: center;
justify-content: center;
position: relative;
width: 100%;
height: ${AVATAR_HEIGHT}rem;
`;

export const AvatarLabelContainer = styled.div`
display: flex;
justify-content: center;
`;

registerLocale("fr", fr);

interface Props {
    goNextStep: () => void;
    goPreviousStep: () => void;
    selectCity: () => void;
    avatar: string | null;
    setAvatar: (avatar: string | null) => void;
}

const RegisterStep2 : React.FC<Props> = observer((props: Props) => {

    const { register : user } = React.useContext(DataStoreContext);
    const { alert } = React.useContext(AlertStoreContext);

    const currentAvatar: string = user.getProfileAvatar();

    const someEmptyFields: boolean = user.firstname === '' || user.lastname === '' || user.city === null || user.birthdate == null || user.address1 == '' || user.phone == '' || props.avatar == null;

    const userPhone: string = user.phone ? user.phone : '';
    const phoneIsValid: boolean = userPhone.match(phoneRegex) != null ? true : false;

    const selectAvatar = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files != null)
        {
            const file = e.target.files[0];
            if (file != null)
            {
                props.setAvatar(URL.createObjectURL(file));
            };
        };
    };

    const moveNextStep = () => {
        if (!phoneIsValid)
        {
            alert.setAlert(EToastStatus.FAIL, "Vous devez saisir un numéro de téléphone portable valide", null);
            return;
        }
        if (someEmptyFields)
        {
            alert.setAlert(EToastStatus.FAIL, "Vous devez renseigner tous les champs obligatoires", null);
            return;
        };
        props.goNextStep();
    };

    return (
        <>
            <Header 
                title='Votre profil' 
                action={props.goPreviousStep} 
                noIcon
            /> 
            <Form>
                <FormItem marginBottom={2}>
                    <AvatarLabelContainer>
                        <Label align='center'>
                            Votre photo de profil*
                        </Label>
                        <ValidityIndicator isValid={props.avatar != null} marginLeft={1} />
                    </AvatarLabelContainer>
                    <AvatarContainer>
                        <Icon 
                            image={props.avatar != null ? props.avatar : currentAvatar} 
                            width={AVATAR_WIDTH} 
                            height={AVATAR_HEIGHT}
                            borderRadius={'50%'}
                            backgroundCover
                        />
                        <UploadImages 
                            forRegister
                            action={selectAvatar}
                            multiple={false}
                        />
                    </AvatarContainer>
                </FormItem>
                <TextInput
                    id='firstname'
                    type='text'
                    label='Prénom*'
                    placeholder="Tapez votre prénom"
                    value={user.firstname}
                    onChange={e => user.setFirstname(e.target.value)}
                    validityIndicator
                    isValid={user.firstname.length > 0}
                    marginBottom={2}
                />
                <TextInput
                    id='lastname'
                    type='text'
                    label='Nom*'
                    placeholder="Tapez votre nom"
                    value={user.lastname}
                    onChange={e => user.setLastname(e.target.value)}
                    validityIndicator
                    isValid={user.lastname.length > 0}
                    marginBottom={2}
                />
                <FormItem marginBottom={2}>
                    <LabelContainer>
                        <Label>
                            Date de naissance*
                        </Label>
                        <ValidityIndicator isValid={user.birthdate != null} />
                    </LabelContainer>
                    <DatePicker
                        selected={user.birthdate}
                        onChange={(date: Date) => user.setBirthdate(date)}
                        wrapperClassName='date_picker full-width'
                        locale={"fr"}
                        dateFormat="P"
                        openToDate={new Date(1990, 0, 1)}
                        showYearDropdown
                        dropdownMode="select"
                        dateFormatCalendar="MMMM"
                    />
                    <DatePickerWrapperStyles />
                </FormItem>
                <TextInput
                    id='phone'
                    type='text'
                    label='Téléphone*'
                    placeholder="Votre numéro de téléphone portable"
                    value={user.phone || ''}
                    onChange={e => user.setPhone(e.target.value)}
                    validityIndicator
                    isValid={phoneIsValid}
                    marginBottom={2}
                />
                <TextInput
                    id='address1'
                    type='text'
                    label='Votre adresse*'
                    placeholder="Tapez votre addresse"
                    value={user.address1 || ''}
                    onChange={e => user.setAddress1(e.target.value)}
                    validityIndicator
                    isValid={user.address1 != ''}
                    marginBottom={2}
                />
                <TextInput
                    id='address2'
                    type='text'
                    label='Adresse (complément)'
                    placeholder="Complément d'adresse si nécessaire"
                    value={user.address2 || ''}
                    onChange={e => user.setAddress2(e.target.value)}
                    marginBottom={2}
                />
                <FormItem marginBottom={2}>
                    <LabelContainer>
                        <Label>
                            Ville*
                        </Label>
                        <ValidityIndicator isValid={user.city != null} />
                    </LabelContainer>
                    <SearchBar
                        onClick={props.selectCity}
                        label="Chercher une ville..."
                        action={props.selectCity} 
                        value={user.city?.city_realname || ''} 
                    />
                </FormItem>
            </Form>
            <Button 
                disabled={someEmptyFields}
                onClick={moveNextStep}
            >
                Étape suivante
            </Button>
        </>
    )
});

export default RegisterStep2;
