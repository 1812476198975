import React from 'react';
import { isAndroid, isIOS } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { ERoutes } from '../shared/Navigation/ERoutes';
import { moveToAppleStore, moveToPlayStore } from '../Connexion/Login';
import styled from 'styled-components';
import background from './../../assets/illustrations/pattern-home.svg';

const Container = styled.div`
background: url(${background}) top center no-repeat ${props => props.theme.colors.powderWhite};
height: 100vh;
width: 100vw;
background-size: cover;
`; 


// This component is used only when new user want to download the app after having scanned the QR Code 
const DownloadAppOnStore = () => {

    let navigate = useNavigate();

    const redirectUser = () => {
        if (isAndroid) 
        {
           moveToPlayStore();
        } 
        else if (isIOS)
        {
            moveToAppleStore();
        } 
        else
        {
            navigate(ERoutes.Login);
        };
    };

    // Redirect user on AppStore or GooglePlay for dowloading the app
    React.useEffect(() => {
        redirectUser();
    }, []); 

    return (
        <Container>
            
        </Container>
    );
};

export default DownloadAppOnStore;