import { types, flow, cast } from "mobx-state-tree";
import { api } from './../api/api';
import { IImage } from "./social";
import { IUser } from "./user";
import { StoreAlert } from './alertStore';
import { EToastStatus } from "../components/shared/Information/Toast";

const { REACT_APP_API_URL } = process.env;
export interface IMessage {
    createdAt: Date,
    id: number,
    isRead: number,
    recipientId: number,
    senderId: number,
    text: string,
    images: IImage[] | null
};

export interface IConversation {
    id: number,
    users: IUser[],
    messages: IMessage[],
};

export const MessageStore = types
.model({
    conversations : types.maybeNull(types.array(types.frozen<IConversation>())),
    selectedConversation: types.maybeNull(types.frozen<IConversation>()),
    selectedFriend: types.maybeNull(types.frozen<IUser>())
})
.actions((self) => {

    const getConversations = flow(function* () {
        const conversations: IConversation[] = yield api
            .get(`${REACT_APP_API_URL}/api/conversations`)
            .then(value => value.data)
            .catch(err => console.log(err));
        self.conversations = cast(conversations);
    });

    const deleteConversation = flow(function* (conversationId: number) {
        const updatedConversations: IConversation[] = self.conversations != null ? [...self.conversations] : [];
        const indexOfConversationToDelete =  updatedConversations.findIndex(conv => conv.id === conversationId);

        const response = yield api
        .delete(`${REACT_APP_API_URL}/api/conversations/${conversationId}`)
        .catch(err => console.log(err));
    
        if (response.status === 200 && indexOfConversationToDelete > -1)
        {
            updatedConversations.splice(indexOfConversationToDelete, 1);
            self.conversations = cast(updatedConversations);
        }
    });

    const getMessages = flow(function* () {
        let conversation: IConversation | null = null;

        if (self.selectedFriend != null)
        {
            yield api
                .get(`${REACT_APP_API_URL}/api/conversations/${self.selectedFriend.id}`)
                .then(resp => conversation = resp.data[0])
                .catch(err => console.log(err));
        };
        if (conversation != null)
        {
            self.selectedConversation = cast(conversation);
        };
    });

    const sendMessage = flow(function* (message: FormData | null) {    
        if (message != null && self.selectedFriend?.id != null)
        {
            let updatedConversation: IConversation | null = self.selectedConversation != null ? self.selectedConversation : null;
            let clonedConversations : IConversation[] = self.conversations != null ? [...self.conversations] : [];
            const indexOfupdatedConversation: number = clonedConversations.findIndex(conv => conv.id === updatedConversation?.id);

            yield api
                .post(`${REACT_APP_API_URL}/api/conversations/message/${self.selectedFriend.id}`, message)
                .then(resp => updatedConversation = resp.data[0])
                .catch(err => StoreAlert.alert.setAlert(EToastStatus.FAIL, `${err.response.data.message}`, null));
        
            if (updatedConversation != null)
            {
                // Some conversations already exist, just replace the good one
                if (clonedConversations.length > 0 && indexOfupdatedConversation > -1)
                {
                    clonedConversations[indexOfupdatedConversation] = updatedConversation;
                }
                // No conversation exists for the moment, add the first one
                else
                {
                    clonedConversations.push(updatedConversation);
                };
                // Finish by updating Store in both situation
                self.conversations = cast(clonedConversations);
            };
        };
    });

    const makeMessagesAsRead = flow(function* (parentId : number | undefined) {
        let updatedConversation: IConversation | null = self.selectedConversation;
        let clonedConversations : IConversation[] = self.conversations != null ? [...self.conversations] : [];
        const indexOfupdatedConversation: number = clonedConversations.findIndex(conv => conv.id === updatedConversation?.id);

        if (updatedConversation != null && parentId != null)
        {
            yield api
                .get(`${REACT_APP_API_URL}/api/conversations/message/read/${parentId}`)
                .then(resp => updatedConversation = resp.data[0])
                .catch(err => console.log(err));
            
        };
        if (updatedConversation != null)
        {
             // Replace the updated conversation into the array of conversations
            clonedConversations[indexOfupdatedConversation] = updatedConversation;
            // Finish by updating Store in both situation
            self.conversations = cast(clonedConversations);
        };
    });

    const selectConversation = (conversation: IConversation | null) => {
        self.selectedConversation = conversation;
    };

    const resetConversation = () => {
        self.selectedConversation = null;
    };

    const setSelectFriend = (friend: IUser | undefined | null) => {
        if (friend !== undefined)
        {
            self.selectedFriend = friend;
        };
    };

    return { 
        getConversations,
        deleteConversation,
        getMessages, 
        selectConversation, 
        resetConversation, 
        sendMessage,
        setSelectFriend,
        makeMessagesAsRead
    };

})
.views(self => ({
    getUserInTouch(conversation: IConversation | null, userLoggedId : number | undefined)
    {
        if (conversation?.users != null && userLoggedId != null)
        {
            const friend: IUser = conversation.users.filter(user => user.id !== userLoggedId)[0];
            return friend;
        };
    },
    getLastConversationMessage(conversation: IConversation | null)
    {
        let textMessage: string = '';
        
        if (conversation?.messages != undefined && conversation.messages.length > 0)
        {
                const lastElement: IMessage = conversation.messages.slice(-1)[0]; // Need an object, not an array given by slice function
                textMessage = lastElement.text;
        };
        return textMessage;
    },
    getNumberOfUnreadMessages(conversation: IConversation | null, userLoggedId : number | undefined)
    {
        let number = 0;
        if (conversation?.messages != null && userLoggedId != null)
        {
            number = conversation.messages.filter(mess => !mess.isRead && mess.recipientId === userLoggedId).length;
        };
        // TO AVOID CSS ISSUES IF NUMBER IS UPPER THAN 99
        if (number > 99)
        {
            number = 99;
        };
        return number;
    },
}));