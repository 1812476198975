import { applySnapshot, types, flow } from "mobx-state-tree";
import defaultUser from './../assets/icons/avatar/DefaultUser.png';
import { api } from './../api/api';
import { EToastStatus, StoreAlert } from './alertStore';

const { REACT_APP_API_URL } = process.env;

export interface IChild {
    id: number | null,
    firstname: string,
    birthdate: Date | null,
    section: string,
    biography: string,
    sex: string,
    avatar: string,
};

export const Child = types
.model({
    id: types.maybeNull(types.number),
    firstname: types.string,
    birthdate: types.maybeNull(types.Date),
    section : types.string,
    biography : types.string,
    sex : types.string,
    avatar : types.string,
})
.actions(self => ({
    setFirstname(firstname: string) {
        self.firstname = firstname;
    },
    setBirthdate(birthdate: Date) {
        self.birthdate = birthdate;
    },
    setSection(section: string) {
        self.section = section;
    },
    setBiography(biography: string) {
        self.biography = biography;
    },
    setSex(sex: string) {
        self.sex = sex;
    },
    setAvatar(avatarUrl : string) {
        self.avatar = avatarUrl;
    },
    copyChildInfo(childToUpdate: IChild) {
        if (childToUpdate.birthdate != null)
        {
            const updatedChild = Child.create({
                id: childToUpdate.id,
                firstname: childToUpdate.firstname,
                birthdate: new Date(childToUpdate.birthdate),
                section: childToUpdate.section,
                biography: childToUpdate.biography,
                sex: childToUpdate.sex,
                avatar: childToUpdate.avatar
            });
            applySnapshot(self, updatedChild);
        }
    },
    updateProfile: flow (function* (profile: any) {
        let operationSucceed = false;
        try 
        {
            const response = yield api.put(`${REACT_APP_API_URL}/api/users/my-children/${profile?.id}`, profile);
            if (response.status === 201)
            {
                operationSucceed = true;
            };
        } catch (error: any) {
            const message = error.response?.data?.message?.length > 0 ? error.response.data.message : "Des erreurs se sont produites :";
            StoreAlert.alert.setAlert(EToastStatus.FAIL, message, error.response?.data?.errors);
        };
        return operationSucceed;
    }),
    addChild: flow (function* (profile: any) {
        let operationSucceed = false;
        try 
        {
            const response = yield api.post(`${REACT_APP_API_URL}/api/users/add-a-child`, profile);
            if (response.status === 200)
            {
                operationSucceed = true;
            };
        } 
        catch (error: any) {
            const message = error.response?.data?.message?.length > 0 ? error.response.data.message : "Des erreurs se sont produites :";
            StoreAlert.alert.setAlert(EToastStatus.FAIL, message, error.response?.data?.errors);
        };
        return operationSucceed;
    }),
    cleanProfile() {
        self.id = null;
        self.firstname = '';
        self.birthdate = null;
        self.section = '';
        self.biography = '';
        self.sex = '';
        self.avatar = '';
    },
    getPlainProfile() {
        const plainProfile: IChild = {
            id: null,
            firstname: self.firstname,
            birthdate: self.birthdate,
            section: self.section,
            biography: self.biography,
            sex: self.sex,
            avatar: self.avatar
        };
        return plainProfile;
    }  
}))
.views(self => ({
    getChildAvatar(child: IChild | null): string {
        let avatar: string =  child?.avatar != null && child.avatar.length > 0 ? child.avatar : defaultUser;
        return avatar;
    }
}));