import * as React from "react";
import { IUser } from "../../store/user";

const useSearchFriends = (initialFriendsList : IUser[] | null) => {

    // FOR SEARCH BAR:
    const [searchContent, setSearchContent] = React.useState<string>('');
    const [filteredFriends, setFilteredFriends] = React.useState<IUser[] | null>(initialFriendsList);

    const handleSearchContent = (e : React.ChangeEvent<HTMLInputElement>) => {
        const value: string = e.target.value;
        setSearchContent(value);
    };

    const filterFriends = () => {
        if (initialFriendsList != null)
        {
            const filtered: IUser[] = initialFriendsList.filter(friend => 
                friend.lastname.toUpperCase().startsWith(searchContent.toUpperCase())
                || friend.firstname.toUpperCase().startsWith(searchContent.toUpperCase())
            );
            setFilteredFriends(filtered);
        };
    };

    React.useEffect(() => {
        filterFriends()
    }, [initialFriendsList, searchContent])


    return { searchContent, handleSearchContent, filteredFriends };
}

export default useSearchFriends;