import { DefaultTheme } from "styled-components";

export const Theme : DefaultTheme = {
    colors: {
        white: "#FFFFFF",
        powderWhite: "#FAF8F2",
        violet: "#252658",
        violetVariant: "#040849", // use for the calendar text
        pinkLight: "#D77A9F",
        pinkMedium: "#CF528580", 
        pinkStrong: "#CF5285",
        greyExtraLight: "#2526580d",
        greyLightest: "#25265817",
        greyLight: "#25265840",
        greyMedium: "#25265880",
        greyMediumPlus: "#627F84",
        greyStrong: "#252658BF",
        green: "#627F84",
        greenToast: "#7DB580",
        warning: "#F0AD4E",
        transparent: '#25265808',
        greyBorder: '#D6D6E5',
        greyLabel: '#999AB4',
        violetLight: '#999AB4',
        violetMedium: '#393B7B',
        pinkPastel: "#ECD8DD"
    },
    fonts: ["Manrope", "sans-serif", "Roboto"],
    fontSizes: {
      xsmall: "1rem",
      small: "1.2rem",
      intermediate: "1.4rem",
      normal: "1.6rem",
      large: "2rem",
      xlarge: "3rem"
    }
  }