import * as React from 'react';
import Router from "./components/DirectPages/Router";
import { Theme } from "./assets/Theme";
import { ThemeProvider } from 'styled-components';
import './App.css';

const App: React.FC = () => {
  return (
    <ThemeProvider theme={Theme}>
      <Router/>
    </ThemeProvider>
  );
}

export default App;
