import React from 'react';
import useWindowDimensions from '../../utils/useWindowDimension';

interface Props {
    authToken: string | null;
}

const IdenfyWebView: React.FC<Props> = (props: Props) => {

    const { height, width } = useWindowDimensions();

    const source = `https://ui.idenfy.com/?authToken=${props.authToken}&lang=fr`;

    return (
        <iframe 
            id='iframe' 
            src={source} 
            width={width} 
            height={height} 
            allow="camera"
        ></iframe>    
    );
};

export default IdenfyWebView;