import * as React from 'react';
import GridLoader from "react-spinners/GridLoader";
import styled from 'styled-components';
import { Theme } from '../../assets/Theme';

const override = "border-color: red;"

const Container = styled.div`
z-index: 100000;
position: fixed;
height: 100%;
width: 100%;
top: 0;
right: 0;
left: 0;
display: flex;
align-items: center;
justify-content: center;
`;

export const Loader: React.FC = () => {
    return (
        <Container>
            <GridLoader 
                color={Theme.colors.pinkStrong} 
                loading={true} 
                css={override} 
                size={20} 
            />
        </Container>
    );
};

export default Loader;
