import * as React from 'react';
import styled from 'styled-components';
import { DataStoreContext } from '../../store/rootStore';
import Header, { PADDING } from '../shared/Navigation/Header';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import 'moment/locale/fr'
import { Container, Text } from '../shared/styled';
import { useNavigate } from 'react-router-dom';
import { Modal } from '../shared/Information/Modal';
import TransactionCard from './../Transactions/TransactionCard';
import { observer } from 'mobx-react';
import { ITransaction, ITransactionStatus } from '../../store/transaction';
import { IUser } from '../../store/user';
import { Theme } from '../../assets/Theme';
import { ERoutes } from '../shared/Navigation/ERoutes';

const localizer = momentLocalizer(moment);

const Legend = styled.div`
display: flex;
justify-content: space-around;
height: 3rem;
margin-bottom: 1.5rem;
background-color: ${Theme.colors.white};
border-radius: 0.5rem;
width: 100%;
`;

const Item = styled.div`
display: flex;
align-items: center;
justify-content: center;
width: 50%;
`;

const Circle = styled.div`
width: 1.5rem;
height: 1.5rem;
border-radius: 50%;
background-color: ${props => props.color};
`;

const CalendarWrapper = styled.div`
width: 100%;
margin-top: 1rem;
margin-bottom: 1rem;
color: ${Theme.colors.violetVariant};
.rbc-calendar {
    height: 50rem;
    justify-content: center;
    display: flex;
    align-items: center;
}
.rbc-toolbar {
    display: flex;
    flex-direction: column;
}
.rbc-btn-group > button {
    color: ${Theme.colors.violetVariant}; 
}
.rbc-btn-group > button:first-child {
    display: none;
}
.rbc-toolbar-label {
    margin-top: 1rem;
}
.rbc-row.rbc-month-header {
    text-transform: capitalize; 
}
.rbc-toolbar-label {
    text-transform: capitalize; 
}
`;

enum EEventType {
    DEMAND,
    GARD,
};

// mandatory event format for react big calendar
interface IEvent {
    id: number,
    type: EEventType,
    title: string,
    start: Date | null,
    end: Date | null,
    parentFirstname : string,
    status: string,
    parentAvatar: string,
    parent: IUser,
    senderId: number,
    transac: ITransaction,
    contactedParent: IUser,
};

const MyCalendar: React.FC = observer(() => {

    let navigate = useNavigate();

    const { parent, transactions, userLogged } = React.useContext(DataStoreContext);

    const [selectedEvent, setSelectedEvent] = React.useState<IEvent | null>(null);

    const transactionsList: ITransaction[] = transactions.list != null ? transactions.list : [];

    const getUserEvents = (): IEvent[] | [] => {
        let events: IEvent[] = [];

        // ONLY TRANSACTIONS WITH AT LEAST ONE PARENT INVOLVED
        transactionsList.filter(transac => transac.users != null && transac.status === ITransactionStatus.CONFIRMED).map(transac => {
            if (transac.users != null && transac.responses != null && transac.sender != null)
            {
                //2 CAS possibles :
                    // 1er cas : Je suis le sender => je cherche dans les réponses celles ou le parentValidate = True puis je sélectionne dans les users celui qui correspond au userID qui est validé
                    // 2ème cas : Je ne suis pas le sender => j’affiche le profil du sender

                const parentValidateId: number = transac.responses.filter(parent => parent.parentIsValidate)[0].userId;
                const parentValidated = transac.users.filter(user => user.id === parentValidateId)[0];
                const parentContacted = transac.sender?.id === userLogged.user?.id ? parentValidated : transac.sender;

                let transacToEvent: IEvent = 
                {
                    id: transac.id,
                    type : transac.sender?.id === userLogged.user?.id ? EEventType.DEMAND : EEventType.GARD,
                    title: parentContacted.firstname,
                    start: transac.start,
                    end: transac.end,
                    status: transac.status,
                    parentFirstname:  parentContacted.firstname,
                    parentAvatar: parentContacted.avatar,
                    parent: parentContacted,
                    senderId: transac.sender?.id,
                    transac: transac,
                    contactedParent: parentContacted
                };
                events.push(transacToEvent);
            };
        });
        return events;    
    };

    const userEvents = getUserEvents();

    const messages = {
        allDay: 'journée',
        previous: 'Précédent',
        next: 'Suivant',
        today: 'Aujourd\'hui',
        month: 'mois',
        week: 'semaine',
        day: 'jour',
        agenda: 'Agenda',
        date: 'date',
        time: 'heure',
        event: 'événement', // Or anything you want
        showMore: (total: number) => `+ ${total} événement(s) supplémentaire(s)`,
    };

    const selectEvent = (event: IEvent | null) => {
        if (event != null)
        {
            setSelectedEvent(event);
            transactions.setSelectedTransaction(event.transac);    
        };
    };
    
    const unselectEvent = () => setSelectedEvent(null);

    const moveToTransactionDetails = async (selectedParent: IUser) => {
        if (selectedParent != null) 
        {
            parent.setSelectParent(selectedParent);
            navigate(ERoutes.TransactionDetail);
        };
    };

    React.useEffect(() => {
        setSelectedEvent(null);
    }, [transactions]);
    
    // Allow to display events in calendar with specific colors depending if it's my demands or my kidsittings
    const eventStyleGetter = (event: IEvent): object => {
        let newStyle = { backgroundColor: ''};
        switch(event.type) {
            case EEventType.DEMAND:
                newStyle.backgroundColor = Theme.colors.pinkStrong;
                break;
            case EEventType.GARD:
                newStyle.backgroundColor = Theme.colors.green;
                break;
        }
        return {
            style: newStyle
        };
    };

    return (
        <Container>
            <Header title={'Calendrier'} />
            <Text marginTop='1rem' marginBottom='2rem'>
                Seules les demandes validées apparaissent.
            </Text>
            <Legend>
                <Item>
                    <Circle color={Theme.colors.pinkStrong} />
                    <Text marginLeft='1rem'>
                        Mes demandes
                    </Text>
                </Item>
                <Item>
                    <Circle color={Theme.colors.green} />
                    <Text marginLeft='1rem'>
                        Celles de mes amis
                    </Text>
                </Item>
            </Legend>
            <CalendarWrapper>
                <Calendar
                    localizer={localizer}
                    events={userEvents}
                    startAccessor="start"
                    endAccessor="end"
                    views={["month"]}
                    messages={messages}
                    defaultDate={new Date()}
                    culture="fr"
                    onSelectEvent={event => selectEvent(event)}
                    eventPropGetter={eventStyleGetter} // custom CSS for events displayed on calendar
                />
            </CalendarWrapper>
            {selectedEvent != null ?
                <Modal 
                    title={"Détails de la garde"}
                    visible={selectedEvent != null}
                    close={unselectEvent}
                    displayBottom={true}
                    width={`calc(100% - 2*${PADDING})`}
                    children={
                        <TransactionCard
                            transac={selectedEvent.transac}
                            contactedParent={selectedEvent.contactedParent}
                            onClick={() => moveToTransactionDetails(selectedEvent.parent)} 
                            action={unselectEvent}
                        />
                    }
                />
            :
                null
            }
        </Container>
    )
});

export default MyCalendar;
