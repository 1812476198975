import * as React from 'react';
import styled from 'styled-components';
import { Theme } from '../../assets/Theme';
import { Icon, Text } from '../shared/styled';
import defaultUser from './../../assets/icons/avatar/DefaultUser.png';
import selectPhoto from './../../assets/icons/social/take-photo.svg';
import { useNavigate } from 'react-router-dom';
import { ERoutes } from '../shared/Navigation/ERoutes';
import { DataStoreContext } from '../../store/rootStore';
import { observer } from 'mobx-react';
import { EContentType } from '../shared/CreateOrUpdateContent';
import Avatar from '../shared/Avatar';

const SELECT_PHOTO_HEIGHT = 4.8;
interface IShare {
    bottom: boolean;
}

const Container = styled.div<IShare>`
background-color: ${Theme.colors.white};
display: flex;
align-items: center;
justify-content: space-between;
padding: 1rem;
width: 100%;
${({ bottom }) => bottom && `
    position: fixed;
    bottom: 0;
    justify-content: center;
    z-index: 9999;
`}
`;

const WhatsUp = styled.div`
border-radius: 5rem;
height: ${SELECT_PHOTO_HEIGHT}rem;
background-color: ${Theme.colors.white};
width: calc(100% - ${SELECT_PHOTO_HEIGHT}rem);
display: flex;
align-items: center;
justify-content: space-between;
border: 0.1rem solid ${Theme.colors.greyBorder};
`;

interface Props {
    title: string,
    contentType: EContentType,
}

const ShareSomething : React.FC<Props> = observer((props : Props) => {

    const { social, userLogged } = React.useContext(DataStoreContext);

    const userAvatar = userLogged.user?.avatar != null ? userLogged.user.avatar : defaultUser;

    const isMessage: boolean = props.contentType === EContentType.CREATE_MESSAGE;

    let navigate = useNavigate();

    // Redirect user to the relevant context :
    const share = (): void => {
        switch(props.contentType) 
        {
            case EContentType.CREATE_POST:
                social.domain.setSelectedPost(null); // clear the eventual current selected post to prevent post message autofill in
                navigate(ERoutes.CreatePost);
                break;
            case EContentType.UPDATE_POST:        
                navigate(ERoutes.CreatePost);
                break;
            case EContentType.CREATE_COMMENT:
                navigate(ERoutes.CreateComment);
                break;
            case EContentType.CREATE_MESSAGE:
                navigate(ERoutes.CreateMessage);
                break;
            default:
                break;
        };
    };

    return (
        <Container bottom={isMessage} >
            {!isMessage &&
                <Avatar 
                    image={userAvatar}
                    onClick={() => null}
                />
            }
            <WhatsUp onClick={share}>
                <Text 
                    color={Theme.colors.greyLabel}
                    marginLeft='1.5rem'
                >
                    {props.title}
                </Text>
                <Icon 
                    image={selectPhoto} 
                    width={4} 
                    height={4}
                    borderRadius='50%'
                    backgroundCover
                />
            </WhatsUp>
        </Container>
    )
});

export default ShareSomething ;