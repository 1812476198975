import * as React from 'react';
import { Instance, types } from "mobx-state-tree";

export const App = types
.model({
    loading: types.boolean,
    menuIsOpen: types.boolean,
    viewPortWidth: types.maybeNull(types.number),
    viewPortHeight: types.maybeNull(types.number),
})
.actions(self => ({
    setLoading(newStatus : boolean) {
        self.loading = newStatus;
    },
    handleOpenMenu() {
        self.menuIsOpen = !self.menuIsOpen;
    },
    setViewPortSize(width: number, height: number) {
        self.viewPortWidth = width;
        self.viewPortHeight = height;
    }
}))
.views(self => ({
    deviceIsTablet() {
        let isTablet = false;
        if (self.viewPortWidth != null)
        {
            isTablet = self.viewPortWidth >= 768;
        };
        return isTablet;
    }
}));

export const AppStore = types
    .model({
        app: App,    
    });

export type AppDataStoreType = Instance<typeof AppStore>;

export const StoreApp: AppDataStoreType = AppStore.create({
    app: {
        loading: false,
        menuIsOpen: false,
        viewPortWidth: null,
        viewPortHeight: null,    
    },
});

export const AppStoreContext = React.createContext(StoreApp);
