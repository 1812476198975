import * as React from 'react';
import styled, { keyframes, css } from 'styled-components';
import { Theme } from '../../../assets/Theme';
import close from './../../../assets/icons/close.svg';

export const PADDING_MODAL = '1em';

const fadeIn = keyframes`
0% {
}
100% {
    background:${Theme.colors.greyLight};
}
`;

const fadeOut = keyframes`
0% {
    background:${Theme.colors.greyLight};
}
100% {
    background:rgba(0,0,0,.0);
}
`;

const moveUp = keyframes`
0% {
	transform:translateY(15rem);
}
100% {
	transform:translateY(0);
}
`;

const moveDown = keyframes`
0% {
	transform:translateY(0);
}
100% {
	transform:translateY(15rem);
}
`;
interface ContainerProps {
    close?: boolean,
};

const Container = styled.div<ContainerProps>`
z-index: 9999;
position: fixed;
height: 100%;
width: 100%;
top: 0;
right: 0;
left: 0;
display: flex;
align-items: center;
justify-content: center;
background-color: ${Theme.colors.greyLight};
animation: ${props => props.close ? css`${fadeOut} 1s ease` : css`${fadeIn} 0.5s ease`};
`;

interface ModalRectProps {
    displayBottom? : boolean,
    close?: boolean,
    width?: string,
};

const ModalRect = styled.div<ModalRectProps>`
display: flex;
flex-direction: column;
align-items: center;
padding: ${PADDING_MODAL};
background: ${Theme.colors.powderWhite};
border-radius: 1rem;
animation: ${moveUp} .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
animation: ${props => props.close ? css`${moveDown} 0.5s ease` : css`${moveUp} 0.5s ease`};
${({ displayBottom }) => displayBottom && `
    position: fixed;
    bottom: 0;
`}
${({ width }) => width && `
    width: ${width};
`}
`;

const ModalHeader = styled.div`
width: 100%;
display: flex;
justify-content: space-between;
align-items: baseline;
position: relative;
`;

const ModalTitle = styled.h3`
color: ${Theme.colors.violet};
margin: 0;
font-size: ${Theme.fontSizes.large};
font-weight: normal;
margin-bottom: 1rem;
`;

const CloseIcon = styled.div`
background: url(${close}) center no-repeat;
width: 3rem;
height: 3rem;
border-radius: 50%;
position: absolute;
right: -2rem;
top: -2rem;
background-color: ${Theme.colors.greyLightest};
`;

interface ModalProps {
	title: string | React.ReactNode;
	close: () => void;
    visible: boolean;
	children?: React.ReactNode | React.ReactChildren;
    displayBottom?: boolean;
    width?: string;
}

export const Modal: React.FC<ModalProps> = (props: ModalProps) => {

    const ref = React.useRef<HTMLDivElement>(null);

    const handleClickOutside = (event: any) => {
        if (ref.current && !ref.current.contains(event.target)) {
            props.close();
        }
    };

    React.useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

	return (
		<Container close={!props.visible}>
			<ModalRect 
                displayBottom={props.displayBottom} 
                close={!props.visible} 
                width={props.width}
                ref={ref}
            >
                <ModalHeader>
                    <ModalTitle>
                        {props.title}
                    </ModalTitle>
                    {props.close && 
                        <CloseIcon 
                            onClick={props.close}
                        />
                    }
                </ModalHeader>
                    {props.children ? props.children : null}
			</ModalRect>
		</Container>
	);
};
