import { IDay } from "../../store/profile";
import { EDays } from "./EDays";

export const edayToString = (day: IDay): string => {
    let formatedLabel = '';
    if (day != null)
    {
        const label = day.label;
        switch(label) {
            case EDays.MONDAY:
                formatedLabel = 'lundi';
                break;
            case EDays.THUESDAY:
                formatedLabel = 'mardi';
                break;
            case EDays.WEDNESDAY:
                formatedLabel = 'mercredi';
                break;
            case EDays.THURSDAY:
                formatedLabel = 'jeudi';
                break;
            case EDays.FRIDAY:
                formatedLabel = 'vendredi';
                break;
            case EDays.SATURDAY:
                formatedLabel = 'samedi';
                break;
            case EDays.SUNDAY:
                formatedLabel = 'dimanche';
                break;
        };  
    };
    return formatedLabel;
};
