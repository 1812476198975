import { types } from "mobx-state-tree";
import { ESearchSteps } from "../components/Search/ESearchSteps";
import { ETransactionNeed } from "./transaction";
import { IUser } from "./user";

export const Search = types
.model({
    currentStep: types.number,
    date: types.Date,
    start: types.maybeNull(types.Date),
    end: types.maybeNull(types.Date),
    results: types.maybeNull(types.frozen<IUser[]>()),
    need: types.maybeNull(types.frozen<ETransactionNeed>())
})
.actions(self => ({
    setCurrentStep(newStep : ESearchSteps) {
        self.currentStep = newStep;
        if (newStep === ESearchSteps.SELECT_DATE)
        {
            self.results = null; // clear previous results if user start a new search;
        }
    },
    setDate(newDate : Date | null) {
        if (newDate != null)
        {
            self.date = newDate;
            // prevent wrong start date if user modifies the date another time after selecting start
            if (self.start != null) 
            {
                const hour = self.start.getHours();
                const minutes = self.start.getMinutes();
                const updatedStartDate = self.date.setHours(hour, minutes, 0, 0);
                self.start = new Date(updatedStartDate);
            }
            // prevent wrong end date if user modifies the date another time after selecting end
            if (self.end != null) 
            {
                const hour = self.end.getHours();
                const minutes = self.end.getMinutes();
                const updatedEndDate = self.date.setHours(hour, minutes, 0, 0);
                self.end = new Date(updatedEndDate);
            }
        }
    },
    setStart(startHour : Date | null) {
        if (startHour != null)
        {
            self.start = startHour;
        }
    },
    setEnd(endHour : Date | null) {
        if (endHour != null)
        {
            self.end = endHour;
        }
    },
    setResults(data : IUser[] | null) {
        if (data != null)
        {
            self.results = data;
        }
    },
    setNeed(needType : ETransactionNeed | null) {
        self.need = needType;
    },
    reset() {
        self.currentStep = ESearchSteps.SELECT_DATE;
        self.start = null;
        self.end = null;
        self.results = null;
        self.need = null;
    }
}));


