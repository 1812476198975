import * as React from 'react';
import { ProfileContainer, ProfileInfo, Text, Icon, AVATAR_HEIGHT, AVATAR_WIDTH, Container } from '../shared/styled';
import styled from 'styled-components';
import age from './../../assets/icons/profile/age.svg';
import habite from './../../assets/icons/profile/habite.svg';
import profil from './../../assets/icons/profile/profil.svg';
import calendar from './../../assets/icons/profile/calendar.svg';
import { IUser } from '../../store/user';
import { IChild } from '../../store/child';
import { getAge } from '../utils/getAge';
import tribu from './../../assets/icons/profile/parent.svg';
import { Item, Separator as Bullet } from '../Transactions/TransactionCard';
import { Theme } from '../../assets/Theme';
import { IDay } from '../../store/profile';
import { transformDateToString } from '../utils/dateManager';
import { edayToString } from '../utils/edayToString';
import defaultUser from './../../assets/icons/avatar/DefaultUser.png';
import { DataStoreContext } from '../../store/rootStore';
import { useNavigate } from 'react-router-dom';
import { ERoutes } from '../shared/Navigation/ERoutes';

interface AvatarContainerProps {
    noMargin? : boolean,
}

export const AvatarContainerProfile = styled.div<AvatarContainerProps>`
display: flex;
align-items: center;
justify-content: center;
position: relative;
height: 6rem;
width: 100%;
margin-bottom: calc(${AVATAR_WIDTH}rem/2 - 3rem);
${({ noMargin }) => noMargin && `
    margin: 0;
`}
`;

export const LineSeparator = styled.hr`
width: 90%;
border-color: ${props => props.theme.colors.greyMedium};
`;

export const Availabilities = styled.div`
margin-top: 1.5rem;
display: flex;
flex-direction: column;
background-color: ${props => props.theme.colors.white};
border-radius: 1rem;
width: 100%;
padding-bottom: 1rem;
`;

const Day = styled.div`
display: flex;
justify-content: space-between;
padding: 1rem;
`;

const Children = styled.div`
display: flex;
flex-direction: column;
margin-top: 1.5rem;
background-color: ${props => props.theme.colors.white};
border-radius: 1rem;
min-height: 8rem;
width: 100%;
padding-bottom: 1rem;
`;

const Child = styled.div`
display: flex;
align-items: center;
justify-content: space-around;
margin-bottom: 1.5rem;
`;
interface Props {
    user: IUser | null,
    hideAvailabilities?: boolean,
}

const ParentProfile : React.FC<Props> = (props: Props) => {

    const { network, userLogged, childProfile, parent } = React.useContext(DataStoreContext);

    let navigate = useNavigate();

    const children = props.user?.children != null ? props.user.children : [];
    const availabilities = props.user?.availabilities != null ? props.user.availabilities : [];
    const numberOfGardsDone: number = props.user != null ?  props.user.numberOfGardsDone : 0;

    const avatar: string = props.user?.avatar != null && props.user.avatar.length > 0 ? props.user.avatar : defaultUser;
    
    // Sort availabilities in order to display according to day of week ASC (Monday to Sunday)
    const sortAvailabilities = (): IDay[] => {
        if (props.user != null) 
        {
            const sortedAvailabilities = [...availabilities];
            return sortedAvailabilities.sort((a: IDay, b: IDay) => a.label - b.label)
        };
        return [];
    };

    const moveToMyChildren = () => {
        if (props.user?.id === userLogged.user?.id)
        {
            navigate(ERoutes.MyChildren);
        };
    };

    const moveToChildDetail = (child: IChild) => {
        parent.setSelectChild(child);
        navigate(ERoutes.ChildDetail);
    };

    return (
        <>
        {props.user != null ? 
            <ProfileContainer>
                    <AvatarContainerProfile>
                        <Icon 
                            image={avatar} 
                            width={AVATAR_WIDTH} 
                            height={AVATAR_HEIGHT}
                            borderRadius={'50%'}
                            avatarProfile={true}
                        />
                    </AvatarContainerProfile>
                    <ProfileInfo>
                        <Item noMarginLeft marginTop={AVATAR_HEIGHT/2 + 1}>
                            <Icon 
                                image={profil} 
                                width={2.5} 
                                height={2.5}
                            />
                            <Text marginLeft={'1rem'}>{props.user.firstname} {props.user.lastname}</Text>
                        </Item>
                        <Item noMarginLeft>
                            <Icon 
                                image={age} 
                                width={2.5} 
                                height={2.5}
                            />
                            <Text marginLeft={'1rem'}>{getAge(props.user.birthdate)} ans</Text>
                        </Item>
                        <Item noMarginLeft>
                            <Icon 
                                image={habite} 
                                width={2.5} 
                                height={2.5}
                            />
                            <Text marginLeft={'1rem'}>Habite à {props.user.city?.city_realname}</Text>
                        </Item>
                        {numberOfGardsDone > 0 &&
                            <Item noMarginLeft>
                                <Icon 
                                    image={network.getParentAward(props.user)} 
                                    width={4} 
                                    height={4}
                                />
                                <Text>A déjà aidé {numberOfGardsDone} fois d'autres parents</Text>
                            </Item>
                        }
                        <LineSeparator />
                        <Item noMarginLeft>
                            <Text marginLeft={'1rem'} marginBottom={'1.5rem'}>{props.user.biography}</Text>
                        </Item>
                    </ProfileInfo>
                    {!props.hideAvailabilities &&
                        <Availabilities>
                            <>
                                <Item noMarginLeft padding={1}>
                                    <Icon 
                                        image={calendar} 
                                        width={2.5} 
                                        height={2.5}
                                        />
                                    <Text 
                                        marginLeft={'1rem'}
                                        color={Theme.colors.pinkStrong} 
                                        bold
                                    >
                                        Mes dispos pour garder vos enfants
                                    </Text>
                                </Item>
                                {sortAvailabilities().map((day: IDay) => 
                                    <Day key={day.label}>
                                        <Text>{edayToString(day)}</Text>
                                        <Text>
                                            De {transformDateToString(day.start)} À {transformDateToString(day.end)}
                                        </Text>
                                    </Day>)
                                }
                            </>
                        </Availabilities>
                    }
                    <Children onClick={moveToMyChildren}>
                        <Item marginBottom={1.5} padding={1} noMarginLeft>
                            <Icon 
                                image={tribu} 
                                width={2.5} 
                                height={2.5}
                                />
                            <Text 
                                color={Theme.colors.pinkStrong} 
                                marginLeft={'1rem'}
                                bold
                            >
                                Ma petite tribu
                            </Text>
                        </Item>
                        {children.length > 0 ?
                            children.map((child : IChild, index) => 
                                <Child 
                                    key={index} 
                                    onClick={() => moveToChildDetail(child)}
                                >
                                    <Icon 
                                        image={childProfile.getChildAvatar(child)} 
                                        width={5} 
                                        height={5}
                                        borderRadius={'50%'}
                                        tribu={true}
                                    />
                                    <Text>{child.firstname}</Text>
                                    <Bullet>&nbsp;</Bullet>
                                    <Text>{getAge(child.birthdate)} ans</Text>
                                    <Bullet>&nbsp;</Bullet>
                                    <Text>{child.section}</Text>
                                </Child>)
                                
                            : 
                                <Text>La tribu n'a pas été renseignée.</Text>
                        }
                    </Children>
            </ProfileContainer>
        : 
            <Container>
                <Text>
                    Aucun profil correspondant n'a été trouvé.
                </Text> 
            </Container>
        }
        </>
    )
};

export default ParentProfile;
