import * as React from 'react';
import styled from 'styled-components';
import { Theme } from '../../../assets/Theme';
import { IPost } from '../../../store/social';
import { Icon, P1, P3, Text } from '../../shared/styled';
import defaultUser from './../../../assets/icons/avatar/DefaultUser.png';
import { dateToString, transformDateToString } from '../../utils/dateManager';
import writeIcon from './../../../assets/icons/social/write.svg';
import PostMenu from './PostMenu';
import { DataStoreContext } from '../../../store/rootStore';
import { useNavigate } from 'react-router-dom';
import Heart from '../../shared/Heart';
import Pictures from '../../shared/Pictures/Pictures';
import { ERoutes } from '../../shared/Navigation/ERoutes';
import Avatar from '../../shared/Avatar';

const PADDING = '1rem';

const Container = styled.div`
width: 100%;
background-color: ${Theme.colors.white};
padding: ${PADDING};
margin-top: 1rem;
border: 0.1rem solid ${props => props.theme.colors.greyLightest};
`;

const Header = styled.div`
display: flex;
position: relative;
`;

interface IOptions {
    opened: boolean;
}

export const Options = styled.div<IOptions>`
position: absolute;
right: 0;
${({ opened }) => opened && `
    margin-bottom: 2em;
`}
`;

const Body = styled.div`
margin-top: 2rem;
`;

const Photos = styled.div``;

const Footer = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
margin-top: 1rem;
height: 2rem;
`;

interface PropsPost {
    post: IPost
};

const Post = React.forwardRef<React.RefObject<any>,PropsPost>((props: PropsPost, ref: any) => {

    const [openPostMenu, setOpenPostMenu] = React.useState<boolean>(false);

    const { userLogged, parent, social } = React.useContext(DataStoreContext);

    const authorAvatar = props.post.author.avatar != null ? props.post.author.avatar : defaultUser;

    let navigate = useNavigate();

    const userIsAuthor: boolean = props.post.author.id === userLogged.user?.id;

    const comments = props.post.comments != null ? props.post.comments : [];

    const moveToParentProfile = async () => {
        await parent.getParentData(props.post.author.id);
        navigate(`parent/${props.post.author.id}`);
    };

    const hasComments: boolean = comments.length > 0;

    const dispayCommentsNumber = (): string => {
        if (comments.length === 1)
        {
            return `1 commentaire`
        }
        else if (comments.length > 1)
        {
            return `${comments.length} commentaires` 
        }
        else 
        {
            return '';
        }
    };

    const moveToComments = () => {
        social.domain.setSelectedPost(props.post);
        navigate(ERoutes.Comments);
    };

    const getPhotos = () => {

        if (props.post.images != null && props.post.images.length > 0)
        {
            return <Pictures list={props.post.images} />
        }
        else
        {
            return null;
        };          
    };

    return (
        <Container ref={ref}>
            <Header>
                <Avatar 
                    image={authorAvatar} 
                    onClick={moveToParentProfile}
                    marginRight='1rem'
                />
                {!openPostMenu &&
                    <div>
                        <P1>{props.post.author.firstname} {props.post.author.lastname}</P1>
                        <P3>
                            {dateToString(props.post.createdAt)} à {transformDateToString(props.post.createdAt)}
                        </P3>
                    </div>
                }
                <Options opened={openPostMenu}>
                    {openPostMenu ? 
                        <PostMenu
                            currentPost={props.post} 
                            closeMenu={() => setOpenPostMenu(false)} 
                        />
                    : userIsAuthor ?
                        <Text color='black' onClick={() => setOpenPostMenu(true)}>
                            ...
                        </Text>
                    :
                        null
                    }
                </Options>
            </Header>
            <Body>                   
                <Text
                    align='left'
                    marginBottom='1rem'
                >
                    {props.post.text}
                </Text>
                <Photos>
                    { getPhotos() }
                </Photos>
            </Body>
            <Footer>
                <Heart post={props.post} />
                {hasComments ? 
                    <Text 
                        align='right' 
                        color={Theme.colors.violetMedium}
                        onClick={moveToComments}
                        size={Theme.fontSizes.small}
                    >
                        {dispayCommentsNumber()}
                    </Text>
                : 
                    <Icon 
                        image={writeIcon} 
                        width={2.5} 
                        height={2.5}  
                        onClick={moveToComments}          
                    />
                }
            </Footer>
        </Container>    
    )
});

export default Post;