import { types, flow } from "mobx-state-tree";
import { IChild } from './child';
import { IActivationCode, IDay } from "./profile";
import { api } from './../api/api';
import { INetwork } from "./network";
import defaultUser from './../assets/icons/avatar/DefaultUser.png';
import { StoreAlert } from './alertStore';
import { EToastStatus } from "../components/shared/Information/Toast";
import { ETopics } from "../components/shared/Input/ContactSelectTopic";

const { REACT_APP_API_URL } = process.env;

export interface ILegalChanges {
    cgv: boolean | null,
    cgu: boolean | null,
    confidPolicy: boolean | null,
    charterOfGoodConduct: boolean | null
};

export interface ICity {
    city_id: number,
    city_name: string,
    city_realname: string,
    city_postal_code: string,
    city_longitude_deg: string,
    city_latitude_deg: string
};
export interface IUser {
    id: number,
    email: string | null,
    password: string | null,
    firstname: string,
    lastname: string,
    city: ICity,
    address1: string | null,
    address2: string | null,
    phone: string | null,
    biography: string,
    birthdate: Date | null,
    avatar: string,
    availabilities: IDay[],
    children: IChild[] | null,
    numberOfGardsDone: number,
    network: INetwork | null,
};

export const UserStore = types
.model({
    user : types.maybeNull(types.frozen<IUser>()),
    isAuth: types.boolean
})
.actions(self => ({
    setIsAuth(authStatus: boolean) {
        self.isAuth = authStatus;
    },
    login : flow (function* (email: string, password: string) {
        let success = false;
        let legalChanges: ILegalChanges | null = null;
        try {
            const response = yield api.post(`${REACT_APP_API_URL}/api/auth/login`, {
                email,
                password
            });
            if (response.status === 200 && response.data != null) 
            {
                const tokens = JSON.stringify(response.data);
                sessionStorage.setItem('tokens', tokens);
                success = true;
            };
        } catch (error: any) {
            // THIS ERROR CONCERNS LEGAL CHANGES NOT ALREADY VALIDATED BY USER SUCH AS CGV, CGU OR CONFIDENTIALITY POLICY
            if (error.response.status === 451 && error.response.data != null)
            {
                legalChanges = error.response.data;
            }
            else
            {
                const message = error.response?.data?.message?.length > 0 ? error.response.data.message : "Des erreurs se sont produites :";
                StoreAlert.alert.setAlert(EToastStatus.FAIL, message, error.response?.data?.errors);
            };
        };
        return { success, legalChanges };
    }),
    updateAgreements : flow (function* (email: string, password: string, legalChanges: ILegalChanges | null) {
        let success = false;
        const formatedLegalChanges: ILegalChanges = { 
            cgv: legalChanges?.cgv != null ? legalChanges.cgv : false,
            cgu: legalChanges?.cgu != null ? legalChanges.cgu : false,
            charterOfGoodConduct: legalChanges?.charterOfGoodConduct != null ? legalChanges.charterOfGoodConduct : false,
            confidPolicy: legalChanges?.confidPolicy != null ? legalChanges.confidPolicy : false
        }
        try {
            const response = yield api.put(`${REACT_APP_API_URL}/api/users/accept/agreements`, {
                email,
                password,
                legalChanges: formatedLegalChanges
            });
            if (response.status === 200 ) 
            {
                success = true;
            };
        } catch (error: any) {
            const message = error.response?.data?.message?.length > 0 ? error.response.data.message : "Des erreurs se sont produites :";
            StoreAlert.alert.setAlert(EToastStatus.FAIL, message, error.response?.data?.errors);
        };
        return success;
    }),
    askNewPassword : flow (function* (email: string) {
        let success = false;
        try {
            const response = yield api.post(`${REACT_APP_API_URL}/api/auth/forgotPassword/`, { email });
            if (response.status === 200 && response.data != null) 
            {
                StoreAlert.alert.setAlert(EToastStatus.SUCCESS, response.data.message, null);
                success = true;
            };
        } catch (error: any) {
            const message = error.response?.data?.message?.length > 0 ? error.response.data.message : "Des erreurs se sont produites :";
            StoreAlert.alert.setAlert(EToastStatus.FAIL, message, error.response?.data?.errors);
        };
        return success;
    }),
    contactUs: flow (function* (firstname: string, lastname, email: string, topic: ETopics, message: string, appVersion: string) {
        try {
            const response = yield api.post(`${REACT_APP_API_URL}/api/users/contact`, {
                firstname,
                lastname,
                email,
                topic,
                message,
                appVersion
            });
            if (response.status === 200 && response.data != null) 
            {
                StoreAlert.alert.setAlert(EToastStatus.SUCCESS, "Votre message a bien été envoyé, merci.", null);
            };
        } catch (error: any) {
            const message = error.response?.data?.message?.length > 0 ? error.response.data.message : "Des erreurs se sont produites :";
            StoreAlert.alert.setAlert(EToastStatus.FAIL, message, error.response?.data?.errors);
        };
    }),
    getUserData : flow (function* () {
        try {
            const response = yield api.get(`${REACT_APP_API_URL}/api/users/my-profile`)
            if (response)
            {
                self.user = response.data;
            };
        } catch (error: any) {
            const message = error.response?.data?.message?.length > 0 ? error.response.data.message : "Des erreurs se sont produites :";
            StoreAlert.alert.setAlert(EToastStatus.FAIL, message, error.response?.data?.errors);
        };
    }),
    updateUserData(data : IUser) {
        self.user = data;
    },
    getSponsorCode : flow (function* () {
        let sponsorCode: IActivationCode | null = null;
        try {
            const response = yield api.get(`${REACT_APP_API_URL}/api/users/sponsorize`);
            if (response.status === 200 && response.data != null) 
            {
                sponsorCode = response.data;
            };
        } catch (error: any) {
            const message = error.response?.data?.message?.length > 0 ? error.response.data.message : "Des erreurs se sont produites :";
            StoreAlert.alert.setAlert(EToastStatus.FAIL, message, error.response?.data?.errors);
        };
        return sponsorCode;
    }),
    deleteMyAccount : flow (function* () {
        let success = false;
        try {
            const response = yield api.delete(`${REACT_APP_API_URL}/api/users/account`);
            if (response.status === 200) 
            {
                StoreAlert.alert.setAlert(EToastStatus.SUCCESS, response.data.message, null);
                success = true;
            };
        } catch (error: any) {
            const message = error.response?.data?.message?.length > 0 ? error.response.data.message : "Des erreurs se sont produites :";
            StoreAlert.alert.setAlert(EToastStatus.FAIL, message, error.response?.data?.errors);
        };
        return success;
    }),

}))
.views(self => ({
    getUserAvatar() {
        let avatar = defaultUser;
        if (self.user?.avatar != null && self.user.avatar.length > 0)
        {
            avatar = self.user.avatar;
        }
        return avatar;
    }
}))
;