import * as React from 'react';
import styled from 'styled-components';
import { Button, Icon, P1, Text } from '../shared/styled';
import { Theme } from '../../assets/Theme';
import { observer } from 'mobx-react';
import Avatar from '../shared/Avatar';
import wepoppinsFavicon from './../../assets/logo/favicon-couleur.svg';
import { MessageContainer, MessageAvatarContainer, MessageBody } from './Message';
import { ITransaction } from '../../store/transaction';
import { IUser } from '../../store/user';
import { dateToString, transformDateInSlot, transformDateToString } from '../utils/dateManager';
import { DataStoreContext } from '../../store/rootStore';
import check from './../../assets/icons/parentCard/check-green.svg';
import { ETransactionNeed } from '../../store/transaction';
import { Buttons } from '../Connexion/Register/Agreements/CharterOfGoodConduct';

const Answer = styled.div`
display: flex;
margin-top: 1rem;
align-items: center;
`;

interface Props {
    transac: ITransaction;
}

const WepoppinsMessage: React.FC<Props> = observer((props : Props) => {

    const { transactions, userLogged } = React.useContext(DataStoreContext);

    const sender: IUser = props.transac.sender;
    const startDate: string = dateToString(props.transac.start);
    const startHour: string = transformDateToString(props.transac.start);
    const endHour: string = transformDateToString(props.transac.end);
    const transacIsFinished: boolean = new Date(props.transac?.start) < new Date();

    const getMessage = (): string => {
        let message = '';
        if (props.transac.need ==  ETransactionNeed.GARD)
        {
            message = `${sender.firstname} ${sender.lastname} souhaiterait faire garder ses enfants le ${startDate} de ${startHour} jusqu'à ${endHour}.`;
        };
        if (props.transac.need ==  ETransactionNeed.RUN)
        {
            message = `${sender.firstname} ${sender.lastname} aurait besoin qu'un parent puisse emmener son enfant le ${startDate} à ${startHour}.`;
        };
        return message;
    }

    const candidate = () => transactions.candidate(props.transac);

    const decline = () => transactions.decline(props.transac);

    const hasAnswered: boolean = props.transac.responses != null ? props.transac.responses?.some(resp => resp.userId === userLogged.user?.id) : false; 

    return (
        <MessageContainer isUserMessage={false}>
            <MessageAvatarContainer isUserMessage={false}>
                <Avatar 
                    image={wepoppinsFavicon}
                    width={4}
                    height={4}
                    onClick={() => null}
                    isWepoppins
                />
            </MessageAvatarContainer>
            <MessageBody isUserMessage={false}>
                <Text align='justify' color={Theme.colors.violet}>
                    { getMessage() }
                </Text>
                {props.transac.message != null && props.transac.message.length > 0 &&
                <>
                    <P1 marginTop='1rem' marginBottom='0.5rem'>Voici son message :</P1>
                    <Text align='justify' color={Theme.colors.violet}>
                        {props.transac.message}
                    </Text>
                    </>
                }
                {hasAnswered ? 
                    <Answer>
                        <Icon 
                            image={check}
                            width={2.5}
                            height={2.5}
                        />
                        <Text 
                            marginLeft='0.5rem'
                            align='left'
                        >
                            Vous avez répondu
                        </Text>
                    </Answer>
                
                : transacIsFinished ?
                    <Text 
                        marginTop='1rem'
                        align='left'
                    >
                        La demande est désormais expirée.
                    </Text>
                :
                    <Buttons>
                        <Button 
                            border
                            borderColor={Theme.colors.violet}
                            color={Theme.colors.violet}
                            backgroundColor='transparent'
                            width={9.5}
                            marginTop={0.1}
                            onClick={decline}
                        >
                            Indispo.
                        </Button>
                        <Button 
                            width={9.5}
                            marginTop={0.1}
                            onClick={candidate}
                        >
                            Dispo.
                        </Button>
                    </Buttons>
                }
            </MessageBody>
        </MessageContainer>
    )
});

export default WepoppinsMessage;
