import * as React from 'react';
import { DataStoreContext } from '../../store/rootStore';
import { Container } from '../shared/styled';
import Header from '../shared/Navigation/Header';
import SelectOptions from '../shared/Select/SelectOptions';
import { useLocation, useNavigate } from 'react-router-dom';
import Transactions from '../Transactions/Transactions';
import { observer } from 'mobx-react';
import { ERoutes } from '../shared/Navigation/ERoutes';
import Loader from '../shared/Loader';
import { ETransactionType, ITransaction, ITransactionStatus } from '../../store/transaction';

const Home : React.FC = observer(() => {

    const location = useLocation();
    let navigate = useNavigate();

    const { userLogged, transactions, message, network } = React.useContext(DataStoreContext);

    const showUserDemands = location.pathname === ERoutes.Home;

    const userGards: ITransaction[] = transactions.getUserGards(userLogged.user?.id);

    const pendingGardsToDo: number | null = userGards.filter(gardToDo => gardToDo.type == ETransactionType.SINGLE && gardToDo.status === ITransactionStatus.OPENED && new Date(gardToDo.start) > new Date()).length;

    React.useEffect(() => {
        userLogged.getUserData();
        if (transactions.selectedTransaction != null)
        {
            transactions.setSelectedTransaction(null); // RESET PREVIOUS SELECTED TRANSAC
        }
    }, []);

    React.useEffect(() => {
        if (userLogged.isAuth)
        {
            transactions.getTransactions();
            message.getConversations();
            network.getPendingRelationships();
        };
    }, [userLogged, transactions]);

    const moveToCalendar = () => navigate(ERoutes.Calendar);

    return (
        <>
        {userLogged.isAuth ?
            <Container>
                <Header title={'Bienvenue !'} />
                <SelectOptions 
                    option1={'Mes demandes'}
                    option2={'Celles de mes amis'}
                    url1={ERoutes.Home}
                    url2={`${ERoutes.Home}/gards`}
                    notifications={pendingGardsToDo}
                />
                <Transactions 
                    showDemands={showUserDemands}
                />
            </Container>
        :
            <Loader />
        }
        </>
    )
});

export default Home;
